'use strict';

var SipAdapter = {};
VendorHub.service('SipAdapter', function () {
    var sipAdapter = {};
    var InviteState = sipAdapter.InviteState = {
        Null: 0,
        Calling: 1,
        Incoming: 2,
        // Not needed
        Early: 3,
        Connecting: 4,
        Confirmed: 5,
        Disconnected: 6
    };
    var CallCode = sipAdapter.CallCode = {
        Trying: 100,
        Ringing: 180,
        Ok: 200,
        BadRequest: 400,
        Unauthorized: 401,
        NotFound: 404,
        TimedOut: 408,
        UnsupportedUriScheme: 416,
        TempUnavailable: 480,
        Busy: 486,
        RequestTerminated: 487,
        BadGateway: 502,
        InternalServer: 501,
        Declined: 603,
        Unknown: -1,
        // not an official SIP response code
        NoMicPermissions: -7777,
        MicInUse: -7778
    };
    var CALLINFO = {
        CODEC_NAME: "codecName"
    };
    /**
     * Enumeration of all the settings attributes.
     */

    var SipSettings = {
        LX_INTERCOM_PASS: "lxIntercomPass",
        SIP_PORT: "sipPort",
        ALT_PORT: "altPorts"
    };
    var defaultSettings = {
        audioCodecs: [{
            identifier: 'PCMA/8000/1'
        }, {
            identifier: 'PCMU/8000/1'
        }, {
            identifier: 'iLBC/8000/1'
        },
            /*{ NOT Supported by PJSIP
                identifier: 'G726/32000/1'
            },*/
            {
                identifier: 'GSM/8000/1'
            }, {
                identifier: 'speex/16000/1'
            }, {
                identifier: 'speex/8000/1'
            }, {
                identifier: 'speex/32000/1'
            }, {
                identifier: 'G722/16000/1'
            }],
        micLevel: 1.0,
        speakerLevel: 1.0
    };
    var hasSip = typeof sip !== 'undefined';
    var registrations = {}; // store these infos in order to re-start the call after an initial busy response!

    var retryCounter = 0;
    var sipUser, sipHost, sipUuid, sipPass;
    var currentCall = null,
        currentSipSettings,
        callDeltaTimer,
        // used to force a minimum timespan before a new call can be made or ended.
        pendingHangup; // stores a flag that indicates that a call is to be ended when the deltaTimer fires.
    // Store these infos, otherwise they might not be known when the timer finishes and cannot be dispatched.

    var lastState = {},
        lastHostUrl;

    sipAdapter.sipCallUpdateHandler = function sipCallUpdateHandler(callInfo) {
        Debug.Plugins.SIP && console.log("SipAdapter", JSON.stringify(callInfo));
        var callStates;

        if (currentCall != null) {
            if (isFinalCallUpdate(callInfo)) {
                Debug.Plugins.SIP && console.log("call end!"); // notify stateContainers

                callStates = {
                    calling: false,
                    talking: false
                };
                updateListenersForHostWithStates(currentCall.hostUrl, callStates);
                currentCall.resolve(callInfo);
                currentCall = null;
            } else {
                currentCall.notify(callInfo); // notify stateContainers

                callStates = {
                    calling: callInfo.code >= 0 && callInfo.code < CallCode.Ok,
                    talking: callInfo.code === CallCode.Ok
                }; // also inform on the codec, if known!

                if (callInfo.hasOwnProperty(CALLINFO.CODEC_NAME)) {
                    callStates.codec = callInfo[CALLINFO.CODEC_NAME];
                }

                if (callStates.talking) {
                    // set the correct levels
                    SipAdapter.setLevels(currentSipSettings.micLevel, currentSipSettings.speakerLevel);
                }

                updateListenersForHostWithStates(currentCall.hostUrl, callStates);
            }
        } else {
            console.error('received call update even though no call is active: [' + callInfo.code + '] - ' + callInfo.message);
        }
    };

    sipAdapter.sipCallErrorHandler = function sipCallErrorHandler(callInfo) {
        Debug.Plugins.SIP && console.log("SipAdapter", JSON.stringify(callInfo));

        if (currentCall) {
            // an lx intercom might be trying to call a sip phone when a user rings the bell. So the
            // first attempt of the app to call the intercom will fail & cause the intercom to stop
            // calling the sip phone. The second attempt will then succeed.
            if (callInfo.code === SipAdapter.CallCode.Busy && retryCounter < 1) {
                retryCounter++;
                setTimeout(function () {
                    // use private method, this way the retryCounter isn't reset
                    sipAdapter._makeCall(sipUser, sipHost, sipPass, sipUuid);
                }, 2000);
            } else {
                updateListenersForHostWithStates(currentCall.hostUrl, {
                    calling: false,
                    talking: false,
                    error: callInfo
                });
                currentCall.reject(callInfo);
                currentCall = null;
            }
        }
    };

    var isFinalCallUpdate = function isFinalCallUpdate(callInfo) {
        Debug.Plugins.SIP && console.log("SipAdapter" + ": isFinalCallUpdate: " + JSON.stringify(callInfo));
        var isFinal; // if there's an error, we won't do anything

        isFinal = callInfo.code >= CallCode.BadRequest; // if there was no error, it might aswell be final when the state is at level 6

        isFinal = isFinal || callInfo.state === InviteState.Disconnected;
        Debug.Plugins.SIP && console.log("       isFinal: " + JSON.stringify(isFinal));
        return isFinal;
    };
    /**
     * Public Method to start a new sip call. Also resets the retryCounter
     * @param control
     */


    sipAdapter.makeCallToControl = function makeCallToControl(control) {
        Debug.Plugins.SIP && console.log("SipAdapter", "makeCallToControl");

        if (currentCall) {
            Debug.Plugins.SIP && console.log("   another call is active, bail out");
            return;
        }

        if (!SipAdapter.requestCallToControl(control)) {
            // no new calls while the timer is active! the call will be made once the timer fires.
            Debug.Plugins.SIP && console.log("   call stop timer is active - wait for it.");
            return;
        }

        var user = control.details.audioInfo.user,
            host = control.details.audioInfo.host,
            pass,
            uuid = control.uuidAction;
        Debug.Plugins.SIP && console.log("SipAdapter", "makeCallToControl " + control.name + " = " + this.getHostUrl(user, host));

        if (Feature.INTERCOM_SIP_PASSWORD && control.isIntercom()) {
            pass = control.details.audioInfo.pass;
        } else {
            pass = "-";
        }

        if (!pass && control.features && control.features.SIP.SECURED_INTERCOM) {
            var content = {
                title: _("controls.intercom.voice.missing-password.title"),
                message: _("controls.intercom.voice.missing-password.explanation"),
                icon: Icon.INFO,
                color: window.Styles.colors.orange,
                buttonOk: true
            };
            NavigationComp.showPopup(content);
        } else {
            SipAdapter.makeCall(user, host, pass, uuid);
        }
    };
    /**
     * Public Method to start a new sip call. Also resets the retryCounter
     * @param user      the user to call
     * @param host      host to call
     * @param lxpass    an optional password to use (for Loxone Intercoms only)
     * @param sipUUID   uuid for loading sip settings
     */


    sipAdapter.makeCall = function makeCall(user, host, lxpass, sipUUID) {
        Debug.Plugins.SIP && console.log("SipAdapter", "makeCall " + this.getHostUrl(user, host));

        if (currentCall != null) {
            console.error("Hang up existing call first!");
            SipAdapter.hangUp(); //currentCall.reject('new call has been made');
        }

        if (!hasSip) {
            console.error("SIP not even supported, why allow makeCall?");
            return null;
        }

        retryCounter = 0;
        currentCall = Q.defer();

        SipAdapter._makeCall(user, host, lxpass, sipUUID);
    };
    /**
     * Private _makeCall method, doesn't reset anything.
     * @param user
     * @param host
     * @param lxpass    an optional password to use (for Loxone Intercoms only)
     * @param sipUUID
     * @private
     */


    sipAdapter._makeCall = function _makeCall(user, host, lxpass, sipUUID) {
        var sipPort = 0; // Just use 0, the Os will choose a free port then!

        try {
            let url = new URL(host);
            host = url.hostname;
        } catch (e) {// This is the normal case, the host must be supplied, not an url!
        }

        sipUser = user;
        sipHost = host;
        sipUuid = sipUUID;
        sipPass = lxpass;
        currentCall.hostUrl = this.getHostUrl(user, host);
        Debug.Plugins.SIP && console.log("make call to " + currentCall.hostUrl); // from now on we hand in call settings.

        var callArgument = {
            target: currentCall.hostUrl,
            settings: {}
        };
        currentSipSettings = SipAdapter.loadSIPSettings(sipUUID);

        if (currentSipSettings !== null) {
            callArgument.settings = currentSipSettings;
        }

        if (lxpass) {
            callArgument.settings[SipSettings.LX_INTERCOM_PASS] = lxpass;
        }

        callArgument.settings[SipSettings.SIP_PORT] = sipPort; //callArgument.settings[SipSettings.ALT_PORT] = altPorts;

        var callArgumentStr = JSON.stringify(callArgument);
        debugLog("------> SIP MAKECALL");
        sip.makeCall(callArgumentStr, this.sipCallUpdateHandler.bind(this), this.sipCallErrorHandler.bind(this));
    };

    sipAdapter._getDefaultSettings = function _getDefaultSettings() {
        return cloneObjectDeep(defaultSettings);
    };

    sipAdapter.hangUp = function hangUp() {
        Debug.Plugins.SIP && console.log("SipAdapter", "hangUp");

        if (!currentCall) {
            console.error("hangUp called, but no call to hang up around!");
            return;
        }

        if (!SipAdapter.requestHangup()) {
            Debug.Plugins.SIP && console.log("    cannot hang up just yet, it will hang up once the delta timer fires.");
            return;
        } // just hang up already, don't wait for any callbacks


        debugLog("------> SIP HANGUP");
        sip.hangUp(function success(s) {
            Debug.Plugins.SIP && console.log("hang up successful = '" + s + '"');
        }, function error(e) {
            Debug.Plugins.SIP && console.log("error while hang up " + e);
        });
        var callStates = {
            calling: false,
            talking: false
        };
        updateListenersForHostWithStates(currentCall.hostUrl, callStates);
        currentCall.resolve("Hung up");
        currentCall = null;
    };

    sipAdapter.isInCall = function isInCall() {
        return !!currentCall;
    };

    sipAdapter.setMicMute = function setMicMute(doMute) {
        sip.muteMic(doMute);
    };

    sipAdapter.setLevels = function setLevels(micLvl, speakerLvl) {
        if (currentCall) {
            sip.setLevels(micLvl, speakerLvl, function () {
                console.info("levels set!");
            }, function (e) {
                console.error("set levels failed");
                console.error(e);
            });
        }
    };

    sipAdapter.destroy = function destroy() {// Nothing to do here!
    };

    sipAdapter.hasSip = hasSip;

    sipAdapter.registerForSIPCallChanges = function registerForSIPCallChanges(reg, user, host) {
        Debug.Plugins.SIP && console.log("registerForSIPCalls " + reg + " host: " + this.getHostUrl(user, host));
        var key = this.getHostUrl(user, host);

        if (registrations[key]) {
            registrations[key].push(reg);
        } else {
            registrations[key] = [reg];
        }
    };

    sipAdapter.unregisterForSIPCallChanges = function unregisterForSIPCallChanges(reg) {
        Debug.Plugins.SIP && console.log("unregisterForSIPCalls " + reg);
        var key = getKeyFromValue(registrations, reg);
        delete registrations[key];
    };
    /**
     * Will tell all clients that the SIP-Call states can be reset. Called when leaving a intercom control content to
     * avoid error popups to show up when entering the screen next time.
     * @param user  the audio user of the control content to reset
     * @param host  the audio host of the control content to reset
     */


    sipAdapter.resetSIPCallStates = function resetSIPCallStates(user, host) {
        Debug.Plugins.SIP && console.log("registerForSIPCalls host: " + this.getHostUrl(user, host));
        updateListenersForHostWithStates(this.getHostUrl(user, host), {});
    };

    sipAdapter.getHostUrl = function getHostUrl(user, host) {
        if (!!user && user !== "") {
            return "sip:" + user + "@" + host;
        } else {
            return "sip:" + host;
        }
    };

    sipAdapter.loadSIPSettings = function loadSIPSettings(sipUUID) {
        Debug.Plugins.SIP && console.log("loadSIPSettings sipUUID:" + sipUUID);

        if (sipUUID) {
            var activeMs = ActiveMSComponent.getActiveMiniserver();

            if (activeMs.sip && activeMs.sip[sipUUID]) {
                return activeMs.sip[sipUUID];
            } else {
                return this._getDefaultSettings();
            }
        } else {
            return this._getDefaultSettings();
        }
    };

    sipAdapter.storeSIPSettings = function storeSIPSettings(sipUUID, settings) {
        Debug.Plugins.SIP && console.log("storeSIPSettings sipUUID:" + sipUUID + " settings:" + JSON.stringify(settings));
        var activeMs = ActiveMSComponent.getActiveMiniserver();

        if (settings && JSON.stringify(settings) !== JSON.stringify(defaultSettings)) {
            if (!activeMs.sip) {
                activeMs.sip = {};
            }

            activeMs.sip[sipUUID] = settings;
        } else {
            if (activeMs.sip && activeMs.sip[sipUUID]) {
                console.info("removing custom SIP settings");
                delete activeMs.sip[sipUUID];
            }
        }

        PersistenceComponent.addMiniserver(activeMs);
    };
    /**
     * Returns the content that provides info an a call that is currently active.
     * @param control
     * @param states
     * @returns {{title: string, message: string, icon: string, buttonOk: boolean}}
     */


    sipAdapter.getActiveCallInfoContent = function getSipErrorPopupContent(control, states) {
        var title = "Info";
        var message = "";
        message += "Host: '" + control.details.audioInfo.host + "'<br>";
        message += "User: '" + control.details.audioInfo.user + "'";

        if (states.codecKnown) {
            message += "<br>Codec: '" + states.codec + "'";
        }

        var content = {
            title: title,
            message: message,
            icon: Icon.INFO,
            buttonOk: true
        };
        return content;
    };
    /**
     * This method prepares a content that will be used to show a popup explaining what went wrong during the SIP call.
     * @param control   the control that was used to connect.
     * @param error     the error that occurred
     * @returns {{icon: string, color: string, buttonOk: boolean}}
     */


    sipAdapter.getErrorInfoContent = function getErrorInfoContent(control, error) {
        var title = "";
        var message = "",
            content = {
                icon: Icon.INFO,
                color: window.Styles.colors.orange,
                buttonOk: true
            };

        switch (error.code) {
            case SipAdapter.CallCode.Busy:
                title = _("controls.intercom.voice.connection-busy");
                message = _("controls.intercom.voice.connection-busy.explanation");
                break;

            case SipAdapter.CallCode.TempUnavailable:
                title = _("controls.intercom.voice.connection-failed");
                message = _("controls.intercom.voice.temp-not-available.explanation");
                break;

            case SipAdapter.CallCode.Declined:
                title = _("controls.intercom.voice.error.declined.title");
                message = _("controls.intercom.voice.error.declined.explanation", {
                    host: SipAdapter.getHostUrl(control.details.audioInfo.user, control.details.audioInfo.host)
                });
                break;

            case SipAdapter.CallCode.UnsupportedUriScheme:
                title = _("controls.intercom.voice.uri-error.title");
                message = _("controls.intercom.voice.uri-error.explanation", {
                    userAndHost: SipAdapter.getHostUrl(control.details.audioInfo.user, control.details.audioInfo.host)
                });
                break;

            case SipAdapter.CallCode.TimedOut:
            case SipAdapter.CallCode.NotFound:
            case SipAdapter.CallCode.BadRequest:
            case SipAdapter.CallCode.BadGateway:
            case SipAdapter.CallCode.InternalServer:
                title = _("controls.intercom.voice.connection-failed");
                message = _("controls.intercom.voice.error.not-reachable.explanation", {
                    host: SipAdapter.getHostUrl(control.details.audioInfo.user, control.details.audioInfo.host),
                    code: error.code
                });
                break;

            case SipAdapter.CallCode.Unauthorized:
                title = _("controls.intercom.voice.invalid-credentials-error.title");
                message = _("controls.intercom.voice.invalid-credentials-error.explanation");
                break;

            case SipAdapter.CallCode.NoMicPermissions:
                title = _("controls.intercom.voice.missing-mic-permissions.title");
                message = _("controls.intercom.voice.missing-mic-permissions.explanation");
                break;

            case SipAdapter.CallCode.MicInUse:
                message = _("controls.intercom.voice.no-mic.explanation");
                break;

            default:
                title = _("controls.intercom.voice.connection-failed");
                message = _("controls.intercom.voice.error.explanation", {
                    error: error.message,
                    code: error.code
                });
                break;
        }

        content.title = title;
        content.message = message;
        return content;
    };
    /**
     * Will start a timer that is used to enforce a minimum timespan between starting and ending calls. This is required
     * as the SIP-stack does not play well with starting/stopping calls very fast.
     *
     * If a call to make or ending a call was requested while the timer was active, it will perform the operation once
     * the timer fires.
     * @param timeout   specifies the minimum delta (in milliseconds)
     */


    sipAdapter.startCallDeltaTimer = function startCallDeltaTimer(timeout) {
        if (callDeltaTimer) {
            return;
        }

        callDeltaTimer = setTimeout(function () {
            callDeltaTimer = null;
            Debug.Plugins.SIP && console.log("SipAdapter", "callDeltaTimer fired!");

            if (pendingHangup) {
                // a view might be closed while a call is active, destroy is going to request hangup no matter what the
                // current isWorking state is.
                currentCall && SipAdapter.hangUp();
                pendingHangup = false;
            } else {
                updateListenersForHostWithStates(lastHostUrl, lastState);
            }
        }, timeout);
    };
    /**
     * Used to ask whether or not making a call is allowed by the delta-timer. If allowed, a delta timer is started. If
     * not, the control to call is being stored to be called when the timer fires.
     * @param control
     * @returns {boolean}
     */


    sipAdapter.requestCallToControl = function requestCallToControl(control) {
        var makeCall = true;

        if (callDeltaTimer) {
            makeCall = false;
        } else {
            // after starting a call, 1.5 seconds need to pass before the call can be ended. This value is based on tests
            // using older devices.
            SipAdapter.startCallDeltaTimer(2000); // inform that the call is being established right away.

            var callStates = {
                    calling: true,
                    talking: false
                },
                hostUrl = SipAdapter.getHostUrl(control.details.audioInfo.user, control.details.audioInfo.host);
            updateListenersForHostWithStates(hostUrl, callStates);
        }

        Debug.Plugins.SIP && console.log("SipAdapter", "requestCallToControl - " + makeCall);
        return makeCall;
    };
    /**
     * Used to ask whether or not hanging up the call is allowed at the time. If a delta timer is active, it'll store
     * a flag that will end the call once the delta-timer fires.
     * @returns {boolean}
     */


    sipAdapter.requestHangup = function requestHangup() {
        var doHangup = true;

        if (callDeltaTimer) {
            doHangup = false; // store for using it later --> think that if you end exit the view while a call is established. Otherwise
            // it might remain active.

            pendingHangup = true;
        } else {
            // after ending calls, 3000s need to pass before starting a new call.
            SipAdapter.startCallDeltaTimer(3000); // will tell the ui that the connection is being torn down.

            var callStates = {
                calling: false,
                talking: false
            };
            updateListenersForHostWithStates(lastHostUrl, callStates);
        }

        Debug.Plugins.SIP && console.log("SipAdapter", "requestHangup - " + doHangup);
        return doHangup;
    };

    var updateListenersForHostWithStates = function updateListenersForHostWithStates(hostUrl, states) {
        Debug.Plugins.SIP && console.info("updateListenersForHostWithStates");
        var registered = registrations[hostUrl],
            reg;
        lastState = states;
        lastHostUrl = hostUrl;
        states.isWorking = !!callDeltaTimer;

        if (registered) {
            for (var i = 0; i < registered.length; i++) {
                reg = registered[i];
                reg.receivedSIPCallStates && reg.receivedSIPCallStates.call(reg, states);
            }
        } else {
            console.info("no one is registered for this sip call??");
        }
    };

    SipAdapter = sipAdapter;
    return sipAdapter;
});
