'use strict';

function NFCService() {
    // by uncommenting the following section, NFC would be testable on the developer IF.
    /* const resolveAfterTimeoutFn = (to = 50, succArg = null) => {
        return (succCb, errCb) => {
            setTimeout(() => {
                succArg ? succCb(succArg) : succCb();
            }, to);
        }
    }
    if (!window.lxnfc) {
        window.lxnfc = {
            enabled: resolveAfterTimeoutFn(),
            listen: (cb) => {},
            openNfcSettings: () => {},
            eraseTag: resolveAfterTimeoutFn(),
            write: (msg, succCb, errCb) => {
                return resolveAfterTimeoutFn()(succCb, errCb);
            },
            makeReadOnly: resolveAfterTimeoutFn()
        }
    }
    if (!window.ndef) {
        window.ndef = {
            uriRecord: (url) => {
                return encodeURIComponent(url);
            },
            androidApplicationRecord: (rec) => {
                return encodeURIComponent(rec);
            }
        }
    }*/

    if (window.lxnfc) {
        lxnfc.enabled(function () {
            this.nfcIsAvailable = true;
        }.bind(this), function (e) {
            Debug.NFC && console.info("NFC not available: " + e);
            this.nfcIsAvailable = e === "NFC_DISABLED"; // disabled means that we have nfc!
        }.bind(this));
        lxnfc.listen(this._tagDiscovered.bind(this));
    } else {
        this.nfcIsAvailable = false;
    }

    this._ndefListeners = [];
}

/**
 * returns boolean whether NFC is available or not
 * @returns {boolean}
 */


NFCService.prototype.nfcAvailable = function nfcAvailable() {
    return !!this.nfcIsAvailable;
};
/**
 * returns promise whether NFC is disabled or not
 * @returns {Promise}
 */


NFCService.prototype.nfcDisabled = function nfcDisabled() {
    var def = Q.defer();
    lxnfc.enabled(function () {
        def.resolve(false);
    }.bind(this), function (e) {
        var disabled = e === "NFC_DISABLED";

        if (disabled) {
            def.resolve(true);
        } else {
            def.reject("NFC not available");
        }
    }.bind(this));
    return def.promise;
};

NFCService.prototype.openNfcSettings = function openNfcSettings() {
    lxnfc.openNfcSettings();
};

NFCService.prototype.preventListening = function preventListening(prevent) {
    Debug.NFC && console.log("NFC preventListening:" + prevent);

    if (prevent) {
        if (this._preventListener) {
            this.stopListening(this._preventListener);
        }

        this.listen().done(function () {
            Debug.NFC && console.log(" - prevented!");
            this.preventListening(true);
        }.bind(this), function () {
            Debug.NFC && console.log(" - prevented!");
            this.preventListening(true);
        }.bind(this), function (fn) {
            this._preventListener = fn;
        }.bind(this));
    } else if (this._preventListener) {
        this.stopListening(this._preventListener);
        delete this._preventListener;
    }
};

NFCService.prototype._tagDiscovered = function _tagDiscovered(tag) {
    Debug.NFC && console.log("NFC _tagDiscovered");

    if (this._ndefListeners.length > 0) {
        Debug.NFC && console.log(" - notify listeners");

        this._ndefListeners.forEach(function (listener) {
            listener(tag);
        });
    } else {
        Debug.NFC && console.log(" - handle as URL Start"); // TODO-thallth support more messages!
        // tags with only access data don't have a "ndefMessage" property

        if (tag.ndefMessage && util.isType(tag.ndefMessage[0], ndef.TNF_WELL_KNOWN, ndef.RTD_URI)) {
            var url = ndef.uriHelper.decodePayload(tag.ndefMessage[0].payload); // append tagId

            url += "&tagId=" + nfc.bytesToHexString(tag.id).toLowerCase();
            Debug.NFC && console.log(" url: " + url);
            UrlHelper.apply(url);
        } else if (!tag.ndefMessage) {
            Debug.NFC && console.log("read nfc tag without url");
        }
    }
};
/**
 * listens to NFC Tags
 * @param {boolean} autostop if listening should be stopped after the first found tag
 * @returns {Promise}
 */


NFCService.prototype.listen = function listen(autostop) {
    Debug.NFC && console.log("NFC listen:" + autostop);
    var def = Q.defer();

    var func = function (tag) {
        Debug.NFC && console.log(" - got tag" + tag);

        if (autostop) {
            def.resolve(tag);
            this.stopListening(func);
        } else {
            def.notify(tag);
        }
    }.bind(this);

    this._ndefListeners.push(func); // notify async, otherwise no listeners!


    setTimeout(function () {
        def.notify(func);
    });
    return def.promise;
};

NFCService.prototype.stopListening = function stopListening(fn) {
    Debug.NFC && console.log("NFC stopListening");

    this._ndefListeners.splice(this._ndefListeners.indexOf(fn), 1);
};

NFCService.prototype.erase = function erase() {
    return this.listen(true).then(function (tag) {
        Debug.NFC && console.log("NFC erase - found tag");
        var def = Q.defer();

        if (typeof tag.isWritable !== "boolean" || tag.isWritable) {
            lxnfc.eraseTag(function () {
                Debug.NFC && console.log("NFC erasing successful");
                def.resolve();
            }, function (e) {
                Debug.NFC && console.error("NFC erasing failed! (" + e + ")");
                def.reject(e);
            });
        } else {
            def.reject(_('nfc.tag.is-write-protected'));
        }

        return def.promise;
    });
};

NFCService.prototype.write = function write(msg) {
    Debug.NFC && console.log("NFCService write: " + ndef.encodeMessage(msg).length + " Bytes");
    return this.listen(true).then(function (tag) {
        Debug.NFC && console.log("NFC write - found tag");
        var def = Q.defer();
        lxnfc.write(msg, function () {
            Debug.NFC && console.log("NFC writing successful");
            def.resolve(tag);
        }, function (e) {
            Debug.NFC && console.error("NFC writing failed! (" + e + ")");
            def.reject(e);
        });
        return def.promise;
    });
};

NFCService.prototype.read = function read() {
    return this.listen(true);
};

NFCService.prototype.makeReadOnly = function makeReadOnly() {
    return this.listen(true).then(function (tag) {
        Debug.NFC && console.log("NFC makeReadOnly - found tag");
        var def = Q.defer();

        if (tag.canMakeReadOnly) {
            if (tag.isWritable) {
                lxnfc.makeReadOnly(function () {
                    Debug.NFC && console.log("NFC makeReadOnly successful");
                    def.resolve();
                }, function (e) {
                    Debug.NFC && console.log("NFC makeReadOnly failed! (" + e + ")");
                    def.reject(e);
                });
            } else {
                def.reject(_('nfc.tag.is-already-write-protected'));
            }
        } else {
            def.reject(_('nfc.tag.write-protection.not-supported'));
        }

        return def.promise;
    });
};
