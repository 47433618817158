'use strict';

var ConnectivityTools = function (ConnectivityTools) {
    class LegacyProbe extends ConnectivityTools.ReachabilityProbe {
        constructor(target, baseTimeout, reachMode, useHttps) {
            super(...arguments);
            this.name = "LegacyProbe " + translateReachMode(reachMode);
            this._successCounter = 0;
        }

        getCommand() {
            return Commands.MAC_INFORMATION;
        }

        /**
         * Checks if the response returned by the command confirms the reachability of a loxone miniserver.
         * @param data
         * @return {boolean}
         */
        isValidResponse(data) {
            var isValid = getLxResponseCode(data) === ResponseCode.OK,
                value = getLxResponseValue(data, true);

            if (isValid) {
                this._serialNo = value;
                this._version = "1.0.0.0"; // not known

                this._successCounter++;
            } // wait until it has succeeded 3 times in order to give other probes the chance to succeed (new
            // miniservers also respond properly to this probes mac-request)


            return isValid && this._successCounter > 2;
        }

        /**
         * The data returned by the response was not valid, maybe it's not a miniserver. Maybe it's a miniserver
         * who is currently rebooting.
         * @param data
         * @return {boolean}
         */
        shouldRetryAfterInvalidResponse(data) {
            return this._shouldRetry();
        }

        /**
         * Handle an error returned by the command. Check if retrying to reach this host is a good idea or useless.
         * @param error
         * @param textStatus
         * @return {boolean}
         */
        shouldRetryOnError(error, textStatus) {
            return this._shouldRetry();
        }

        /**
         * This method needs to be overwritten by the legacyProbe baseclass as CORS flags might be of use there.
         * @param timeout
         * @return {}
         * @private
         */
        getRequestOptions(timeout) {
            var options = super.getRequestOptions(...arguments);
            options.crossDomain = true;
            options.headers = options.headers || {};
            options.headers["Access-Control-Allow-Origin"] = "*";
            return options;
        }

        /**
         * Will determine if retrying to evaluate the reachability is feasible.
         * @return {boolean}
         * @private
         */
        _shouldRetry() {
            return this._attemptCounter < 5; // not a common usecase, avoid polling forever. 5 was picked arbitrarily
        }

    }

    ConnectivityTools.LegacyProbe = LegacyProbe;
    return ConnectivityTools;
}(ConnectivityTools || {});
