'use strict';
/**
 * This class represents a generic search index. It can be filled up with arbitrary items that provide a uuid, a name
 * and optionally a description (which is not included in the search keywords).
 */

window.SearchUtils = function (SearchUtils) {
    var NAME_RATING = 12,
        KEYWORD_RATING = 1;

    class Index {
        //region Static
        static sortResultList(resultList) {
            resultList.sort(function (a, b) {
                var res = 0;

                try {
                    res = b.weight - a.weight;

                    if (res === 0) {
                        res = a.name.localeCompare(b.name);
                    }

                    if (res === 0 && a.description) {
                        res = a.description.localeCompare(b.description);
                    }
                } catch (ex) {
                    console.error("Could not compare " + a.name + " with " + b.name + " - " + ex.message);
                }

                return res;
            });
            return resultList;
        }

        static prepareResult(result, minWeight, noLimit) {
            var maxWeight = 0,
                limit,
                nResults = 0,
                bestResults = [],
                resArr; // get the max weight from all result parts

            Object.keys(result).forEach(function (key) {
                resArr = result[key];

                if (resArr.length > 0) {
                    resArr[0].location = key;
                    bestResults.push(resArr[0]);
                    maxWeight = Math.max(maxWeight, resArr[0].weight);
                }
            }); // define a limit --> results below this limit will not pass the filter.

            if (noLimit) {
                limit = -1;
            } else {
                limit = maxWeight / 10;
            } // sort & filter the best results, then pick the first & therefore best one.


            bestResults = SearchUtils.Index.sortResultList(bestResults);
            bestResults = this._getFilteredList(bestResults, Math.max(limit, minWeight)); // limit them

            Object.keys(result).forEach(function (loc) {
                result[loc] = this._getFilteredList(result[loc], Math.max(limit, minWeight));
                nResults += result[loc].length;
            }.bind(this));
            result.count = nResults;
            result.maxWeight = maxWeight;

            if (bestResults.length > 0) {
                result.bestResult = bestResults[0];
                result.bestResult.isBest = true;
            }

            return result;
        }

        static _getFilteredList(inputList, weightLimit) {
            var result, i;

            for (i = 0; i < inputList.length; i++) {
                if (inputList[i].weight < weightLimit) {
                    break;
                }
            }

            result = inputList.splice(0, i);
            return result;
        } //endregion Static


        constructor(location) {
            this.name = this.constructor.name;
            this.location = location;
            this.index = {};
            this.items = {};
        }

        destroy() {
            delete this.items;
            delete this.index;
        }

        /**
         * Returns the number of items indexed in here.
         * @returns {number}
         */
        getSize() {
            return Object.keys(this.items).length;
        }

        /**
         * Will search the index for the word provided and return a list nResults searchresults
         * @param word
         * @param [nResults]    default: unlimited
         * @returns {*}
         */
        lookup(word, nResults) {
            var resultList,
                ratingMap = {},
                lcWord = word.toLowerCase(); // search for the word as a whole

            this._launchSearch(lcWord, ratingMap); // if the word as whole did not provide results and it's made up from different words, add them.


            if (Object.keys(ratingMap).length === 0) {
                this.split(lcWord).forEach(function (prt) {
                    // search for the parts of the keyword - but with a reduced weight.
                    this._launchSearch(prt, ratingMap, true);
                }.bind(this));
            } // reduce the ratingMap down to a sorted list of results.


            resultList = this._getSortedList(ratingMap);
            return resultList;
        }

        /**
         * Will ensure the item and it's keyword are properly indexed.
         * @param uuid      identifies the item to be indexed
         * @param name      the name used for searching for this item
         * @param descr     the description to use for this item
         * @param item      the item to be indexed
         */
        addItem(uuid, name, descr, item) {
            if (!name || name === "") {
                console.error(this.name, "Items that are to be indexed must at least have a name!");
                return;
            }

            this.addKeyword(name, uuid, NAME_RATING);
            item.searchName = name;
            item.searchDescription = descr;

            if (!this.items.hasOwnProperty(uuid)) {
                this.items[uuid] = item;
            }
        }

        /**
         * Will split up a text into its components (right now, those separated by a blankspace).
         * @param text      the text to split
         * @returns {Array} the array containing the components.
         * @private
         */
        split(text) {
            var prts = text.split(" ");

            if (prts.length === 1) {
                prts = [];
            }

            return prts;
        }

        /**
         * Adds the keyword with the proper rating to the search index.
         * @param text      the keyword to store
         * @param uuid      the uuid for the item to reference if this keyword is searched for
         * @param rating    how good this keyword identifies the item referenced by the uuid.
         * @private
         */
        addKeyword(text, uuid, rating) {
            if (nullEmptyString(text)) {
                var lKw = text.toLowerCase(),
                    // if no rating is given, pick a generic keyword rating.
                    weight = !rating && rating !== 0 ? KEYWORD_RATING : rating;

                if (!this.index.hasOwnProperty(lKw)) {
                    this.index[lKw] = {};
                }

                if (this.index[lKw].hasOwnProperty(uuid)) {
                    this.index[lKw][uuid] += weight;
                } else {
                    this.index[lKw][uuid] = weight;
                }
            }
        }

        // -----------------------------------------------------------------------------------------------------------
        // --------- Private Methods ---------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------------

        /**
         * Will launch a search for a given word and fills the (unordered) results into a rating map.
         * @param word      the word to search for.
         * @param ratingMap the map for the (unordered) results.
         * @param reduced   if true it means that the keyword has been split up and the weight has to be reduced.
         * @returns {{}}
         * @private
         */
        _launchSearch(word, ratingMap, reduced) {
            var evaluated, weight;
            Object.keys(this.index).forEach(function (key) {
                // get the evaluation  result for this keyword compared to the lookup word.
                evaluated = this._evaluateMatch(key, word);

                if (evaluated > 0) {
                    // retreive the items mapped to this keyword
                    Object.keys(this.index[key]).forEach(function (uuid) {
                        // compute a weight based on how close to the keyword it is and how important it is
                        weight = this.index[key][uuid] * evaluated; // ensure the weight is properly adopted.

                        weight = reduced ? weight / 4 : weight; // add the item to the rating map.

                        this._addToRatingMap(ratingMap, word, key, weight, uuid);
                    }.bind(this));
                }
            }.bind(this));
        }

        /**
         * Will update search hit to the rating map
         * @param ratingMap     the rating map to add the match to.
         * @param word          the word searched for
         * @param key           the keyword that did match
         * @param weight        the quality of the match
         * @param uuid          the UUID of the item matched by the keyword
         * @private
         */
        _addToRatingMap(ratingMap, word, key, weight, uuid) {
            if (weight <= 0) {// no match.
            } else if (!ratingMap.hasOwnProperty(uuid) && weight > 0) {
                // new entry.
                try {
                    ratingMap[uuid] = {
                        matches: [{
                            key: key,
                            word: word,
                            weight: weight
                        }],
                        // store the matches.
                        weight: weight,
                        // nr 1 feature for sorting
                        name: this.items[uuid].searchName,
                        // used for comparing.
                        description: this.items[uuid].searchDescription,
                        item: this.items[uuid] // the item might contain additional info.

                    };
                } catch (ex) {
                    console.error(this.name, "Could not process search result for " + uuid + " - " + ex);
                    console.error(this.name, " --> item: " + JSON.stringify(this.items[uuid]));
                }
            } else {
                // already something in the results --> update the entry
                ratingMap[uuid].matches.push({
                    key: key,
                    word: word,
                    weight: weight
                });
                ratingMap[uuid].weight += weight;
            }
        }

        /**
         * 0 = no match, otherwise its the larger the better.
         * @param keyword
         * @param lookup
         * @returns {number}
         * @private
         */
        _evaluateMatch(keyword, lookup) {
            var res = 0,
                idx,
                coverage,
                top = 10;

            if (keyword === lookup) {
                // exact hits must have at least double the weight
                res = top * 2;
            } else {
                // partial hit
                idx = keyword.indexOf(lookup);

                if (idx >= 0) {
                    // mind how many percent of the keyword are covered by the lookup
                    coverage = lookup.length / keyword.length;
                    res = top * coverage; // check if the lookup is only contained in the keyword, but it doesn't start with it.

                    if (idx >= 1) {
                        // the keyword doesn't start with the lookup, reduce the result.
                        res = res / 2;
                    }
                }
            }

            return Math.round(res);
        }

        /**
         * Will take all values from the map, put them in a list and sort them by their weight, name and description.
         * @param searchMap
         * @returns {Array}
         * @private
         */
        _getSortedList(searchMap) {
            // reduce the map to a list for sorting it.
            return SearchUtils.Index.sortResultList(Object.values(searchMap));
        }

    }

    SearchUtils.Index = Index;
    return SearchUtils;
}(window.SearchUtils || {});
