'use strict';

const _msFinderEvents = {
    NEW_SEARCH_STARTED: "new-search-started",
    FOUND_MINISERVER: "found-miniserver",
    SEARCH_FINISHED: "search-finished"
};


var MiniserverFinder = function () {
    var LOOKUP_TIME = 5000 + 1000; // +1s to make sure that the native sockets are ready again (AddressInUse error!)

    class MiniserverFinder {
        constructor() {
            Object.assign(this, EventHandler.Mixin);
            this.name = "MsFinder";
        }

        hasMSFinder() {
            return window.hasOwnProperty("msFinder");
        }

        startMiniserverFinder(isSecondTime) {
            Debug.Plugins.MiniserverFinder && console.log("MSFinder: startMiniserverFinder");

            if (!this.hasMSFinder()) {
                this.__useMockOnDevIf() && this.__simStartFinder();
                return;
            }

            if (ActiveMSComponent.getActiveMiniserver() !== null) {
                Debug.MSSession && console.log(this.name, "startMiniserverFinder -> StopMSSession first");
                CompChannel.emit(CCEvent.StopMSSession, true);
            }

            this.emit(_msFinderEvents.NEW_SEARCH_STARTED);
            this.foundMiniservers = [];
            msFinder.startLxFinder(this._foundMiniserver.bind(this), this._pluginError.bind(this));
            this.stopSearchTimeout = setTimeout(function () {
                msFinder.stopLxFinder(this._finishedSearch.bind(this), this._pluginError.bind(this));
            }.bind(this), LOOKUP_TIME);
        }

        stopMiniserverFinder() {
            Debug.Plugins.MiniserverFinder && console.log("MSFinder: stopMiniserverFinder");

            if (!this.hasMSFinder()) {
                this.__useMockOnDevIf() && this.__simStopFinder();
                return;
            }

            if (this.stopSearchTimeout) {
                clearTimeout(this.stopSearchTimeout);
            }

            msFinder.stopLxFinder(this._stoppedSearch.bind(this), this._pluginError.bind(this));
        }

        // Private methods
        _foundMiniserver(result) {
            if (result === true) {
                console.log("waiting for finish callback");
            } else {
                var ms,
                    newMS = [],
                    foundNewMS = false;

                for (var ip in result) {
                    if (result.hasOwnProperty(ip)) {
                        // port 80 is the default, so no need to show it
                        var suffix = ":80",
                            cleanIP = ip; // check, if :80 is the last part of ip (-> not delete :8000)

                        if (ip.indexOf(suffix, ip.length - suffix.length) !== -1) {
                            cleanIP = ip.replace(':80', '');
                        }

                        if (this._checkIfNewMiniserver(cleanIP) || Debug.Plugins.MiniserverFinder) {
                            Debug.Plugins.MiniserverFinder && console.log("found device with ip '" + cleanIP + "' and miniserver name '" + result[ip] + "'");
                            foundNewMS = true;
                            ms = {
                                msName: result[ip],
                                localUrl: cleanIP
                            };
                            newMS.push(ms);

                            if (Debug.Plugins.MiniserverFinder) {
                                ms.msName = ms.msName + " (" + (this.foundMiniservers.length + newMS.length) + ")";
                            }
                        }
                    }
                }

                if (foundNewMS) {
                    this.foundMiniservers = this.foundMiniservers.concat(newMS);
                    this.emit(_msFinderEvents.FOUND_MINISERVER, newMS, this.foundMiniservers);
                }
            }
        }

        _checkIfNewMiniserver(ip) {
            for (var i = 0; i < this.foundMiniservers.length; i++) {
                if (this.foundMiniservers[i].localUrl === ip) {
                    return false;
                }
            }

            return true;
        }

        _finishedSearch() {
            Debug.Plugins.MiniserverFinder && console.log("MSFinder: _finishedSearch");
            var nrOfMs = this.foundMiniservers.length;

            if (nrOfMs > 0) {
                console.log("search was succesfully stopped, " + nrOfMs + " found");

                if (Debug.Plugins.MiniserverFinder) {
                    GUI.Notification.createGeneralNotification({
                        title: nrOfMs + " Miniservers found!",
                        removeAfter: 5
                    });
                }
            } else {
                console.log("no miniserver was found, show errorview");
            }

            this.emit(_msFinderEvents.SEARCH_FINISHED, this.foundMiniservers);
        }

        _stoppedSearch() {
            console.log("MSFinder: _stoppedSearch");
            this.emit(_msFinderEvents.SEARCH_FINISHED, this.foundMiniservers);
        }

        _pluginError(e) {
            console.error("MSFinder: _pluginError: " + (e.message || JSON.stringify(e)), e);
            console.error(e);
        }

        // region simulated finder

        __useMockOnDevIf() {
            return PlatformComponent.isDeveloperInterface();
        }

        __simStartFinder() {
            this.emit(_msFinderEvents.NEW_SEARCH_STARTED);
            this.foundMiniservers = [];

            const dbgResults = this.__debugResults();
            const baseOffset = LOOKUP_TIME / dbgResults.length;

            this.__dbgTimeouts = [];
            dbgResults.forEach((resultPack, idx) => {
                this.__dbgTimeouts.push(
                    setTimeout(this._foundMiniserver.bind(this, resultPack), 1)
                );
            });

            this.stopSearchTimeout = setTimeout(this._finishedSearch.bind(this), LOOKUP_TIME);
        }

        __simStopFinder() {
            this.__dbgTimeouts.forEach(to => clearTimeout(to));
            this.__dbgTimeouts = [];

            this.stopSearchTimeout && clearTimeout(this.stopSearchTimeout);
        }

        __debugResults() {
            const results = [];

            results.push({
                "10.121.64.152": "B",
                "10.121.40.48": "A"
            });
            results.push({
                "10.121.64.143": "C",
                "10.7.7.65": "D"
            });

            return results;
        }


        // endregion

    }

    var MSFinder = MiniserverFinder; // EventHandler Mixin

    return new MSFinder();
}();

MiniserverFinder.Event = _msFinderEvents;
