'use strict';

window.Components = function (Components) {
    /**
     * This component contains all basic tasks for update checking. It has to be subclassed, then it can be used to
     * handle the update management of various platforms (e.g. Miniserver, Apps, Music Server).
     *
     * It will trigger loading the data at the right time, it will compare the versions, present the notification and
     * popup - plus it will store all the data needed to avoid showing the updates too often.
     */
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var DATEF = "YYYY-MM-DD";
        var DEFAULT_DATE = "0000-00-00",
            DEFAULT_VERSION = "0.0.0.0"; // days after which the notification or the popup will reappear

        var NOT_NOW_INTERVAL = 8,
            // after hitting "Not Now" in an update popup
            OKAY_INTERVAL = 1,
            // after opening the popup and hitting "More Info"
            UNREAD_POPUP_INTERVAL = 0; // after dismissing the notification.

        var OVERDUE_DELAY = 14; // days after which an update is considered overdue.

        class PlatformUpdateBaseExt extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.file = false;
                this.isPrepared = false;
                this._updateData = null;
                this.checkForUpdateDef = false;
                Debug.Update.UpdateBase && console.log(this.name + ": INIT");
                this.registerExtensionEv(this.component.ECEvent.StartUpdateCheck, this._launchPrepareForUpdateCheck.bind(this));
                this.registerExtensionEv(this.component.ECEvent.UpdateFileLoaded, this._updateFileLoaded.bind(this));
            }

            /**
             * Required as these extensions might be destroyed when the Miniserver changes.
             */
            destroy() {
                this.resetExtension(true);
                super.destroy(...arguments);
            }

            // ---------- PUBLIC METHODS -----------------

            /**
             * Will return a promise that resolves if an update is available. It will rejects with the err-arg set to
             * "false" if the current device is up to date. If err is true, well, then an error occurred. E.g. conn loss.
             * @returns {*}
             */
            checkForUpdate() {
                var promise;

                if (!this.checkForUpdateDef) {
                    this.checkForUpdateDef = Q.defer();
                } // store promise into a separate var as the deferred could be set to null inside compareAndRespond and
                // in that case we would be unable to return a promise to the caller.


                promise = this.checkForUpdateDef.promise; // check if the data is available already, or if we have to wait.

                if (this._dataReady()) {
                    this._respondToCheckUpdateRequest();
                }

                return promise;
            }

            /**
             * Same like checkForUpdate, but this will only resolve if an update is available, which is overdue already.
             * @return {*}
             */
            checkForOverdueUpdate() {
                var promise;

                if (!this.checkForOverdueUpdateDef) {
                    this.checkForOverdueUpdateDef = Q.defer();
                } // store promise into a separate var as the deferred could be set to null inside compareAndRespond and
                // in that case we would be unable to return a promise to the caller.


                promise = this.checkForOverdueUpdateDef.promise; // check if the data is available already, or if we have to wait.

                if (this._dataReady()) {
                    this._respondToCheckOverdueUpdateRequest();
                }

                return promise;
            }

            // ---------- METHODS TO OVERRIDE -----------------

            /**
             * Returns the update file ID of the current platform
             * @return {string}
             */
            getPlatformId() {
                throw new Error("getPlatformId needs to be implemented in an subclass!");
            }

            /**
             * Returns the update level of the current platform
             * @return {string}
             */
            getUpdateLevel() {
                throw new Error("getUpdateLevel needs to be implemented in an subclass!");
            }

            /**
             * Returns the platforms current version
             * @return {string}
             */
            getCurrentVersion() {
                throw new Error("getCurrentVersion needs to be implemented in an subclass!");
            }

            /**
             * Returns the message to show in the popup and in the update notification
             * @param newVersion    the version of the available update
             * @param currVersion
             * @param overdue       if true, the update is overdue already - should result in a firm message.
             * @return {string}
             */
            getMessage(newVersion, currVersion, overdue) {
                throw new Error("getMessage needs to be implemented in an subclass!");
            }

            /**
             * Returns the title to show in the popup and in the update notification
             * @param overdue       if true, the update is overdue already - should result in a firm title
             * @return {string}
             */
            getTitle(overdue) {
                throw new Error("getTitle needs to be implemented in an subclass!");
            }

            /**
             * Specifies what icon to use when prompting for updates!
             * @return {string}
             */
            getUpdateIcon() {
                throw new Error("getUpdateIcon needs to be implemented in an subclass!");
            }

            // ---------- METHODS that may be overridden -----------------

            /**
             * Returns the update info part for this platform with the proper update level
             * @return {*}
             */
            getUpdateInfo() {
                Debug.Update.UpdateBase && console.log(this.name + ": getUpdateInfo");

                if (!this._dataReady()) {
                    throw new Error("Cannot call getUpdateInfo while the data is not ready yet!");
                }

                if (!this.updateInfo) {
                    this.updateInfo = this._findUpdateInfo(this.file, this.getPlatformId(), this.getUpdateLevel());
                }

                return this.updateInfo;
            }

            /**
             * Called when the update checks are initiated. The Update file download will also start at that time. E.g.
             * the update level can be loaded here, or the current version
             * @return {Promise} a promise that will resolve when the data acquired by this ext is ready for the update check.
             */
            prepareForUpdateCheck() {
                Debug.Update.UpdateBase && console.log(this.name + ": prepareForUpdateCheck");
                return this._loadStoredUpdateData();
            }

            /**
             * Will return true if an update is available based on the current version and the current updateInfo for
             * this platform.
             * @return {boolean}
             */
            isUpdateAvailable() {
                var currVersion = this.getCurrentVersion(),
                    updInfo = this.getUpdateInfo(),
                    available = false,
                    dontPromoteFor;

                if (!updInfo) {
                    Debug.Update.UpdateBase && console.log(this.name + ": isUpdateAvailable: false - no Update Info!");
                    return available;
                }

                available = isOlderVersionThan(currVersion, updInfo.version);
                Debug.Update.UpdateBase && console.log(this.name + ": isUpdateAvailable: " + available);
                Debug.Update.UpdateBase && console.log("        curr version: " + currVersion);
                Debug.Update.UpdateBase && console.log("         new version: " + updInfo.version);

                if (available && (updInfo.hasOwnProperty("dontpromotefor") || updInfo.hasOwnProperty("dontPromoteFor"))) {
                    // when converting from xml to json, it will become lower case. But as for testing, camelcase might still be in use.
                    dontPromoteFor = updInfo.dontpromotefor || updInfo.dontPromoteFor;
                    available = isOlderVersionThan(currVersion, dontPromoteFor);
                    Debug.Update.UpdateBase && console.log("   mind dontPromptFor: " + dontPromoteFor + " =  " + available);
                }

                return available;
            }

            /**
             * Returns the function to call when an update is to be started.
             * @param link the link to the file or to the place where the update is available (app/play store)
             */
            getStartUpdateFunction(link) {
                return null; // default - not available
            }

            /**
             * Returns the string that is used to store the data of this platforms update handling
             * @return {string}
             */
            getStorageName() {
                return this.name;
            }

            /**
             * Days after which the notification will reappear if the user has clicked on the notification but hit not
             * now on the popup shown afterwards.
             * @return {number}
             */
            getNotNowInterval() {
                return NOT_NOW_INTERVAL;
            }

            /**
             * Days after which the notification will reappear if the user hit okay (= install) but the update wasn't
             * successful and the version remains outdated
             * @return {number}
             */
            getOkayInterval() {
                return OKAY_INTERVAL;
            }

            /**
             * Days after which the notification will reappear if it has been simply dismissed without reading it.
             * @return {number}
             */
            getUnreadPopupInterval() {
                return UNREAD_POPUP_INTERVAL;
            }

            /**
             * Will reset all data currently loaded & remove all potentially shown notifications/popups.
             * @param cancelUpdateCheck if true, the current checkForUpdateDef will be rejected
             */
            resetExtension(cancelUpdateCheck) {
                if (this._updateNotification) {
                    this._updateNotification.remove(false);

                    delete this._updateNotification;
                }

                if (this._updatePopup) {
                    NavigationComp.removePopup(this._updatePopup);
                    delete this._updatePopup;
                }

                if (this.checkForUpdateDef && cancelUpdateCheck) {
                    this.checkForUpdateDef.reject(true);
                    this.checkForUpdateDef = false;
                }

                this.isPrepared = false;
                this.file = false;
                this._updateData = false;
            }

            /**
             * Returns the highest update level or Release if no update levels have been provided
             * The update levels can be passed as individual arguments
             * @note ALPHA (Test) > BETA > RELEASE
             * @returns {string|*}
             */
            getHighestUpdateLeve() {
                var argsArray,
                    highestUpdateLevel = this.component.UpdateLevel.RELEASE;

                if (arguments.length) {
                    // Convert the arguments object to an Array to be able to use the Arrays prototype functions
                    argsArray = [].slice.call(arguments);

                    if (argsArray.indexOf(this.component.UpdateLevel.ALPHA_LEGACY) !== -1) {
                        highestUpdateLevel = this.component.UpdateLevel.ALPHA_LEGACY;
                    } else if (argsArray.indexOf(this.component.UpdateLevel.BETA) !== -1) {
                        highestUpdateLevel = this.component.UpdateLevel.BETA;
                    }
                }

                return highestUpdateLevel;
            }

            // -------------------- Private -------------------

            /**
             * Will trigger that this platform acquires all data needed to check if an update is available (excl loading
             * the update file itself, which is handled by the update file extension.
             * @private
             */
            _launchPrepareForUpdateCheck() {
                Debug.Update.UpdateBase && console.log(this.name + ": _launchPrepareForUpdateCheck"); // Starting a new update check session, dismiss old windows & reset data..

                this.resetExtension();
                this.prepareForUpdateCheck().then(function (res) {
                    this.isPrepared = true;
                    this._dataReady() && this._checkForUpdate();
                }.bind(this), function (err) {
                    this.isPrepared = false;
                    console.error(this.name, "Could not prepare for update check! " + err);
                }.bind(this));
            }

            /**
             * Called when the update file has been loaded (from the server or the filesystem)
             * @param ev
             * @param file
             * @private
             */
            _updateFileLoaded(ev, file) {
                Debug.Update.UpdateBase && console.log(this.name + ": _updateFileLoaded");
                this.file = file;
                this._dataReady() && this._checkForUpdate();
            }

            /**
             * Will return true if all data needed for checking if an update is available is ready.
             * @return {boolean}
             * @private
             */
            _dataReady() {
                var res = this.isPrepared && !!this.file;
                Debug.Update.UpdateBase && console.log(this.name + ": _dataReady: " + res);
                return res;
            }

            /**
             * Will check for the updates availability and if it should be promoted. if so, it will promote it.
             * @private
             */
            _checkForUpdate() {
                Debug.Update.UpdateBase && console.log(this.name + ": _checkForUpdate");
                var handled = false,
                    isOverdue,
                    newVersion = this.getUpdateInfo().version,
                    showUpdatePrompt = true; // check if the version has changed to the one we've already asked for.

                if (this._updateData.lastAskedVersion !== newVersion) {
                    // the version changed, updated the _updateData object for the new one!
                    this._updateLastAskedVersion(newVersion);
                } else {
                    showUpdatePrompt = false;
                }
                // is this update overdue already?


                isOverdue = this._isOverdue(); // all data has been loaded. now either show a popup - or if an updateCheck has already been requested -
                // respond to it!

                if (this.checkForUpdateDef) {
                    this._respondToCheckUpdateRequest();

                    handled = true;
                }

                if (this.checkForOverdueUpdateDef) {
                    handled = handled || isOverdue;

                    this._respondToCheckOverdueUpdateRequest();
                }

                if (!handled && this.isUpdateAvailable(showUpdatePrompt) && this._shouldPromptUpdate()) {
                    this._presentUpdateNotification();
                }
            }

            /**
             * Will return true if the overdueAfter-date has been reached.
             * @return {boolean}
             * @private
             */
            _isOverdue() {
                var res = false;
                /* // as some of our partners have trouble updating their systems in time, the orange bar has been disabled in accordance with Tom right now.
                if (this._updateData && this._updateData.hasOwnProperty("overdueAfter")) {
                    var curr = this._getCurrentDate();
                    res = this._updateData.overdueAfter <= curr;
                    Debug.Update.UpdateBase && console.log(this.name, "_isOverdue: limit=" +
                        this._updateData.overdueAfter + ", today=" + curr + " --> " + res);
                } else {
                    Debug.Update.UpdateBase && console.error("No update data available yet, assuming not overdue");
                }
                */

                return res;
            }

            /**
             * Will return true if an update is available.
             * @return {boolean|*}
             * @private
             */
            _shouldPromptUpdate() {
                var currDate = this._getCurrentDate(),
                    result;

                result = this._updateData.dontAskBefore < currDate; // yyyy-mm-dd is comparable via string compare

                Debug.Update.UpdateBase && console.log(this.name + ": _shouldPromptUpdate: " + result);
                return result;
            }

            /**
             * Will try to look up the update entry for the platform and update-level provided.
             * @param file
             * @param platformId
             * @param updateLevel
             * @returns {boolean}
             */
            _findUpdateInfo(file, platformId, updateLevel) {
                var pfEntry,
                    lvlEntry = false,
                    found = false,
                    pfType,
                    lookupPlatform = platformId.toLowerCase();
                Debug.Update.UpdateBase && console.log(this.name, "_findUpdateInfo " + lookupPlatform + " / " + updateLevel);

                try {
                    // lookup platform
                    file.some(function (entry) {
                        pfType = entry.type.toLowerCase(); // ensure the comparison works (maybe the updatecheck provides camelcase)

                        if (pfType === lookupPlatform) {
                            Debug.Update.UpdateBase && console.log("    found platform " + pfType);
                            pfEntry = entry;
                            found = true;
                        }

                        return found;
                    }); // lookup update level

                    if (pfEntry) {
                        lvlEntry = pfEntry.levels[updateLevel];
                        Debug.Update.UpdateBase && console.log("    found updateLevel " + updateLevel);
                    }
                } catch (ex) {
                    console.warn("Error while parsing the update-check-file for an app update!", ex);
                }

                Debug.Update.UpdateBase && console.log("       found: " + JSON.stringify(lvlEntry));
                return lvlEntry;
            }

            /**
             * Returns the current Miniserver date in the format DATEF
             * @private
             */
            _getCurrentDate() {
                return ActiveMSComponent.getMiniserverTimeAsFakeUTC().format(DATEF);
            }

            // --------------------------------------------------
            // -----------   Notification Handling    -----------
            // --------------------------------------------------
            _presentUpdateNotification() {
                if (this._updateNotification || this._updatePopup) {
                    console.error("Update Notification already shown. Don't show again!");
                    return;
                }

                var updateInfo = this.getUpdateInfo(),
                    version = updateInfo.version,
                    changelog = updateInfo.changelog;
                Debug.Update.UpdateBase && console.log(this.name + ": _showUpdateNotification: " + version + " - " + changelog); // create the notification content

                this._updateNotification = GUI.Notification.createGeneralNotification({
                    iconSrc: this.getUpdateIcon(),
                    iconColor: this._getNotificationIconColor(),
                    title: this.getTitle(),
                    clickable: true,
                    closeable: true
                }, NotificationType.SUCCESS);

                this._updateNotification.on(GUI.Notification.CLICK_EVENT, function () {
                    this._updateNotification.remove(false);

                    this._handleShowPopup(version, changelog);
                }.bind(this));

                this._updateNotification.on(GUI.Notification.MARK_AS_READ_EVENT, this._handleNotificationDismissed.bind(this));

                this._updateNotification.on("destroy", function () {
                    delete this._updateNotification;
                }.bind(this));
            }

            /**
             * After calling this method, there will be no popup again for the day (on Apps), but the next day, it'll
             * appear again.
             * @private
             */
            _handleNotificationDismissed() {
                Debug.Update.UpdateBase && console.log(this.name + ": _handleNotificationDismissed"); // it should ask again.

                this._askAgainAfterDays(this.getUnreadPopupInterval());
            }

            // --------------------------------------------------
            // -----------      Popup Handling        -----------
            // --------------------------------------------------

            /**
             * Will present a popup that informs the user of an update that might be available for his Miniserver.
             * @param version
             * @param changelog
             * @private
             */
            _handleShowPopup(version, changelog) {
                Debug.Update.UpdateBase && console.log(this.name + ": _handleShowPopup");

                if (this._updatePopup) {
                    console.error("Update Popup already shown!");
                    return;
                } // prepare the update popup content


                var overdue = this._isOverdue(),
                    info = this.getUpdateInfo(),
                    content = {
                        icon: this.getUpdateIcon(),
                        title: this.getTitle(overdue),
                        message: this._getMessageWithCurrVersion(overdue),
                        buttonCancel: _("update.not-now"),
                        color: this._getPopupIconColor()
                    },
                    updateFn = this.getStartUpdateFunction(info.path); // prepare buttons with callbacks!


                this.buttons = [];

                if (updateFn) {
                    this.buttons.push({
                        title: _("update.now"),
                        action: updateFn
                    });
                }

                if (info.changelog) {
                    this.buttons.push({
                        title: _("update.more-info")
                    });
                }

                content.buttons = this.buttons; // present it and respond.

                this._updatePopup = NavigationComp.showPopup(content);

                this._updatePopup.then(this._handleUpdateResponse.bind(this), this._handleUpdateResponse.bind(this, -1));
            }

            /**
             * Will return the please update message along with an info showing the version that is currently running
             * on the platform (App, Miniserver, Music Server)
             */
            _getMessageWithCurrVersion(overdue) {
                return this.getMessage(this.getUpdateInfo().version, this.getCurrentVersion(), overdue);
            }

            /**
             * Decides based on the index on what to do next.
             * @param idx
             * @private
             */
            _handleUpdateResponse(idx) {
                this._updatePopup = null;

                if (this.buttons[idx] && this.buttons[idx].action) {
                    // install action
                    this.buttons[idx].action();

                    this._askAgainAfterDays(this.getOkayInterval());
                } else if (idx < 0) {
                    // cancel
                    this._askAgainAfterDays(this.getNotNowInterval());
                } else {
                    // show changelog
                    NavigationComp.openWebsite(this.getUpdateInfo().changelog);

                    this._askAgainAfterDays(this.getOkayInterval());
                }
            }

            /**
             * After calling this method, the user will be asked again for an update after the number of days given. If
             * a new update is published in the mean time, meaning that there are new features and fixes, the notification
             * will appear regardless of this date.
             * @param   days    the number of days to wait before asking again.
             * @private
             */
            _askAgainAfterDays(days) {
                Debug.Update.UpdateBase && console.log(this.name + ": _askAgainAfterDays");
                var now = ActiveMSComponent.getMiniserverTimeAsFakeUTC();
                now.add(days, "days");

                this._updateDontAskBefore(now.format(DATEF));

                Debug.Update.UpdateBase && console.log("Don't ask the user for an update again, until: " + this._updateData.dontAskBefore);
            }

            /**
             * If an update check was manually requested, this will answer it as soon as the data is available. It will
             * not prompt anything, it will simply resolve with an updateInfo if an update is available or reject if not.
             * @private
             */
            _respondToCheckUpdateRequest() {
                if (!this.checkForUpdateDef) {
                    return; // nothing to do - no one requested anything)
                }

                if (this.isUpdateAvailable()) {
                    this.checkForUpdateDef.resolve(this._getUpdateAvailableObject());
                } else {
                    this.checkForUpdateDef.reject(false);
                } // reset otherwise the next request for an update check will reuse the old deferred, which already is
                // resolved or rejected by then.


                this.checkForUpdateDef = null;
            }

            /**
             * Just like _respondToCheckUpdateRequest, but for overdue updates only.
             * @private
             */
            _respondToCheckOverdueUpdateRequest() {
                if (!this.checkForOverdueUpdateDef) {
                    return; // nothing to do - no one requested anything)
                }

                if (this.isUpdateAvailable() && this._isOverdue()) {
                    this.checkForOverdueUpdateDef.resolve(this._getUpdateAvailableObject());
                } else {
                    this.checkForOverdueUpdateDef.reject(false);
                } // reset otherwise the next request for an update check will reuse the old deferred, which already is
                // resolved or rejected by then.


                this.checkForOverdueUpdateDef = null;
            }

            /**
             * Returns an object containing all the infos needed to respond to an check for update request.
             * @return {*}
             * @private
             */
            _getUpdateAvailableObject() {
                var updateAvObj = this.getUpdateInfo(),
                    overdue = this._isOverdue();

                updateAvObj.title = this.getTitle(overdue);
                updateAvObj.message = this.getMessage(updateAvObj.version, this.getCurrentVersion(), overdue);
                updateAvObj.icon = this.getUpdateIcon();
                updateAvObj.showMore = this._handleShowPopup.bind(this, updateAvObj.version, updateAvObj.changelog);
                return updateAvObj;
            }

            // ----------------------------------------------------
            // --   Update and load this miniservers data ---------
            // ----------------------------------------------------

            /**
             * Used to update the version string of the last update we asked the user to update.
             * @param updVersion
             * @private
             */
            _updateLastAskedVersion(updVersion) {
                this._updateData.lastAskedVersion = updVersion;
                this._updateData.dontAskBefore = DEFAULT_DATE;
                this._updateData.overdueAfter = this._getNewOverdueDate();

                this._storeUpdateData();
            }

            /**
             * Will update and store the date, when the user can be asked to update again (if a new update is published
             * again until then, this value becomes irrelevant).
             * @param newDate
             * @private
             */
            _updateDontAskBefore(newDate) {
                this._updateData.dontAskBefore = newDate;

                this._storeUpdateData();
            }

            /**
             * Will store the updateMsData object in the settings of the current Miniserver.
             * @private
             */
            _storeUpdateData() {
                Debug.Update.UpdateBase && console.log(this.name + ": _storeUpdateData for " + this.getStorageName());

                if (UpdateComp.saveUpdateInfo(this.getStorageName(), this._updateData)) {
                    Debug.Update.UpdateBase && console.log("    stored update data: " + JSON.stringify(this._updateData));
                } else {
                    console.warn("Could not save UpdateData for " + this.getStorageName() + ".");
                }
            }

            /**
             * Will retrieve the msUpdateData that has been stored for the current platform. It'll initialize a new
             * object if nothing has been stored so far.
             * @private
             */
            _loadStoredUpdateData() {
                Debug.Update.UpdateBase && console.log(this.name + ": _loadStoredUpdateData for " + this.getStorageName());
                return UpdateComp.loadUpdateInfo(this.getStorageName()).then(function (data) {
                    if (!data) {
                        this._prepareDefaultData();
                    } else {
                        this._updateData = data;
                    }

                    Debug.Update.UpdateBase && console.log("    loaded update data: " + JSON.stringify(this._updateData));
                }.bind(this), function (err) {
                    this._prepareDefaultData();
                }.bind(this));
            }

            /**
             * If no previous update data is found, initialize the update data with default values.
             * @private
             */
            _prepareDefaultData() {
                Debug.Update.UpdateBase && console.log("    Nothing stored yet, initialize new object!"); // nothing stored yet, initialize!

                this._updateData = {
                    lastAskedVersion: DEFAULT_VERSION,
                    dontAskBefore: DEFAULT_DATE,
                    overdueAfter: this._getNewOverdueDate()
                };
            }

            /**
             * Will return a string representing the next overdue date, OVERDUE_DELAY days from today.
             * @private
             */
            _getNewOverdueDate() {
                // a certain time after which a new version has appeared, it will be marked as overdue and may be prompted
                // in a different way.
                var now = ActiveMSComponent.getMiniserverTimeAsFakeUTC();
                now.add(OVERDUE_DELAY, "days");
                return now.format(DATEF);
            }

            /**
             * Returns the fill color of the notification icon
             * @private
             */
            _getNotificationIconColor() {
                return window.Styles.colors.green;
            }

            /**
             * Returns the fill color of the Popup icon
             * @returns string
             * @private
             */
            _getPopupIconColor() {
                return this._isOverdue() ? window.Styles.colors.orange : window.Styles.colors.green;
            }

        }

        Components.Update.extensions.PlatformUpdateBaseExt = PlatformUpdateBaseExt;
    }
    return Components;
}(window.Components || {});
