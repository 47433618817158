'use strict';
/**
 * Created by loxone on 01.10.18.
 */

var ConnectivityTools = function (ConnectivityTools) {
    var EXP_BACKOFF = 1.34;
    var ProbeStatus = {
        STOPPED: -10,
        // manually stopped.
        FAILED: -2,
        // failed permanently, no use retrying.
        ONGOING: -1,
        // failed, retrying
        READY: 0,
        // ready, not started yet!
        PENDING: 1,
        // no response so far
        SUCCESSFUL: 2 // SUCCESSFUL

    };

    class ReachabilityProbe {
        /**
         * Will create a reachability check probe ready to be launched.
         * @param target                most important - what targets reachability needs to be checked?
         * @param baseTimeout           depending on the reach mode, a base timeout for the individual requests needs to be set
         * @param maxTimeout            the maximum time between two requests
         * @param reachMode             the probe needs to know if it's attempt is internal or external.
         * @param useHttps              if the probe should try using https instead of http.
         * @param [serialNoToIgnore]    Array of serial numbers which should be ignored
         */
        constructor(target, baseTimeout, maxTimeout, reachMode, useHttps, serialNoToIgnore) {
            this._reachMode = reachMode;
            this._useHttps = useHttps;
            this._serialNoToIgnore = serialNoToIgnore;
            this._baseTimeout = baseTimeout;
            this._target = target;
            this._probeDeferred = null;
            this._msIsUpdating = false;
            this._maxTimeout = maxTimeout;
            this.name = "ReachabilityProbe " + translateReachMode(reachMode) + "/" + (useHttps ? "HTTPS" : "REGULAR");
            Debug.ReachabilityProbe && console.log(this.name, "init for " + target);

            this._setProbeStatus(ProbeStatus.READY);
        }

        destroy() {
            this._setProbeStatus(ProbeStatus.STOPPED);

            this._probeDeferred = null;

            this._stopRequest();

            this._stopRetryTimeout();
        }

        /**
         * The probe will start to evaluate the targets reachablity.
         * @return {*}  the promise that will resolve when the target is reachable or reject if its not.
         */
        launch() {
            this._attemptCounter = 0;

            this._launchAttempt();

            this._setProbeStatus(ProbeStatus.PENDING);

            return this._initializeProbeFeedback();
        }

        getCommand() {
            return Commands.API_INFORMATION;
        }

        getStatus() {
            return this._status;
        }

        getInfo() {
            return {
                useTLS: this._useHttps,
                reachMode: this._reachMode,
                target: this._target,
                attempts: this._attemptCounter,
                cmd: this.getCommand(),
                timeout: this._getTimeoutForAttempt(this._attemptCounter),
                status: this._status,
                data: this._statusData,
                updating: this._msIsUpdating
            };
        }

        hasTld(tld) {
            return new URL(this._target).origin.endsWith(tld);
        }

        overrideMaxTimeout(maxTimeout) {
            this._maxTimeout = maxTimeout;
        }

        isSuccessful() {
            return this._status === ProbeStatus.SUCCESSFUL;
        }

        isPending() {
            return this._status === ProbeStatus.PENDING;
        }

        didFail() {
            return this._status === ProbeStatus.FAILED;
        }

        isStopped() {
            return this._status === ProbeStatus.STOPPED;
        }

        isReady() {
            return this._status === ProbeStatus.READY;
        }

        isLocal() {
            return this._reachMode === ReachMode.LOCAL;
        }

        /**
         * Checks if the response returned by the command confirms the reachability of a loxone miniserver.
         * @param data
         * @return {boolean}
         */
        isValidResponse(data) {
            var isValid = getLxResponseCode(data) === ResponseCode.OK,
                value = this._getLxResponseValue(data);

            if (isValid && value && value.snr && value.version) {
                if (this._serialNoToIgnore && this._serialNoToIgnore.length) {
                    isValid = !this._serialNoToIgnore.includes(cleanSerialNumber(value.snr));
                }

                if (isValid) {
                    this._serialNo = value.snr;
                    this._version = value.version;
                }

                if (value.hasOwnProperty('hasEventSlots')) {
                    this._hasEventSlots = value.hasEventSlots;
                } else {
                    this._hasEventSlots = true;
                }

                if (value.hasOwnProperty("isInTrust")) {
                    this._isInTrust = value.isInTrust;
                } else {
                    this._isInTrust = false;
                }

                if (value.hasOwnProperty("certTLD")) {
                    this._certTld = value.certTLD;
                } else {
                    this._certTld = null;
                }
            }

            return isValid;
        }

        /**
         * The data returned by the response was not valid, maybe it's not a miniserver. Maybe it's a miniserver
         * who is currently rebooting, or it is a miniserver which may be ignored because the user clicked cancel on the "Serial number change dialog"
         * @param data
         * @return {boolean}
         */
        shouldRetryAfterInvalidResponse(data) {
            var resCode = getLxResponseCode(data);
            return resCode >= 500 || // The Miniserver is currently rebooting
                resCode === ResponseCode.OK; // The Miniserver is up and running, but may be ignored (Different Miniservers with the same address)
        }

        /**
         * Handle an error returned by the command. Check if retrying to reach this host is a good idea or useless.
         * @param error
         * @param textStatus
         * @return {boolean}
         */
        shouldRetryOnError(error, textStatus) {
            var shouldRetry = false; // if not stopped, always retry unless the request was aborted.

            if (!this.isStopped()) {
                shouldRetry = textStatus !== "abort";
            }

            return shouldRetry; // by default, yes - we will retry as long as it takes.
        }

        /**
         * This method needs to be overwritten by the legacyProbe baseclass as CORS flags might be of use there.
         * @param timeout
         * @return {}
         * @private
         */
        getRequestOptions(timeout) {
            return {
                dataType: "json",
                timeout: timeout,
                cache: false
            };
        }

        stopRetryTimeout() {
            this._stopRetryTimeout();
        }

        // Private
        _setProbeStatus(newStatus, data) {
            Debug.ReachabilityProbe && console.log(this.name, this._translateStatus(newStatus) + (data ? " - " + JSON.stringify(data) : ""));
            this._status = newStatus;
            this._statusData = data;
        }

        _translateStatus(status) {
            var result = "-";

            switch (status) {
                case ProbeStatus.FAILED:
                    result = "Failed";
                    break;

                case ProbeStatus.ONGOING:
                    result = "Ongoing";
                    break;

                case ProbeStatus.SUCCESSFUL:
                    result = "Successful";
                    break;

                case ProbeStatus.PENDING:
                    result = "Pending";
                    break;

                case ProbeStatus.STOPPED:
                    result = "Stopped";
                    break;

                case ProbeStatus.READY:
                    result = "Ready";
                    break;

                default:
                    break;
            }

            return result;
        }

        // Promise Handling
        _initializeProbeFeedback() {
            this._probeDeferred = Q.defer();
            return this._probeDeferred.promise;
        }

        /**
         * Informs that the probe has successfully detected it's targets reachability.
         * @param serial            the serial number of the target
         * @param version           the firmware version of the target
         * @param isInTrust         if the Miniserver is a member of a Trust
         * @param [certTld]         if provided a different top level domain than .com is to be used for RC/TLS
         * @private
         */
        _probeSucceeded(serial, version, isInTrust, certTld) {
            this._msIsUpdating = false;

            this._setProbeStatus(ProbeStatus.SUCCESSFUL, {
                serialNo: cleanSerialNumber(serial),
                version: version,
                isInTrust: isInTrust,
                certTld: certTld
            }); // avoid informing when the probe has been stopped already


            !this.isStopped() && this._probeDeferred && this._probeDeferred.resolve(this);
            this._probeDeferred = null;
        }

        _probeFailed(reason) {
            this._msIsUpdating = false;

            this._setProbeStatus(ProbeStatus.FAILED, reason); // avoid informing when the probe has been stopped already


            !this.isStopped() && this._probeDeferred && this._probeDeferred.reject(this);
            this._probeDeferred = null;
        }

        _probeIsRetrying(reason) {
            this._setProbeStatus(ProbeStatus.ONGOING, reason); // avoid informing when the probe has been stopped already


            !this.isStopped() && this._probeDeferred && this._probeDeferred.notify(this);
        }

        // Detecting reachability
        _launchAttempt() {
            // prefix increment the attempt otherwise we'd have 0 timeout initially
            this._attemptCounter++;

            var timeout = this._getTimeoutForAttempt(this._attemptCounter),
                cmd = this.getCommand();

            this._currentRq = this._sendRequest(this._target, cmd, timeout);

            this._currentRq.done(this._handleResponse.bind(this));

            this._currentRq.fail(this._handleError.bind(this));
        }

        _sendRequest(address, command, timeout) {
            let fullAddr = address;
            if (address.hasSuffix("/")) {
                fullAddr = address + command;
            } else {
                fullAddr = address + "/" + command;
            }

            // BG-I27846: on android occasionally the requests fail instantly after the first one didn't go through
            // adding a cache buster may fix this behaviour, since other requests (e.g. getip) are working.
            let target = new URL(fullAddr);
            target.searchParams.append("cacheBstr", Date.now());
            fullAddr = target.toString();

            Debug.ReachabilityProbe && console.log(this.name, "_sendRequest: " + fullAddr + " (timeout:" + timeout / 1000 + "s)");
            var promise = $.ajax(fullAddr, this.getRequestOptions(timeout));
            Debug.Communication && CommTracker.track(promise, CommTracker.Transport.HTTP, fullAddr);
            return promise;
        }

        _stopRequest() {
            this._currentRq && this._currentRq.abort();
            this._currentRq = null;
        }

        // Handling Request Responses
        _handleResponse(data) {
            Debug.ReachabilityProbe && console.log(this.name, "_handleResponse: " + JSON.stringify(data));

            if (this.isValidResponse(data)) {
                if (this._hasEventSlots) {
                    this._probeSucceeded(this._serialNo, this._version, this._isInTrust, this._certTld);
                } else {
                    this._startRetryTimeout(ResponseCode.NO_MORE_EVENT_SLOTS);
                }
            } else if (this.shouldRetryAfterInvalidResponse(data)) {
                this._startRetryTimeout();
            } else {
                this._probeFailed(data);
            }
        }

        _handleError(request, textStatus) {
            var errCode = request.status;
            Debug.ReachabilityProbe && console.error(this.name, "_handleError: " + errCode + ", " + JSON.stringify(textStatus));

            if (this.shouldRetryOnError(request, textStatus)) {
                this._msIsUpdating = request.statusText === "Miniserver Updating";

                this._startRetryTimeout(errCode);
            } else {
                this._probeFailed(errCode);
            }
        }

        // Handling Timeouts and Retries

        /**
         * Will return compute a timeout that fits the current attempt. Will grow larger the longer it takes. A timeout
         * for a retry will always be shorter than the timeout that specifies how long to wait for a response of a request.
         * @param attempt       how often did this probe try already?
         * @return {number}
         * @private
         */
        _getTimeoutForAttempt(attempt) {
            var time; // the first 3 attempts must be super quick, as any successful remote response might wait for it.

            if (this._reachMode === ReachMode.LOCAL && attempt < 4) {
                time = this._baseTimeout;
            } else {
                time = this._baseTimeout * attempt; // when retrying more than once, include the exp. backoff to have the timeout grow larger each time

                if (attempt > 1) {
                    // attempt starts with 1 initially!
                    time *= EXP_BACKOFF;
                } // ensure it doesn't exceed 2 or 5 seconds (local / remote).


                time = Math.min(this._maxTimeout, time);
            }

            return time;
        }

        /**
         * Returns the waiting period in between attempts to check the reachability
         * @param attempt
         * @return {number}
         * @private
         */
        _getRetryDelay(attempt) {
            var delay = this._getTimeoutForAttempt(attempt); // the first few times, locally retry right away - then at worst case retry every 2.5 seconds


            if (attempt < 4 && this._reachMode === ReachMode.LOCAL) {
                delay = 50;
            } else {
                delay = delay / 4;
            }

            return delay;
        }

        /**
         * Will start a timeout that will initiate a retry.
         * @param [reason]  Might provide info on why a retry is performed (rebooting, timeout, ..)
         * @private
         */
        _startRetryTimeout(reason) {
            var timeout = this._getRetryDelay(this._attemptCounter); // only increment with each new attempt.


            this._timeout = setTimeout(function () {
                this._timeout = null;

                this._launchAttempt();
            }.bind(this), timeout);

            this._probeIsRetrying(reason);
        }

        _stopRetryTimeout() {
            this._timeout && clearTimeout(this._timeout);
            this._timeout = null;
        }

        /**
         * As the lx response value from the API request isn't proper JSON, it needs to be manually adopted before being
         * parsed. It won't work with the lxUtils method, as there only converting escaped ' is supported. Don't adopt it
         * at that central tool function, as there it will mess with response values of other result values (e.g. autopilot)
         * @param data      the data to get the response value from.
         * @private
         */
        _getLxResponseValue(data) {
            var valString = getLxResponseValue(data, true);
            valString = valString.replace(/'/g, '"'); // converts ' to " -> e.g. for jdev/cfg/api-Response

            return JSON.parse(valString);
        }

    }

    ConnectivityTools.ReachabilityProbe = ReachabilityProbe;
    return ConnectivityTools;
}(ConnectivityTools || {});
