'use strict';

VendorHub.DeviceInfo = function () {
    var AppState = {
        UNDEFINED: "undefined",
        PAUSED: "pause",
        RESUMED: "resumed"
    };
    var currentAppState = AppState.UNDEFINED; // ---- On iOS there are additional states (resign/active) that may occur in addition to pause/resume

    var iOSAppState = {
        UNDEFINED: "undefined",
        ACTIVE: "active",
        RESIGNED: "resigned"
    };
    var currentiOSAppState = iOSAppState.UNDEFINED;
    var devInfoAdapter = {},
        appInfoObj = appInfoObject(),
        platTypeObj = platformInfoObject(),
        noAnimationStyleSheet; // public methods

    devInfoAdapter.getAppInfoObj = function getAppInfoObj() {
        return appInfoObj;
    };

    devInfoAdapter.getPlatformInfoObj = function getPlatformInfoObj() {
        return platTypeObj;
    }; // private methods


    var onPauseTimeout = null,
        onResumeTimeout = null,
        isPaused = false,
        PAUSE_THRESHOLD = 200;

    var onPause = function onPause() {
        if (!verifyAppStateChange(AppState.PAUSED)) {
            return;
        }

        console.log("onPause Event"); // we do not need this on ios devices. This is just used for NFC!

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
            if (onResumeTimeout !== null) {
                clearTimeout(onResumeTimeout);
                onResumeTimeout = null;
            }

            if (onPauseTimeout === null) {
                onPauseTimeout = setTimeout(onPauseAfterTimeout, PAUSE_THRESHOLD);
            }
        } else {
            onPauseAfterTimeout();
        }
    };

    var onPauseAfterTimeout = function onPauseAfterTimeout() {
        console.log("onPauseAfterTimeout");
        onPauseTimeout = null;
        isPaused = true;
        CompChannel.emit(CCEvent.Pause); // prevent animations in background (Groot!)

        noAnimationStyleSheet = noAnimationStyleSheet || createNoAnimationStyleSheet();
        $("head").append(noAnimationStyleSheet);
    };

    var onResume = function onResume() {
        if (!verifyAppStateChange(AppState.RESUMED)) {
            return;
        }

        console.log("onResume Event"); // resume animations - may not be present yet, e.g. android auto "open settings" sends onPause and immediately
        // afterwards an onResume --> if the app has never been sent to the BG before, the noAnimationStyleSheet won't
        // exist and hence this will crash --> meaning that the onPauseAfterTimeout will thrown after the onResume.

        noAnimationStyleSheet && noAnimationStyleSheet.remove(); // we do not need this on ios devices. This is just used for NFC!

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.Android) {
            if (onPauseTimeout !== null) {
                clearTimeout(onPauseTimeout);
                onPauseTimeout = null;
            }

            if (isPaused && onResumeTimeout === null) {
                onResumeTimeout = setTimeout(onResumeAfterTimeout, PAUSE_THRESHOLD);
            }
        } else {
            onResumeAfterTimeout();
        }
    };

    var onResumeAfterTimeout = function onResumeAfterTimeout() {
        console.log("onResumeAfterTimeout");
        onResumeTimeout = null;
        isPaused = false;
        CompChannel.emit(CCEvent.Resume);
    };

    var onActive = function onActive() {
        if (!verifyiOSAppStateChange(iOSAppState.ACTIVE)) {
            return;
        }

        console.log("onActive Event");
        CompChannel.emit(CCEvent.Active);
    };

    var onResign = function onResign() {
        if (!verifyiOSAppStateChange(iOSAppState.RESIGNED)) {
            return;
        }

        console.log("onResign Event");
        CompChannel.emit(CCEvent.Resign);
    };

    addEvent("pause", document, onPause);
    addEvent("resume", document, onResume);
    addEvent("resign", document, onResign);
    addEvent("active", document, onActive);

    var createNoAnimationStyleSheet = function () {
        var style = $('<style type="text/css">');
        style.html('*, ::after, ::before {' + '   -o-transition-property: none !important;' + '   -moz-transition-property: none !important;' + '   -ms-transition-property: none !important;' + '   -webkit-transition-property: none !important;' + '   transition-property: none !important;' + '   -o-transform: none !important;' + '   -moz-transform: none !important;' + '   -ms-transform: none !important;' + '   -webkit-transform: none !important;' + '   transform: none !important;' + '   -webkit-animation: none !important;' + '   -moz-animation: none !important;' + '   -o-animation: none !important;' + '   -ms-animation: none !important;' + '   animation: none !important;' + '}');
        return style;
    };

    var verifyAppStateChange = function verifyAppStateChange(newAppState) {
        var allowed = false;
        allowed |= currentAppState === AppState.UNDEFINED; // always allow

        allowed |= currentAppState === AppState.PAUSED && newAppState === AppState.RESUMED;
        allowed |= currentAppState === AppState.RESUMED && newAppState === AppState.PAUSED;

        if (allowed) {
            currentAppState = newAppState;
        } else {
            console.error("DeviceInfo", "verifyAppStateChange: PROHIBITED, from " + currentAppState + " to " + newAppState);
        }

        return allowed;
    };

    var verifyiOSAppStateChange = function verifyiOSAppStateChange(newiOSAppState) {
        var allowed = false;
        allowed |= currentiOSAppState === iOSAppState.UNDEFINED; // always allow

        allowed |= currentiOSAppState === iOSAppState.RESIGNED && newiOSAppState === iOSAppState.ACTIVE;
        allowed |= currentiOSAppState === iOSAppState.ACTIVE && newiOSAppState === iOSAppState.RESIGNED;

        if (allowed) {
            currentiOSAppState = newiOSAppState;
        } else {
            console.error("DeviceInfo", "verifyiOSAppStateChange: PROHIBITED, from " + currentiOSAppState + " to " + newiOSAppState);
        }

        return allowed;
    };

    return devInfoAdapter;
};

addEvent("initializeApp", document, function () {
    VendorHub.DeviceInfo = VendorHub.DeviceInfo();
});
