'use strict';

window.Components = function (Components) {
    // after 10 seconds of using the phone, the update checks are being started.
    var START_TIMEOUT = 1000 * 10,
        CHECK_INTERVAL = 1000 * 60 * 60 * 12; // every 12 hrs.

    function UpdateComp() {
        // prepare attributes
        this.extensions = {};
        this.name = "UpdateComp";
        this._updateCheckInterval = null; // initializes this component as extension-channel

        Observer.call(this); // Prepare the extensions

        this.extensions.miniserverUpdateExt = initExtension(Components.Update.extensions.MiniserverUpdateExt, this);
        this.extensions.appUpdateExt = initExtension(Components.Update.extensions.AppUpdateExt, this);
        this.extensions.updateFileExt = initExtension(Components.Update.extensions.UpdateFileExt, this);
        this.extensions.storageExt = initExtension(Components.Update.extensions.UpdateStorageExt, this); // Register for Component-Channel Events

        CompChannel.on(CCEvent.StructureReady, this._dispatchMSChanged.bind(this, true));
        CompChannel.on(CCEvent.StopMSSession, this._dispatchMSChanged.bind(this, false));
        CompChannel.on(CCEvent.Pause, this._dispatchSaveStorage.bind(this, false));
        CompChannel.on(CCEvent.Resign, this._dispatchSaveStorage.bind(this, false));
    }

    UpdateComp.prototype.ECEvent = {
        MiniserverChanged: "MiniserverChanged",
        StartUpdateCheck: "StartUpdateCheck",
        UpdateFileLoaded: "UpdateFileLoaded",
        SaveStorage: "SaveStorage",
        // called when the app will be sent to the bg or alike.
        MSUpdateLevelReceived: "MSUpdateLevelReceived" // called when the miniservers update level is known.

    };
    /**
     * Used from the outside to specify what platforms update is of interest.
     * @type {{CONFIG: string, APP: string, MUSIC_SERVER: string}}
     */

    UpdateComp.prototype.TargetType = {
        CONFIG: "Config",
        APP: "App",
        MUSICSERVER: "loxonemusicserver"
    };
    /**
     * Will return a promise that resolves if an update is available and it will reject if the update isn't available.
     * @param targetType  APP, CONFIG or MUSICSERVER
     * @param [identifier]
     * @param [overdueOnly]    only resolve if the update is available & overdue
     */

    UpdateComp.prototype.checkUpdateFor = function checkUpdateFor(targetType, identifier, overdueOnly) {
        var ext = null;

        switch (targetType) {
            case this.TargetType.APP:
                ext = this.extensions.appUpdateExt;
                break;

            case this.TargetType.CONFIG:
                ext = this.extensions.miniserverUpdateExt;
                break;

            case this.TargetType.MUSICSERVER:
                ext = this.musicExtensions[identifier];
                break;

            default:
                break;
        }

        if (overdueOnly) {
            return ext.checkForOverdueUpdate.call(ext);
        } else {
            return ext.checkForUpdate.call(ext);
        }
    };
    /**
     * Resolves if an update is available for the current miniserver
     * @param overdueOnly   if true, it will only resolve if the update available is also overdue.
     */


    UpdateComp.prototype.checkForMSUpdate = function checkForMSUpdate(overdueOnly) {
        // The miniserver will actively promote updates via the Systemstate from this Version onwards
        if (Feature.SYSTEM_STATE_MS_UPDATE) {
            // Reject the promise, to handle it like there is no update available
            return Q.reject();
        } else {
            return this.checkUpdateFor(this.TargetType.CONFIG, null, overdueOnly);
        }
    };

    UpdateComp.prototype.checkForAppUpdate = function checkForAppUpdate() {
        return this.checkUpdateFor(this.TargetType.APP);
    };

    UpdateComp.prototype.checkForMusicServerUpdate = function checkForMusicServerUpdate() {
        return this.checkUpdateFor(this.TargetType.MUSICSERVER);
    };
    /**
     * the updatelevel from the Miniserver
     * @type {{RELEASE: string, BETA: string, TEST: string}}
     */


    UpdateComp.prototype.UpdateLevel = {
        RELEASE: "RELEASE",
        BETA: "BETA",
        ALPHA: "INTERNALV2",
        // This is obsolete but is still in use!
        // We once released a release version with update level alpha.
        //• The previous version 11.2.3 (2020.12.22) bumped all alpha update levels to release
        //• In this version 11.2.3 (2020.12.23) we introduce a new updatelevel ("internal") with the Enum Key "ALPHA"
        //• So we fade out the app updatelevel "ALPHA" in our App in favour of "INTERNAL" to not publish alphas to our release customers every day
        //• We also won't supply the "ALPHA" (test) update level in our updateserver anymore, but we add the new "ALPHA" (internal) level
        ALPHA_LEGACY: "TEST",
        ALPHA_LEGACY_V2: "INTERNAL"
    };
    /**
     * Firmware version insensitive check for the release level.
     * @param lvl
     * @returns {boolean}
     */

    UpdateComp.prototype.isRelease = function isRelease(lvl) {
        var cmp = lvl.toUpperCase();
        return cmp === this.UpdateLevel.RELEASE || cmp === "DEFAULT";
    };
    /**
     * Firmware version insensitive check for the beta level.
     * @param lvl
     * @returns {boolean}
     */


    UpdateComp.prototype.isBeta = function isBeta(lvl) {
        var cmp = lvl.toUpperCase();
        return cmp === this.UpdateLevel.BETA;
    };
    /**
     * Firmware version insensitive check for the alpha level.
     * @param lvl
     * @returns {boolean}
     */


    UpdateComp.prototype.isAlpha = function isAlpha(lvl) {
        var cmp = lvl.toUpperCase();
        return cmp === this.UpdateLevel.ALPHA || cmp === this.UpdateLevel.ALPHA_LEGACY || cmp === this.UpdateLevel.ALPHA_LEGACY_V2;
    };

    UpdateComp.prototype.saveUpdateInfo = function saveUpdateInfo() {
        return this.extensions.storageExt.saveUpdateInfo.apply(this.extensions.storageExt, arguments);
    };

    UpdateComp.prototype.loadUpdateInfo = function loadUpdateInfo() {
        return this.extensions.storageExt.loadUpdateInfo.apply(this.extensions.storageExt, arguments);
    };

    UpdateComp.prototype._dispatchSaveStorage = function _dispatchSaveStorage() {
        this.emit(this.ECEvent.SaveStorage);
    };
    /**
     * Will dispatch a MiniserverChanged Event for the Extensions.
     * @param conn  is there a miniserver connected or not?
     * @private
     */


    UpdateComp.prototype._dispatchMSChanged = function _handleMSChanged(conn) {
        Debug.Update.Comp && console.log(this.name + ": _handleMSChanged: conn=" + conn);

        var localConnection = this._hasLocalConnection();

        if (conn && localConnection) {
            Debug.Update.Comp && console.log("   connection, start checks after timeout");

            this._prepareMusicServerExtensions();

            this._startDelayTimeout = setTimeout(this._startUpdateChecks.bind(this), START_TIMEOUT);
        } else {
            Debug.Update.Comp && console.log("   " + (conn ? "" : "connection closed ") + (localConnection ? "" : "remote conn ") + "update check not allowed. If not started already, don't start checks."); // eventually a start timeout is active, stop it!

            if (this._startDelayTimeout) {
                clearTimeout(this._startDelayTimeout);
                this._startDelayTimeout = null;
            } // also stop the update check interval


            if (this._updateCheckInterval) {
                clearInterval(this._updateCheckInterval);
                this._updateCheckInterval = null;
            }
        } // no update checks during external connections.


        if (!localConnection && conn) {
            Debug.Update.Comp && console.log("   Ignoring external connection, bailing out.");
            return;
        } // Emit the MS changed event to the extensions. They might prepare for or stop checking for updates.


        var arg = {
            connected: conn
        };
        this.emit(this.ECEvent.MiniserverChanged, arg);
    };
    /**
     * Will return true if an update check is to be processed. False if not.
     * return {boolean}
     */


    UpdateComp.prototype._hasLocalConnection = function _hasLocalConnection() {
        return CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL;
    };
    /**
     * Will start triggering update checks periodically.
     * @private
     */


    UpdateComp.prototype._startUpdateChecks = function _startUpdateChecks() {
        Debug.Update.Comp && console.log(this.name + ": _startUpdateChecks"); // informs all other extensions that now is the time to start checking for updates.

        this._triggerUpdateCheck(); // start an interval that will trigger re-updates.


        this._updateCheckInterval = setInterval(this._triggerUpdateCheck.bind(this, true), CHECK_INTERVAL);
    };
    /**
     * Disptaches the start update check event on for the extensions.
     * @param forced    if true, the update file must be redownloaded.
     * @private
     */


    UpdateComp.prototype._triggerUpdateCheck = function _triggerUpdateCheck(forced) {
        Debug.Update.Comp && console.log(this.name + ": _triggerUpdateCheck");
        this.emit(this.ECEvent.StartUpdateCheck, {
            forced: forced
        });
    };
    /**
     * Will check the structure for music servers & create a separate extension for each Music Server.
     * @private
     */


    UpdateComp.prototype._prepareMusicServerExtensions = function _prepareMusicServerExtensions() {
        Debug.Update.Comp && console.log(this.name + ": _prepareMusicServerExtensions");
        var servers = ActiveMSComponent.getStructureManager().getMediaServerSet(),
            ext; // there may still be old music server extensions around

        this._resetMusicServerExtensions();

        servers && Object.values(servers).forEach(function (svrObj) {
            if (svrObj.type === MediaServerType.LXMUSIC && svrObj.host && svrObj.host !== "") {
                ext = initExtension(Components.Update.extensions.MusicServerUpdateExt, this, svrObj);
                this.musicExtensions[svrObj.uuidAction] = ext;
            }
        }.bind(this));
    };
    /**
     * Will destroy any preexisting music server extensions and reset the musicExtensions attribute to an empty obj.
     * @privates
     */


    UpdateComp.prototype._resetMusicServerExtensions = function _resetMusicServerExtensions() {
        this.musicExtensions && Object.values(this.musicExtensions).forEach(function (ext) {
            ext.destroy();
        });
        this.musicExtensions = {};
    };

    Components.Update = {
        Init: UpdateComp,
        extensions: {}
    };
    return Components;
}(window.Components || {});
