'use strict';

VendorHub.service('BatteryStateAdapter', function () {
    const dbgName = "BatteryStateAdapter";
    console.log(dbgName, "+CTOR-FN");

    // private variables
    var batteryStateAdapter = {},
        batteryStateObj = {};

    batteryStateAdapter.getBatteryStateObj = function () {
        var platform = PlatformComponent.getPlatformInfoObj().platform; // macOS doesn't support the battery plugin

        if (platform === PlatformType.Webinterface ||
            platform === PlatformType.DeveloperInterface ||
            platform === PlatformType.Mac ||
            platform === PlatformType.Windows ||
            platform === PlatformType.Linux) {
            console.log(dbgName, "getBatteryStateObj --> unsupported PF = " + platform);
            batteryStateObj = {
                level: "100",
                isPlugged: true
            };
            return batteryStateObj;
        } else {
            console.log(dbgName, "getBatteryStateObj: " + JSON.stringify(batteryStateObj) + ", (PF=" + platform + ")");
        }

        return batteryStateObj;
    };

    var onBatteryStatus = function onBatteryStatus(info) {
        console.log(dbgName, "onBatteryStatus: " + JSON.stringify(info));
        batteryStateObj = {
            level: info.level,
            isPlugged: info.isPlugged
        }; // broadcast on component channel battery changed event

        CompChannel.emit(CCEvent.BatteryStateChanged, batteryStateObj);
    };

    window.addEventListener("batterystatus", onBatteryStatus, false);
    return batteryStateAdapter;
});
