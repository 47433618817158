'use strict'; //for debugging purposes. (e.g. Debug.simulateNetworkChange)

if (!Connection) {
    var Connection = {
        WIFI: "wifi",
        ETHERNET: "ethernet",
        CELL: "cellular",
        CELL_2G: "2g",
        CELL_3G: "3g",
        CELL_4G: "4g",
        CELL_5G: "5g",
        NONE: "none",
        UNKNOWN: "unknown"
    };
}

VendorHub.service('NetworkInfoAdapter', function () {
    var isNetworkPluginAvailable = window.cordova && typeof navigator.connection !== 'undefined'; // private variables

    var netInfoAdapter = {},
        networkStatus; // Simplify network status

    var checkNetState = function checkNetState(objectToCheck) {
        switch (objectToCheck) {
            case Connection.WIFI:
            case Connection.ETHERNET:
                return NetworkStatus.LAN;

            case Connection.CELL:
            case Connection.CELL_2G:
            case Connection.CELL_3G:
            case Connection.CELL_4G:
            case Connection.CELL_5G: // due to chines remote problems assume network state cell

            case Connection.UNKNOWN:
                return NetworkStatus.CELL;

            case Connection.NONE:
            default:
                console.log("NetworkInfoAdapter reports NOT_REACHABLE - based on the following info:");
                console.log(JSON.stringify(objectToCheck));
                return NetworkStatus.NOT_REACHABLE;
        }
    }; // Handle initial network type


    if (isNetworkPluginAvailable) {
        networkStatus = checkNetState(navigator.connection.type);
    } else {
        networkStatus = NetworkStatus.LAN;
    } // public methods


    netInfoAdapter.getNetworkStatus = function getNetworkStatus() {
        return {
            status: networkStatus
        };
    }; // private methods


    var onNetworkChanged = function onNetworkChanged() {
        console.log("onNetworkChanged Event: " + navigator.connection.type);
        var newNetState = checkNetState(navigator.connection.type);

        if (networkStatus !== newNetState) {
            networkStatus = newNetState;
            CompChannel.emit(CCEvent.NetworkChanged, networkStatus);
        }
    };

    document.addEventListener("online", onNetworkChanged, false);
    document.addEventListener("offline", onNetworkChanged, false);
    return netInfoAdapter;
});
