'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var ALPHA_CHANGELOG = "https://docs.google.com/a/loxone.com/document/d/1ekSVxa4x_0_eq9rttSw5ynU8o1R1thk8yIBnmkP1alI",
            BETA_CHANGELOG = "https://www.loxone.com/dede/infos/publicbeta/",
            NOT_NOW_INTERVAL = 365,
            // after hitting "Not Now" in an update popup
            UNREAD_POPUP_INTERVAL = 365; // after dismissing the notification.

        class MiniserverUpdateExt extends Components.Update.extensions.PlatformUpdateBaseExt {
            constructor(component, extensionChannel) {
                super(...arguments);
                this._miniserverUpdateLevel = null;
                this.registerExtensionEv(this.component.ECEvent.MiniserverChanged, this._handleMSChange.bind(this));
            }

            // ---------- METHODS TO OVERRIDE -----------------

            /**
             * Returns the update file ID of the current platform
             * @return {string}
             */
            getPlatformId() {
                return UpdateComp.TargetType.CONFIG;
            }

            /**
             * Days after which the notification will reappear if the user has clicked on the notification but hit not
             * now on the popup shown afterwards.
             * @return {number}
             */
            getNotNowInterval() {
                return NOT_NOW_INTERVAL;
            }

            /**
             * Days after which the notification will reappear if it has been simply dismissed without reading it.
             * @return {number}
             */
            getUnreadPopupInterval() {
                return UNREAD_POPUP_INTERVAL;
            }

            /**
             * As for miniservers there might already be a different update checking system (System State) in place
             * this method is overridden and verifying no app update info is shown for miniservers that have a version
             * that checks for its own updates via system state.
             * @returns {boolean}
             */
            isUpdateAvailable() {
                Debug.Update.MSUpdate && console.log(this.name, "isUpdateAvailable");
                var result = false;

                if (Feature.SYSTEM_STATE_MS_UPDATE) {
                    Debug.Update.MSUpdate && console.log("     --> System State Update notifications in charge, return false");
                } else {
                    result = super.isUpdateAvailable(...arguments);
                }

                Debug.Update.MSUpdate && console.log("     --> " + result);
                return result;
            }

            /**
             * Called when the update checks are initiated. The Update file download will also start at that time. E.g.
             * the update level can be loaded here, or the current version
             * @return {Promise} a promise that will resolve when the data acquired by this ext is ready for the update check.
             */
            prepareForUpdateCheck() {
                Debug.Update.UpdateBase && console.log(this.name + ": prepareForUpdateCheck");

                var basePromise = super.prepareForUpdateCheck(...arguments),
                    promise = this._loadUpdateLevel().then(function () {
                        // the miniservers update level is important for other extensions too (e.g. the Music Server)
                        this.channel.emit(UpdateComp.ECEvent.MSUpdateLevelReceived, this.getUpdateLevel());
                    }.bind(this));

                return Q.all([promise, basePromise]);
            }

            /**
             * Returns the update level of the current platform
             * @return {string}
             */
            getUpdateLevel() {
                // Adopt. Never show a beta or alpha update to an user who has no administrative rights!
                var isAdmin = SandboxComponent.hasAdminPermission(),
                    lvl = isAdmin ? this._miniserverUpdateLevel : UpdateComp.UpdateLevel.RELEASE;
                Debug.Update.UpdateBase && console.log(this.name + ": getUpdateLevel: " + lvl);
                return lvl;
            }

            /**
             * Returns the platforms current version
             * @return {string}
             */
            getCurrentVersion() {
                return ActiveMSComponent.getConfigVersion();
            }

            /**
             * Returns the message to show in the popup and in the update notification
             * @param newVersion    the version of the available update
             * @param currVersion
             * @param overdue       if true, the update is overdue already - should result in a firm message.
             * @return {string}
             */
            getMessage(newVersion, currVersion, overdue) {
                var message,
                    newVPretty = this._prettifyCfgVersion(newVersion);

                if (overdue) {
                    message = _("update.current-version", {
                        version: currVersion
                    }) + "<br />" + _("update.new-version", {
                        version: newVPretty
                    }) + "<br /><br />" + _("update.ms.overdue.message");
                } else {
                    message = _("update.available.ms.text", {
                        version: newVPretty
                    }) + "<br />" + _("update.current-version", {
                        version: currVersion
                    });
                }

                return message;
            }

            /**
             * Returns the title to show in the popup and in the update notification
             * @param overdue       if true, the update is overdue already - should result in a firm title
             * @return {string}
             */
            getTitle(overdue) {
                if (overdue) {
                    return _("update.ms.overdue.title");
                } else {
                    return _("update.available.title");
                }
            }

            /**
             * Specifies what icon to use when prompting for updates!
             * @return {string}
             */
            getUpdateIcon() {
                return Icon.Menu.MINISERVER;
            }

            // ---------- METHODS that may be overridden -----------------

            /**
             * Returns the update info part for this platform with the proper update level
             * @return {*}
             */
            getUpdateInfo() {
                var info = super.getUpdateInfo(...arguments);

                if (info && !info.changelog) {
                    if (UpdateComp.isAlpha(this.getUpdateLevel())) {
                        info.changelog = ALPHA_CHANGELOG;
                    } else if (UpdateComp.isBeta(this.getUpdateLevel())) {
                        info.changelog = BETA_CHANGELOG;
                    }
                }

                return info;
            }

            /**
             * Returns the function to call when an update is to be started.
             * @param link the link to the file or to the place where the update is available (app/play store)
             */
            getStartUpdateFunction(link) {
                var fn = null,
                    gatewayMs = ActiveMSComponent.getGatewayType() === GatewayType.GATEWAY; // only present the update option for alpha versions. Never for release or beta.

                if (UpdateComp.isAlpha(this.getUpdateLevel()) && !gatewayMs) {
                    fn = this._triggerAutoUpdate.bind(this, link);
                }

                return fn;
            }

            /**
             * Returns the string that is used to store the data of this platforms update handling
             * @return {string}
             */
            getStorageName() {
                return this.name + ActiveMSComponent.getMiniserverSerialNo();
            }

            // --------------------- Private Methods ----------------------------------

            /**
             * Called whenever a miniserver connects or is disconnected (session stop, not connection loss). As soon
             * as a connection is here, the firmware will be acquired & an update check is started.
             * @param ev
             * @param arg
             * @private
             */
            _handleMSChange(ev, arg) {
                var connected = arg.connected;
                Debug.Update.MSUpdate && console.log(this.name + ": _handleMSChange: connected? " + connected);

                if (connected) {
                    Debug.Update.MSUpdate && console.log("   connected. BaseClass "); // loading the data is handled by the baseclass.
                } else {
                    Debug.Update.MSUpdate && console.log("   connection closed, reset extension");
                    this.resetExtension(true);
                }
            }

            /**
             * Will ask the Miniserver for it's update level flag.
             * @private
             */
            _loadUpdateLevel() {
                Debug.Update.MSUpdate && console.log(this.name + ": _loadUpdateLevel");
                var prms; // only users with administrative rights can read the update level.

                if (SandboxComponent.hasAdminPermission()) {
                    prms = CommunicationComponent.send(Commands.UPDATE_LEVEL).then(function (result) {
                        this._miniserverUpdateLevel = this._checkUpdateLevel(result.LL.value);
                        Debug.Update.MSUpdate && console.log("        UpdateLevel: " + this._miniserverUpdateLevel);
                    }.bind(this), function (err) {
                        console.error("Could not determine update level, assuming release!" + JSON.stringify(err));
                        this._miniserverUpdateLevel = UpdateComp.UpdateLevel.RELEASE;
                    }.bind(this));
                } else {
                    this._miniserverUpdateLevel = UpdateComp.UpdateLevel.RELEASE;
                    prms = Q.when(UpdateComp.UpdateLevel.RELEASE);
                }

                return prms;
            }

            /**
             * Ensures the update level received is compatible with the one we're using inside the app
             * @param rawLvl
             * @private
             */
            _checkUpdateLevel(rawLvl) {
                var resLvl = rawLvl.toUpperCase();
                resLvl = resLvl.replace("TEST", UpdateComp.UpdateLevel.ALPHA_LEGACY);
                resLvl = resLvl.replace("DEFAULT", UpdateComp.UpdateLevel.RELEASE); // new miniservers will return "Alpha" as update level, internally we work with "TEST", just like
                // on the update server, where the file will too contain "TEST"

                resLvl = resLvl.replace("ALPHA", UpdateComp.UpdateLevel.ALPHA_LEGACY);
                return resLvl;
            }

            /**
             * Will send the autoupdate command to the Miniserver.
             * @param link
             * @private
             */
            _triggerAutoUpdate(link) {
                var cmdPromise,
                    waitTitle = _("update.ms.auto-update.sending"),
                    waitMessage = _("please-wait"),
                    hide = _("hide-window"),
                    succ = {
                        title: _("update.ms.auto-update.sent"),
                        message: _("update.ms.auto-update.message"),
                        buttonOk: true
                    },
                    err = {
                        title: _("error"),
                        message: _("update.ms.auto-update.error"),
                        buttonOk: true
                    };

                SandboxComponent.getPermission(MsPermission.SYS_WS).then(function () {
                    cmdPromise = CommunicationComponent.send(Commands.AUTO_UPDATE);
                    NavigationComp.showWaitingFor(cmdPromise, waitTitle, waitMessage, hide);
                    cmdPromise.then(function () {
                        NavigationComp.showPopup(succ);
                    }, function () {
                        NavigationComp.showPopup(err);
                    });
                }.bind(this));
            }

            /**
             * The config version from the update server does not really fit the one we get from the Miniserver.
             * @param input     "09.00.08.23"
             * @return {string} "9.0.8.23"
             * @private
             */
            _prettifyCfgVersion(input) {
                var res = "",
                    prts = [];

                try {
                    input.split(".").forEach(function (prt) {
                        prts.push(parseInt(prt));
                    });
                    res = prts.join(".");
                } catch (ex) {
                    res = input;
                }

                return res;
            }

        }

        Components.Update.extensions.MiniserverUpdateExt = MiniserverUpdateExt;
    }
    return Components;
}(window.Components || {});
