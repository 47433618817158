'use strict';

var lxFormat = function () {
    // TODO localize comma <-> point

    /**
     * @param text
     * @param value one value or array of values
     * @param autoDropDecimals
     * @returns {string}
     * @constructor
     */
    function LxFormat(text, value, autoDropDecimals) {
        if (!text) {
            return "";
        }

        if (text.indexOf('<v.pdu>') !== -1) {
            // precise duration
            text = formatLxDuration(text, value, true);
        } else if (text.indexOf('<v.dur>') !== -1) {
            // short duration (topmost 2 positions)
            text = formatLxDuration(text, value);
        } else if (text.indexOf('<v.durs>') !== -1) {
            // very short duration
            text = formatLxDurationNoZero(text, value);
        } else if (text.indexOf('<v.m>') !== -1) {
            text = formatEIBTime(text, value);
        } else if (text.indexOf('<v.d>') !== -1) {
            text = formatEIBDate(text, value);
        } else if (text.indexOf('<v.t>') !== -1) {
            text = formatTime(text, value);
        } else if (text.indexOf('%u') !== -1 || text.indexOf('<v.u>') !== -1) {
            text = formatLxDateTime(text, value);
        } else if (text.indexOf('%x') !== -1 || text.indexOf('<v.x>') !== -1) {
            text = formatDigital(text, value);
        } else if (text.indexOf('%c') !== -1 || text.indexOf('<v.c>') !== -1) {
            text = formatColor(text, value);
        } else if (text.indexOf('%') !== -1) {
            text = handleCStyleFormat.apply(this, arguments);
        }

        return text;
    }

    /**
     * <v.t>    this is the format for a TIME, it is not suited for a duration, even tough it was used for something like
     * this in the past. <v.dur> or <v.pdu> where introduced for durations instead.
     * @param text
     * @param seconds
     * @returns {string}
     */


    var formatTime = function formatTime(text, seconds) {
        var time = new LxDate().startOf('day').seconds(seconds);
        var result = time.format(LxDate.getTimeFormat());
        var days = Math.floor(seconds / 86400);
        result = days + _("dateTime.day.oneLetter") + " " + result;
        return text.replace('<v.t>', result);
    };
    /**
     * <v.dur> or <v.pdu>  Converts an input value in seconds to a duration, but only showing its two largest parts.
     * E.g. 60s = 1m, 138s = 2m 8s, 3668s = 1h 1m
     * @param text
     * @param seconds
     * @param {boolean} precise optional argument, if set it'll show the duration precise instead of short
     * @returns {string}
     */


    var formatLxDuration = function formatLxDuration(text, seconds, precise) {
        if (precise) {
            return text.replace('<v.pdu>', LxDate.formatSeconds(seconds));
        } else {
            return text.replace('<v.dur>', LxDate.formatSecondsShort(seconds));
        }
    };
    /**
     * <v.durs> Converts an input value in seconds to a duration, but only showing its largest parts. No Zeros attached.
     * E.g. 60s = 1m, 138s = 2m 8s, 3668s = 1h 1m, 3600 = 1h
     * @param text
     * @param seconds
     * @returns {string}
     */


    var formatLxDurationNoZero = function formatLxDurationNoZero(text, seconds) {
        return text.replace('<v.durs>', LxDate.formatSecondsShort(seconds, true));
    };
    /**
     * <v.u> %u
     * @param text      the text that is to be formated
     * @param seconds   seconds since 1.1.2009
     * @param [dateType] {DateType} short, regular or text. Per Default it's regular "YYYY-MM-DD".
     * @returns {string}
     */


    var formatLxDateTime = function formatLxDateTime(text, seconds, dateType) {
        var time = new LxDate([2009, 0, 1]).seconds(seconds);
        var dateFormat = LxDate.getDateFormat(dateType);
        var result = time.format(dateFormat + " " + LxDate.getTimeFormat());
        result = text.replace('<v.u>', result);
        return result.replace('%u', result);
    };
    /**
     * <v.d> EIS 4
     * @param text
     * @param val
     * @param [dateType] {DateType} short, regular or text. Per Default it's regular "YYYY-MM-DD".
     * @returns {string}
     */


    var formatEIBDate = function formatEIBDate(text, val, dateType) {
        val = parseInt(val);
        var day = val >> 16 & 0x1f,
            month = val >> 8 & 0x1f,
            year = val & 0x7f;

        if (year < 90) {
            year += 2000;
        } else {
            year = 1990 + year % 10;
        }

        var result = new LxDate([year, month - 1, day]).format(LxDate.getDateFormat(dateType));
        return text.replace('<v.d>', result);
    };
    /**
     * <v.m> EIS 3
     * @param text
     * @param val
     * @returns {string}
     */


    var formatEIBTime = function formatEIBTime(text, val) {
        val = parseInt(val); // var day = ((val >> 21) & 0x07), // w = 1 = monday, 7 = sunday, 0 = no day ignore the day, it's not used

        var hour = val >> 16 & 0x1f,
            min = val >> 8 & 0x3f,
            sec = val & 0x3f;
        var secondsOfDay = (hour * 60 + min) * 60 + sec;
        var time = new LxDate([2009, 0, 1]).seconds(secondsOfDay);
        var result = time.format(LxDate.getTimeFormat()); //var result = sprintf("%.2d", hour) + ":" + sprintf("%.2d", min) + ":" + sprintf("%.2d", sec);

        return text.replace('<v.m>', result);
        /*
        int nVal = (int)val;
        int nHour = (nVal >> 16) & 0x1f;
        int nMinute = (nVal >> 8) & 0x3f;
        int nSecond = nVal & 0x3f;
        //int nDay = (nVal >> 21) & 0x7;  // w = 1 = monday, 7 = sunday, 0 = no day
        result = [NSString stringWithFormat:@"%02d:%02d:%02d",nHour,nMinute,nSecond];
        return result;
        */
    };
    /**
     * <v.c> %c
     * @param text
     * @param val
     * @returns {string}
     */


    var formatColor = function formatColor(text, val) {
        val = parseInt(val);

        if (val > 200000000) {
            // oh, it's a lumitech value
            var brightness = parseInt(val / 10000 % 1000),
                kelvin = parseInt(val % 10000);
            var lumiRes = sprintf("%i%%", brightness) + ", " + sprintf("%iK", kelvin);
            lumiRes = text.replace('<v.c>', lumiRes);
            return lumiRes;
        } else {
            // normal RGB value
            var redValue = parseInt(val % 1000),
                greenValue = parseInt(val % 1000000 / 1000),
                blueValue = parseInt(val / 1000000);
            var result = sprintf("R=%i%%", redValue) + ", " + sprintf("G=%i%%", greenValue) + ", " + sprintf("B=%i%%", blueValue);
            result = text.replace('<v.c>', result);
            return result;
        }
        /*
         if (val > 200000000) {
         // oh, it's a lumitech value
         int brightness = ((int)val / 10000) % 1000;
         int kelvin = (int)val % 10000;
         return [NSString stringWithFormat:@"%i%%, %iK", brightness, kelvin];
         } else {
         int redValue = (int)val % 1000;
         int greenValue = ((int)val % 1000000) / 1000;
         int blueValue = (int)val / 1000000;
         return [NSString stringWithFormat:@"R=%i%%, G=%i%%, B=%i%%", redValue, greenValue, blueValue];
         }
         */

    };
    /**
     * <v.x> %.x
     * @param text
     * @param val
     * @returns {string}
     */


    var formatDigital = function formatDigital(text, val) {
        var valueText = parseInt(val) === 0 ? _("off") : _("on");
        var result = text.replace('<v.x>', valueText);
        return result.replace('%x', valueText);
    };

    var localizedFormat = function localizedFormat(text, value, fewestPossibleFractions) {
        let fractionSeparator = numbro.languageData().delimiters.decimal,
            fractionDigits = 0,
            numbrobj = new numbro(value),
            res;

        if (!fewestPossibleFractions) {
            fewestPossibleFractions = false;
        }

        if (text.indexOf("%0") > -1 && text.indexOf("d") > -1) {
            // decimals
        } else if (text.indexOf("%.", "") > -1 && text.indexOf("f", "") > -1) {
            // fractions
            var fracTxt = text.replace("%.", "").replace("f", "");
            fractionDigits = fracTxt === "" ? 0 : parseInt(fracTxt);
        } else if (text.indexOf("%f") > -1) {
            // "unlimited fractions"
            fractionDigits = 5;
            fewestPossibleFractions = true;
        }

        res = numbrobj.format({
            thousandSeparated: true,
            mantissa: fractionDigits
        });

        if (fewestPossibleFractions && fractionDigits > 0) {
            while ([ "0", fractionSeparator ].includes(res[res.length -1])) {
                let removedChar = res[res.length-1];
                res = res.slice(0, -1);
                if (![ "0", fractionSeparator ].includes(res[res.length -1]) || removedChar === fractionSeparator) {
                    break;
                }
            }
        }
        return res;
    };
    /**
     * handles a text with C-style placeholder(s)
     * @param text
     * @param {number|[]} value one or array of values
     * @param autoDropDecimals if this is set, the format will be changed to the minimum nr of decimals, eg. value 10.2 and format %.2f will be changed to %.1f!
     * WARNING: this will always change the format to %.xf!
     * @returns {string}
     */


    var handleCStyleFormat = function handleCStyleFormat(text, value, autoDropDecimals) {
        try {
            var result,
                args = [text],
                sf = lxSplitFormat(text),
                localized = Regex.LOCALIZE_NUMERAL.test(sf.valFormat); // if localization of a number is to be used or not

            if (value === undefined) {
                value = 0;
            }

            if (localized) {
                result = sf.predTxt;
                result = result + localizedFormat(sf.valFormat, value, autoDropDecimals);
                result = result + sf.succTxt;
            } else {
                args = args.concat(value);
                result = sprintf.apply(null, args);
            }

            return result;
        } catch (e) {
            console.error(e);
            return text;
        }
    };

    return LxFormat;
}();

var lxSplitFormat = function () {
    /**
     * @return {{}}
     */
    function SplitFormat(inputFormat) {
        var formatObject = {};
        formatObject.predTxt = "";
        formatObject.valFormat = inputFormat;
        formatObject.succTxt = ""; // TODO: woessto - remove this when the format is corrected in the next version

        inputFormat = inputFormat.replace("<v>", "%.0f");
        inputFormat = inputFormat.replace("<v.0>", "%.0f");
        inputFormat = inputFormat.replace("<v.1>", "%.1f");
        inputFormat = inputFormat.replace("<v.2>", "%.2f");
        inputFormat = inputFormat.replace("<v.3>", "%.3f");
        inputFormat = inputFormat.replace("<v.4>", "%.4f");

        if (inputFormat) {
            var currPos = inputFormat.indexOf("%f");

            if (currPos <= -1) {
                currPos = inputFormat.indexOf('%.');

                if (currPos <= -1) {
                    currPos = inputFormat.indexOf('%d');

                    if (currPos <= -1) {
                        currPos = inputFormat.indexOf('%0');

                        if (inputFormat.charAt(currPos + 3) !== 'd') {
                            currPos = -1;
                        }
                    }
                }
            }

            var formatStart = currPos;

            if (currPos > -1) {
                // it has a c-style format!
                formatObject.predTxt = inputFormat.substring(0, currPos); // find c/x/u/d

                var char = '';
                var inputLen = inputFormat.length;

                do {
                    char = inputFormat.charAt(++currPos);
                } while (char != "c" && char != "u" && char != "x" && char != "f" && char != "d" && currPos + 1 < inputLen);

                if (currPos < inputLen) {
                    formatObject.valFormat = inputFormat.substring(formatStart, currPos + 1);
                    formatObject.succTxt = inputFormat.substring(currPos + 1, inputFormat.length);
                } else {
                    formatObject.predTxt = "";
                    formatObject.valFormat = inputFormat;
                    formatObject.succTxt = "";
                }
            }
        }

        formatObject.predTxt = sprintf(formatObject.predTxt);
        formatObject.succTxt = sprintf(formatObject.succTxt);
        return formatObject;
    }

    return SplitFormat;
}();
