'use strict';

var ConnectivityTools = function (ConnectivityTools) {
    class SafetyProbe extends ConnectivityTools.ReachabilityProbe {
        constructor(target, baseTimeout, maxTimeout, reachMode, useHttps, serialNoToIgnore) {
            super(...arguments);
            this.name = "ReachabilityProbeSafety " + translateReachMode(reachMode) + "/" + (useHttps ? "HTTPS" : "REGULAR");
        }

        shouldRetryOnError() {
            return false;
        }

    }

    ConnectivityTools.SafetyProbe = SafetyProbe;
    return ConnectivityTools;
}(ConnectivityTools || {});
