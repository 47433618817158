"use strict";

var PNS_COMMANDS = {
    WS_APIV1_UPDATE: "https://push.loxonecloud.com/v1/update",
    WS_APIV1_UNREGISTER: "https://push.loxonecloud.com/v1/unregister",
    WS_APIV1_UNREGISTER_UNKNOWN: "https://push.loxonecloud.com/v1/unregisterUnknown"
};

function PushNotificationService() {
    this._serviceMessages = {
        "-1": []
    }; // messages are stored with the "serviceType" key

    this.deviceSupportsPushNotifications = false; // eg. FireTab doesn't! false is default (WI)
    this.permissionsGranted = false;
}

/**
 * checks if the DevicePushToken has changed, updates if necessary, and listens for PushNotifications
 * @returns {Promise}
 */


PushNotificationService.prototype.initPushNotifications = function initPushNotifications() {
    Debug.PushNotification && console.log("PNS initPushNotifications");

    this._checkPendingUnregistrations();

    return this.isPushEnabledForApp().then(function () {
        this.listenForPushNotifications();
        return this.checkDevicePushTokens();
    }.bind(this));
};
/**
 * checks if PushNotifications are enabled for the Device
 * @param [askUserForActivation=false] if the user should be asked (by the OS) to enable the notifications for the app (only ask this after user wants to enable push for a Miniserver)
 * @returns {Promise}
 */


PushNotificationService.prototype.isPushEnabledForApp = function isPushEnabledForApp(askUserForActivation) {
    var def = Q.defer();
    pushNotification.isPushAvailable(function (enabled) {
        Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", clarify permissions..");
        this.deviceSupportsPushNotifications = true;
        this.checkPushPermissions().then(res => {
            this.permissionsGranted = true;
        }, (err) => {
            this.permissionsGranted = false;

        }).then((res) => {
            Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", permissions: " + this.permissionsGranted);
            if (enabled && this.permissionsGranted) {
                def.resolve();
            } else {
                if (askUserForActivation) {
                    Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", permissions: " + this.permissionsGranted + " --> ask!");
                    this.requestPushPermissions().then(res => {
                        Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", permissions: " + this.permissionsGranted + " --> ask --> confirmed!");
                        // request the token to be asked from the OS!
                        var askTimeout = setTimeout(def.reject, 10 * 1000);
                        this.getCurrentPushToken().done(function () {
                            clearTimeout(askTimeout);
                            def.resolve();
                        }, function () {
                            clearTimeout(askTimeout);
                            def.reject();
                        });
                    }, (err) => {
                        Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", permissions: " + this.permissionsGranted + " --> ask --> declined!");
                        def.reject();
                    });
                } else {
                    Debug.PushNotification && console.log("PNS isPushEnabledForApp:" + enabled + ", permissions: " + this.permissionsGranted + " --> no, don't ask!");
                    def.reject();
                }
            }
        })

    }.bind(this), function (e) {
        Debug.PushNotification && console.log("PNS isPushEnabledForApp: not available!");
        def.reject(new Error(e));
        this.permissionsGranted = false;
        this.deviceSupportsPushNotifications = false;
    }.bind(this));
    return def.promise;
};
/**
 * checks if PushNotifications is configured (enabled, disabled, settings) for the Miniserver of the given mac
 * @param mac
 * @returns {Promise}
 */


PushNotificationService.prototype.isPushConfiguredForMiniserver = function isPushConfiguredForMiniserver(mac) {
    Debug.PushNotification && console.log("PNS isPushConfiguredForMiniserver:" + mac);
    return this._getMiniserverForMac(mac);
};
/**
 * checks if PushNotifications are enabled for the Miniserver of the given mac
 * @param mac
 * @returns {Promise}
 */


PushNotificationService.prototype.isPushEnabledForMiniserver = function isPushEnabledForMiniserver(mac) {
    Debug.PushNotification && console.log("PNS isPushEnabledForMiniserver:" + mac);
    return this.isPushConfiguredForMiniserver(mac).then(function (miniserver) {
        var enabled = miniserver.hasOwnProperty("token");
        Debug.PushNotification && console.log(" - enabled:" + enabled);
        if (enabled) return true; else throw new Error("push not enabled for this Miniserver");
    });
};
/**
 * registers the Miniserver for PushNotifications
 * @param mac
 * @returns {Promise}
 */


PushNotificationService.prototype.registerMiniserver4Push = function registerMiniserver4Push(mac) {
    Debug.PushNotification && console.log("PNS registerMiniserver4Push:" + mac);
    var def = Q.defer();
    def.resolve(this._getStoredMiniserverPushToken(mac).then(function () {
        return "already registered";
    }, function () {
        // Miniserver not registered, go on!
        return this._sendRegistrationToMiniserver(mac).then(function (token) {
            // registration was successful, now store the token
            return this._setMiniserverPushToken(mac, token);
        }.bind(this));
    }.bind(this)));
    return def.promise;
};
/**
 * unregisters the Miniserver for PushNotifications
 * @param mac
 * @param disablePushForMiniserver if the push should be disabled explicitly (on first connection..)
 * @returns {Promise}
 */


PushNotificationService.prototype.unregisterMiniserver4Push = function unregisterMiniserver4Push(mac, disablePushForMiniserver) {
    Debug.PushNotification && console.log("unregisterMiniserver4Push:" + mac);
    return this._getMiniserverForMac(mac).then(function (ms) {
        if (ms.token) {
            Debug.PushNotification && console.log(" -> add the current token to the delete array");

            if (!ms.delete) {
                Debug.PushNotification && console.log(" -> add delete array for " + ms.mac);
                ms.delete = [ms.token];
            } else {
                Debug.PushNotification && console.log(" -> add token " + ms.token + " to delete array for " + ms.mac);
                ms.delete.push(ms.token);
            }

            delete ms.token; // save first

            return this._savePNSFile().then(this._unregisterMiniserver4Push(mac));
        } else {
            return this._unregisterMiniserver4Push(mac);
        }
    }.bind(this), function () {
        if (disablePushForMiniserver) {
            Debug.PushNotification && console.log(" -> add Miniserver to save disabled state..");
            return this._getMiniserverForMac(mac, true); // add new Miniserver to json
        } else {
            Debug.PushNotification && console.log(" -> unregister unknown Miniserver..");
            return this._unregisterMiniserver4Push(mac);
        }
    }.bind(this));
};

PushNotificationService.prototype.unregisterUnusedMiniserverFromPush = function unregisterUnusedMiniserverFromPush(msName, mac, msg) {
    Debug.PushNotification && console.log("PNS unregisterUnusedMiniserverFromPush:" + msName + " mac:" + mac + " msg:" + msg);
    var content = {
        title: _('notifications.unknown', {
            msName: msName,
            mac: mac
        }),
        message: msg,
        icon: Icon.INFO,
        color: window.Styles.colors.green,
        buttonOk: _('unsubscribe')
    };
    NavigationComp.showPopup(content).done(function () {
        this.unregisterMiniserver4Push(mac).done(function (res) {
            if (typeof res === "string") {
                GUI.Notification.createGeneralNotification({
                    title: res,
                    removeAfter: 3
                });
            }
        });
    }.bind(this));
};
/**
 * unregisters the Miniserver for PushNotifications
 * @param mac
 * @returns {Promise}
 */


PushNotificationService.prototype._unregisterMiniserver4Push = function _unregisterMiniserver4Push(mac) {
    Debug.PushNotification && console.log("PNS _unregisterMiniserver4Push:" + mac);
    return this._getDevicePushToken().then(function (devicePushToken) {
        return this._getMiniserverPushTokensToDelete(mac).then(function success(tokens) {
            // we have a token stored, go on normally
            if (tokens.length === 1) {
                Debug.PushNotification && console.log(" - only 1 token, look if we are connected to this Miniserver"); // we only have 1 token to be deleted, normal case, send also to Miniserver

                if (ActiveMSComponent.getActiveMiniserver() != null && mac === ActiveMSComponent.getActiveMiniserver().serialNo) {
                    Debug.PushNotification && console.log(" - connected, unregister at Miniserver.."); // unregistration Miniserver (Miniserver only deletes token, not forwards anything to Loxone Server)
                    // send the unregister command to Miniserver - it is not mandatory that this is successful - it will be also handled via the blacklist!

                    CommunicationComponent.sendViaHTTP(Commands.format(Commands.PNS.UNREGISTER, tokens[0])).then(function () {
                        Debug.PushNotification && console.log(" - Miniserver unregistration success");
                    }, function (res) {
                        if (getLxResponseCode(res) === ResponseCode.NOT_FOUND) {
                            Debug.PushNotification && console.info(" - Miniserver has no registration..");
                        } else {
                            console.error(" - Miniserver unregistration error");
                        }
                    });
                } else {
                    Debug.PushNotification && console.info(" - not connected to this miniserver");
                }

                return this._sendUnregisterToServer(devicePushToken, mac, tokens[0]);
            } else if (tokens.length > 1) {
                Debug.PushNotification && console.log(" - multiple tokens, loop through..");
                var promises = [],
                    token;

                for (var i = 0; i < tokens.length; i++) {
                    token = tokens[i];
                    Debug.PushNotification && console.log(" - unregister token:" + token);
                    promises.push(this._sendUnregisterToServer(devicePushToken, mac, token));
                }

                return Q.all(promises);
            } else {
                this._removeMiniserverPushTokensFromDeleteArray(mac);

                return "nothing found to delete!";
            }
        }.bind(this), function error() {
            // we have no token stored, try method without token
            Debug.PushNotification && console.log(" - no token stored, try without token");
            return this._sendUnregisterUnknownToServer(devicePushToken, mac);
        }.bind(this));
    }.bind(this));
};
/**
 * deletes the Miniserver from the Push.json file
 * unregisters everything if necessary!
 * @param mac
 * @private
 */


PushNotificationService.prototype.deleteMiniserverForMac = function deleteMiniserverForMac(mac) {
    Debug.PushNotification && console.log("PNS deleteMiniserverForMac:" + mac); // check if platform is supported
    // keep this check for WI and other platforms!

    if (this._getPlatform()) {
        return this._loadPNSFile().then(function (pnsFile) {
            var ms;

            for (var i = 0; i < pnsFile.miniservers.length; i++) {
                ms = pnsFile.miniservers[i];

                if (ms.mac === mac) {
                    if (typeof ms.token === "string" && ms.token.length > 0) {
                        if (!ms.delete) {
                            Debug.PushNotification && console.log(" -> add delete array for " + ms.mac);
                            ms.delete = [ms.token];
                        } else {
                            Debug.PushNotification && console.log(" -> add token " + ms.token + " to delete array for " + ms.mac);
                            ms.delete.push(ms.token);
                        }

                        delete ms.token;
                    } else {
                        Debug.PushNotification && console.log(" -> no token, nothing to delete!"); // we don't have a token, we have nothing to delete!
                    }

                    break;
                }
            } // save first!


            return this._savePNSFile().then(function () {
                if (ms && ms.delete) {
                    Debug.PushNotification && console.log(" - Miniserver may be still registered, unregister him first..");
                    return this._unregisterMiniserver4Push(mac).then(function () {
                        Debug.PushNotification && console.log(" - delete Miniserver");
                        pnsFile.miniservers.splice(pnsFile.miniservers.indexOf(ms), 1);
                        return this._savePNSFile();
                    }.bind(this));
                } else if (ms && !ms.token) {
                    Debug.PushNotification && console.log(" - delete Miniserver");
                    pnsFile.miniservers.splice(pnsFile.miniservers.indexOf(ms), 1);
                    return this._savePNSFile();
                } else {
                    return "No Miniserver found in Push.json";
                }
            }.bind(this));
        }.bind(this));
    }
};
/**
 * checks if the DevicePushToken has changed, and updates it (on Loxone Server) if necessary
 * @returns {Promise}
 */


PushNotificationService.prototype.checkDevicePushTokens = function checkDevicePushTokens() {
    Debug.PushNotification && console.log("PNS checkDevicePushTokens");
    var def = Q.defer();

    var platformType = this._getPlatform(); // check if platform is supported


    if (platformType) {
        // we are on a supported platform
        // load stored PushToken
        this._getStoredDevicePushToken().done(function (storedDevicePushToken) {
            Debug.PushNotification && console.log(" - storedDevicePushToken=" + storedDevicePushToken); // get devices PushToken

            this._getDevicePushToken().done(function (devicePushToken) {
                Debug.PushNotification && console.log(" - devicePushToken=" + devicePushToken);

                if (storedDevicePushToken !== devicePushToken) {
                    // the PushDeviceToken has changed!
                    Debug.PushNotification && console.log(" - DevicePushTokens has changed - start updating!");
                    /*GUI.Notification.createGeneralNotification({
                        title: "DevicePushToken has changed, sending update to Server..",
                        removeAfter: 3
                    }, NotificationType.INFO);*/
                    // update on server!

                    var updatePromise = this._updateDevicePushTokens(storedDevicePushToken, devicePushToken).then(function success() {
                        /*GUI.Notification.createGeneralNotification({
                            title: "DevicePushToken successfully updated on Server",
                            removeAfter: 3
                        }, NotificationType.SUCCESS);*/
                    }, function error(e) {
                        console.error(e);
                        GUI.Notification.createGeneralNotification({
                            title: "DevicePushToken update on Server failed",
                            subtitle: e,
                            removeAfter: 3
                        }, NotificationType.ERROR);
                    }, function notify(msg) {
                        GUI.Notification.createGeneralNotification({
                            title: "DevicePushToken update on Server failed",
                            subtitle: msg,
                            removeAfter: 3
                        }, NotificationType.WARNING);
                    });

                    def.resolve(updatePromise);
                } else {
                    Debug.PushNotification && console.log(" - DevicePushTokens are equal!");
                    def.resolve("DevicePushToken are equal!");
                }
            }.bind(this));
        }.bind(this), function () {
            Debug.PushNotification && console.log(" - no storedDevicePushToken!");
            def.reject(new Error("PushNotifications not used atm!")); // we haven't registered for PushNotifications

            /*GUI.Notification.createGeneralNotification({
                title: "PushNotifications not used atm!",
                removeAfter: 3
            }, NotificationType.INFO);*/
        });
    } else {
        def.reject(new Error("PushNotifications not available"));
        /*GUI.Notification.createGeneralNotification({
            title: "PushNotifications not available for this platform (" + platformType + ")!",
            removeAfter: 3
        }, NotificationType.WARNING);*/
    }

    return def.promise;
};
/**
 * checks if the Miniserver knows from the registration of this App
 * @param mac
 */


PushNotificationService.prototype.checkRegistrationOnMiniserver = function checkRegistrationOnMiniserver(mac) {
    Debug.PushNotification && console.log("PNS checkRegistrationOnMiniserver");
    return this._getStoredMiniserverPushToken(mac).then(function (token) {
        Debug.PushNotification && console.log(" - App should be registered, check!");
        return SandboxComponent.send(this._getCheckRegistrationCommand(token), EncryptionType.REQUEST_RESPONSE_VAL).then(function () {
            Debug.PushNotification && console.log(" - registered!");
        }, function () {
            Debug.PushNotification && console.log(" - not registered, start re-registration");
            /*var retryNotification = GUI.Notification.createGeneralNotification({
                title: "You are not registered for PN on this Miniserver",
                subtitle: "trying to register again..",
                removeAfter: 3
            }, NotificationType.WARNING);*/

            return this._sendRegistrationToMiniserver(mac).then(function (newToken) {
                Debug.PushNotification && console.log(" - re-registration was successful!"); //retryNotification.remove();
                // compare tokens

                if (token === newToken) {
                    /*GUI.Notification.createGeneralNotification({
                        title: "You are registered again for PN on this Miniserver",
                        removeAfter: 3
                    }, NotificationType.SUCCESS);*/
                } else {
                    Debug.PushNotification && console.error(" - token is different, store new token");

                    this._setMiniserverPushToken(mac, newToken);
                    /*GUI.Notification.createGeneralNotification({
                        title: "Re-registration failed",
                        subtitle: ("got a different token, old:" + token + " new:" + newToken),
                        removeAfter: 3
                    }, NotificationType.ERROR);*/

                }
            }.bind(this));
        }.bind(this));
    }.bind(this), function () {
        Debug.PushNotification && console.log(" - app shouldn't be registered");
    });
};
/**
 * listens to PushNotifications, handles further processing when receiving a notification
 */


PushNotificationService.prototype.listenForPushNotifications = function listenForPushNotifications() {
    Debug.PushNotification && console.log("PNS listenForPushNotifications");
    pushNotification.listen(function success(pn) {
        Debug.PushNotification && console.log("PNS new notification:" + JSON.stringify(pn));

        if (typeof pn.data === "string") {
            pn.data = JSON.parse(pn.data);
        } // handle different types


        switch (pn.type) {
            case PushNotificationType.DELETE_CONFIRMATION:
                // Delete Confirmation
                if (pn.foreground) {
                    this._handleUnregisterConfirmation(pn.data.hash);
                } else {
                    var content = {
                        title: pn.title,
                        message: pn.message,
                        icon: Icon.DISCONNECT,
                        buttonOk: _('unsubscribe'),
                        buttonCancel: true
                    };
                    NavigationComp.showPopup(content).done(function () {
                        this._handleUnregisterConfirmation(pn.data.hash);
                    }.bind(this));
                }

                break;

            case PushNotificationType.LIMIT_EXCEEDED:
                // Limit exceeded
                GUI.Notification.createGeneralNotification({
                    title: pn.title,
                    subtitle: pn.message,
                    clickable: true,
                    closeable: true
                }, NotificationType.SUCCESS);
                break;

            case PushNotificationType.MS_DEVICE_MANAGER:
            case PushNotificationType.NORMAL:
            case PushNotificationType.MESSAGE_CENTER:
            case PushNotificationType.INTERCOM_RICH:
            case PushNotificationType.INTERCOM_RICH_PAST:
                // Normal Miniserver Push Notification v1
                if (pn.foreground) {
                    // play sound
                    if (!pn.sound || pn.sound === "default") {
                        pn.sound = "notification.wav";
                    }

                    pn.sound = "resources/Audio/PNS/" + pn.sound;
                } //pn.title = "PN: " + pn.title;


                this.onNewNotification(pn);
                break;

            case PushNotificationType.SERVICE_NOTIFICATION:
                // Generic Service Notification
                if (!pn.data) {
                    pn.data = {}; // to prevent crashes if we have no data
                }

                var serviceType = -1;

                if (typeof pn.data.serviceType === "number") {
                    serviceType = pn.data.serviceType;
                }

                if (serviceType !== -1 && // if no serviceType is specified -> show all notifications
                    this._serviceMessages[serviceType] && // only show 1 notification per serviceType
                    pn.foreground) {
                    // if user taps the OS Notification, always show the screen directly!
                    return;
                }

                if (!pn.foreground) {
                    NavigationComp.showServiceMessageScreen(pn.title, pn.message, pn.data.icon, pn.data.btn, pn.data.link);
                    return;
                }

                var notification = GUI.Notification.createGeneralNotification({
                    time: pn.ts,
                    title: pn.title,
                    subtitle: pn.message,
                    iconSrc: pn.data.icon,
                    clickable: true,
                    closeable: true
                }, NotificationType.SUCCESS);
                notification.on(GUI.Notification.CLICK_EVENT, function () {
                    NavigationComp.showServiceMessageScreen(pn.title, pn.message, pn.data.icon, pn.data.btn, pn.data.link);
                    notification.remove(false);
                }.bind(this));
                notification.on("destroy", function () {
                    // remove the notification
                    if (serviceType === -1) {
                        this._serviceMessages[serviceType].splice(this._serviceMessages[serviceType].indexOf(notification), 1);
                    } else {
                        delete this._serviceMessages[serviceType];
                    }
                }.bind(this)); // store the notification

                if (serviceType === -1) {
                    this._serviceMessages[serviceType].push(notification);
                } else {
                    this._serviceMessages[serviceType] = notification;
                }

                break;

            default:
                // TODO-thallth check - on iOS it appears when installing fresh from Testflight!

                /*GUI.Notification.createGeneralNotification({
                    title: "received unknown Notification",
                    subtitle: JSON.stringify(pn),
                    closeable: true
                }, NotificationType.INFO);*/
                break;
        }
    }.bind(this), function error(e) {
        console.log("PNS error" + e);
    });
};
/**
 * checks if some Miniservers are stored with a delete array and tries unregister them
 * also checks for Miniservers which aren't in the archive anymore!
 * @private
 */


PushNotificationService.prototype._checkPendingUnregistrations = function _checkPendingUnregistrations() {
    Debug.PushNotification && console.log("PNS _checkPendingUnregistrations");

    this._loadPNSFile().done(function (miniservers) {
        var archiveMiniservers = PersistenceComponent.getAllMiniserver(),
            pnsFileMiniservers = miniservers,
            ms,
            i; // check if all Miniservers are "sync" with archive

        for (i = 0; i < pnsFileMiniservers.length; i++) {
            ms = pnsFileMiniservers[i];

            if (!archiveMiniservers[ms.mac] || ms.delete) {
                Debug.PushNotification && console.log(" -> pending unregistration:" + ms.mac);

                if (typeof ms.token !== "string") {
                    Debug.PushNotification && console.log(" -> unregister everything and delete Miniserver afterwards");
                    this.deleteMiniserverForMac(ms.mac);
                } else {
                    Debug.PushNotification && console.log(" -> unregister only all from delete array");

                    this._unregisterMiniserver4Push(ms.mac);
                }
            }
        }
    }.bind(this));
};
/**
 * sends the actual register request to the Miniserver
 * handles an exponential back-off
 * @param mac
 * @param delay
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._sendRegistrationToMiniserver = function _sendRegistrationToMiniserver(mac, delay) {
    Debug.PushNotification && console.log("PNS _sendRegistrationToMiniserver:" + mac + " delay: " + delay);
    var startDelay = 5000,
        maxDelay = 20000,
        nextDelay = delay > 0 ? delay * 2 : startDelay; // build a retry function

    var retry = function () {
        var retryDef = Q.defer(); // set timeout and retry

        setTimeout(function () {
            retryDef.resolve(this._sendRegistrationToMiniserver(mac, nextDelay));
        }.bind(this), nextDelay);
        return retryDef.promise;
    }.bind(this);

    return this.getCurrentPushToken().then(function (devicePushToken) {
        Debug.PushNotification && console.log(" - start new registration on Miniserver");
        /*var notification = GUI.Notification.createGeneralNotification({
            title: "Registering for Push Notifications..."
        }, NotificationType.INFO);*/

        var cmd = this._getRegisterCommand(devicePushToken);

        return CommunicationComponent.sendViaHTTP(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (result) {
            //notification.remove();
            var msPushToken = result.LL.value; // successfully registered at Miniserver

            Debug.PushNotification && console.log(" - Miniserver registration success, token=" + msPushToken);
            return msPushToken;
        }.bind(this), function (res) {
            console.error(" - Miniserver registration error");
            console.error(JSON.stringify(res)); // 499 = no response from "push.loxonecloud.com"

            if (getLxResponseCode(res) === 499 && nextDelay < maxDelay) {
                console.info(" - make another request and try again! (after " + nextDelay + "ms)");
                return retry();
            } else {
                console.info(" - bail out, try again on next reconnect..");
            } //notification.remove();


            throw new Error("Registration to Miniserver failed!");
        }.bind(this));
    }.bind(this));
};

PushNotificationService.prototype._getRegisterCommand = function _getRegisterCommand(devicePushToken) {
    var cmd,
        platform = this._getPlatform(),
        deviceInfo;

    if (!Feature.PN_REGISTRATION_LIST) {
        cmd = Commands.format(Commands.PNS.REGISTER, platform, devicePushToken);
        Debug.PushNotification && console.log("PNS _getRegisterCommand OLD: " + cmd);
    } else {
        deviceInfo = this._getPlatformDeviceInfo();
        cmd = Commands.format(Commands.PNS.REGISTER_WITH_INFO, platform, devicePushToken, deviceInfo);
        Debug.PushNotification && console.log("PNS _getRegisterCommand NEW: " + cmd);
    }

    return cmd;
};

PushNotificationService.prototype._getCheckRegistrationCommand = function _getCheckRegistrationCommand(devicePushToken) {
    var cmd, deviceInfo;

    if (!Feature.PN_REGISTRATION_LIST) {
        cmd = Commands.format(Commands.PNS.CHECK_REG, devicePushToken);
        Debug.PushNotification && console.log("PNS _getCheckRegistrationCommand OLD: " + cmd);
    } else {
        deviceInfo = this._getPlatformDeviceInfo();
        cmd = Commands.format(Commands.PNS.CHECK_REG_WITH_INFO, devicePushToken, deviceInfo);
        Debug.PushNotification && console.log("PNS _getCheckRegistrationCommand NEW: " + cmd);
    }

    return cmd;
};
/**
 * sends the normal unregister request to the server
 * @param devicePushToken
 * @param mac
 * @param token
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._sendUnregisterToServer = function _sendUnregisterToServer(devicePushToken, mac, token) {
    Debug.PushNotification && console.log("PNS _sendUnregisterToServer:" + devicePushToken + " mac:" + mac + " token:" + token);
    var def = Q.defer();
    var authString = devicePushToken + mac,
        auth = CryptoJS.HmacSHA1(authString, token).toString(),
        payload = JSON.stringify({
            "pushid": devicePushToken,
            "mac": mac,
            "auth": auth
        });
    Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.LXSVR, PNS_COMMANDS.WS_APIV1_UNREGISTER, payload);
    Debug.PushNotification && console.log(" - send unregister with token:" + token);
    $.ajax({
        type: "POST",
        url: PNS_COMMANDS.WS_APIV1_UNREGISTER,
        processData: false,
        contentType: "application/json; charset=UTF-8",
        dataType: "json",
        data: payload,
        success: function (data, textStatus, jqXHR) {
            Debug.PushNotification && console.log(JSON.stringify(data));
            var hasUnregistered = false;

            if (data["error_code"] === 200) {
                Debug.PushNotification && console.log(" - Server unregistration successful");
                hasUnregistered = true;
            } else if (data["error_code"] === 801) {
                // no existing entry on server
                Debug.PushNotification && console.info(" - Server has no registration!");
                hasUnregistered = true;
            } else if (data["error_code"] === 806) {
                Debug.PushNotification && console.info(" - Server has no registration!"); // {"valid":true,"unregister_reports":{"0c92ed01-0392-610a-ffffeee0009800b5":{"error_code":806,"error":"Invalid parameter: TargetArn Reason: No endpoint found for the target arn specified"}}}

                def.reject(new Error(data["error_code"] + " - " + data["error"]));
            } else if (data["error_code"] === 402) {
                Debug.PushNotification && console.info(" - Invalid Auth String!"); // {"valid":false,"error_code":402,"error":"Invalid Auth String!"}

                this._removeMiniserverPushTokensFromDeleteArray(mac, token).done(function () {
                    Debug.PushNotification && console.log("Token " + token + " removed!");
                }); // Unregister unknown


                this._sendUnregisterUnknownToServer(devicePushToken, mac);

                def.reject(new Error(data["error_code"] + " - " + data["error"]));
            } else {
                console.error(" - Server unregistration failed!");
                console.error(JSON.stringify(data));
                def.reject(new Error(data["error_code"] + " - " + data["error"]));
            }

            if (hasUnregistered) {
                // remove and resolve afterwards
                def.resolve(this._removeMiniserverPushTokensFromDeleteArray(mac, token).then(function () {
                    return "Unregistered from Push Notifications!";
                }, function () {
                    return "Unregistered from Push Notifications!"; // also on error!
                }));
            }
        }.bind(this),
        error: function (jqXHR, textStatus, errorThrown) {
            console.error(" - Server unregistration failed");
            console.error(errorThrown);

            try {
                var data = JSON.parse(errorThrown);

                if (data["error_code"] === 402) {
                    Debug.PushNotification && console.info(" - Invalid Auth String!"); // {"valid":false,"error_code":402,"error":"Invalid Auth String!"}

                    this._removeMiniserverPushTokensFromDeleteArray(mac, token).done(function () {
                        Debug.PushNotification && console.log("Token " + token + " removed!");
                    }); // Unregister unknown


                    this._sendUnregisterUnknownToServer(devicePushToken, mac);

                    def.reject(new Error(data["error_code"] + " - " + data["error"]));
                } else {
                    def.reject(new Error("Unregistration to Loxone Server failed, please try again!"));
                }
            } catch (e) {
                def.reject(new Error("Unregistration to Loxone Server failed, please try again!"));
                console.log(e.stack);
            }
        }.bind(this)
    });
    return def.promise;
};
/**
 * sends the unregisterUnknown request to the server
 * @param devicePushToken
 * @param mac
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._sendUnregisterUnknownToServer = function _sendUnregisterUnknownToServer(devicePushToken, mac) {
    Debug.PushNotification && console.log("PNS _sendUnregisterUnknownToServer:" + devicePushToken + " mac:" + mac);
    var def = Q.defer(),
        payload = JSON.stringify({
            "pushid": devicePushToken,
            "mac": mac
        });
    Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.LXSVR, PNS_COMMANDS.WS_APIV1_UNREGISTER_UNKNOWN, payload);
    $.ajax({
        type: "POST",
        url: PNS_COMMANDS.WS_APIV1_UNREGISTER_UNKNOWN,
        processData: false,
        contentType: "application/json; charset=UTF-8",
        dataType: "json",
        data: payload,
        success: function (data, textStatus, jqXHR) {
            Debug.PushNotification && console.log(JSON.stringify(data));

            if (data["error_code"] === 200) {
                Debug.PushNotification && console.log(" - Server unregistration request successfully sent!"); // unregistration was successfully initiated, now wait for confirmation notification

                def.resolve("Unregistration request successfully sent!");
            } else if (data["error_code"] === 801) {
                // no existing entry on server
                Debug.PushNotification && console.info(" - Server has no registration!"); // unregistration not needed, everything's good!

                def.resolve("Unregistered from Push Notifications!");
            } else {
                console.error(" - Server unregistration failed");
                console.error(JSON.stringify(data));
                def.reject(new Error(data["error_code"] + " - " + data["error"]));
            }
        }.bind(this),
        error: function (jqXHR, textStatus, errorThrown) {
            console.error(" - Server unregistration failed");
            console.error(errorThrown);
            def.reject(new Error("Unregistration to Loxone Server failed, please try again!"));
        }
    });
    return def.promise;
};
/**
 * handles the unregisterUnknown push notification's hash
 * @param hash from push notification
 * @private
 */


PushNotificationService.prototype._handleUnregisterConfirmation = function _handleUnregisterConfirmation(hash) {
    Debug.PushNotification && console.log("PNS _handleUnregisterConfirmation:" + hash);

    this._sendUnregisterConfirmation(hash).done(function success() {
        Debug.PushNotification && console.log("PNS _sendUnregisterConfirmation was successfull!");
        /*GUI.Notification.createGeneralNotification({
            title: "Unregistration from Push Notification successful",
            removeAfter: 3
        }, NotificationType.SUCCESS);*/
    }, function error(e) {
        console.error("PNS _sendUnregisterConfirmation failed!");
        console.error(e);
        GUI.Notification.createGeneralNotification({
            title: "Unregistration from Push Notification failed",
            subtitle: e,
            removeAfter: 3
        }, NotificationType.ERROR);
    }, function notify(msg) {
        console.warn("PNS _sendUnregisterConfirmation failed!");
        GUI.Notification.createGeneralNotification({
            title: "Unregistration from Push Notification failed",
            subtitle: msg,
            removeAfter: 3
        }, NotificationType.WARNING);
    });
};
/**
 * sends the unregister confirmation (hash from the push notification) to the server
 * @param hash from push notification
 * @param delay for exponential back-off
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._sendUnregisterConfirmation = function _sendUnregisterConfirmation(hash, delay) {
    var def = Q.defer(),
        nextDelay = delay > 0 ? delay * 2 : 1000; // build a retry function

    var retry = function () {
        var retryDef = Q.defer(); // set timeout and retry

        setTimeout(function () {
            retryDef.resolve(this._sendUnregisterConfirmation(hash, nextDelay));
        }.bind(this), nextDelay);
        return retryDef.promise;
    }.bind(this);

    Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.LXSVR, PNS_COMMANDS.WS_APIV1_UNREGISTER_UNKNOWN + "/" + hash);
    $.ajax({
        type: "POST",
        url: PNS_COMMANDS.WS_APIV1_UNREGISTER_UNKNOWN + "/" + hash,
        dataType: "json",
        success: function (data, textStatus, jqXHR) {
            Debug.PushNotification && console.log(JSON.stringify(data));

            if (data["error_code"] === 200) {
                console.log(" - Server unregister successful");
                def.resolve();
            } else {
                console.error(" - Server unregister failed");
                console.error(JSON.stringify(data));
                def.reject(new Error(data["error_code"] + " - " + data["error"]));
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.error(" - Server unregistration failed");
            console.error(errorThrown);
            def.notify(new Error("retry in " + nextDelay / 1000 + " seconds.."));
            def.resolve(retry());
        }
    });
    return def.promise;
};
/**
 * updates the DevicePushToken on the Loxone Server
 * @param oldToken
 * @param newToken
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._updateDevicePushTokens = function _updateDevicePushTokens(oldToken, newToken) {
    Debug.PushNotification && console.log("PNS _updateDevicePushTokens old:" + oldToken + " new:" + newToken);
    return this._loadPNSFile().then(function (pnsFile) {
        // search a random Miniserver with a token
        var randomMS = null,
            startIdx = Math.floor(Math.random() * pnsFile.miniservers.length);

        for (var i = startIdx; i < pnsFile.miniservers.length; i++) {
            randomMS = pnsFile.miniservers[i];

            if (typeof randomMS.token === "string" && randomMS.token.length > 0) {
                // use him!
                break;
            } else {
                randomMS = null; // set it to null
            }

            if (i === pnsFile.miniservers.length - 1) {
                // we are in last round and have no Miniserver found, start again at 0!
                i = 0;
            }
        }

        if (randomMS != null) {
            Debug.PushNotification && console.log(" - using Miniserver:" + JSON.stringify(randomMS));

            var platform = this._getPlatform(),
                mac = randomMS.mac.toUpperCase(),
                type = platform.toUpperCase(),
                authString = type + oldToken + newToken + mac;

            var payload = {
                type: type,
                pushid_old: oldToken,
                pushid_new: newToken,
                mac: mac,
                auth: CryptoJS.HmacSHA1(authString, randomMS.token).toString()
            };
            return this._sendUpdateRequestToServer(payload).then(function () {
                return this._setDevicePushToken(newToken, true);
            }.bind(this), function () {
                console.info("DevicePushToken update failed, delete all registrations and ask user again to register push.."); // the update failed, so push doesn't work anyway atm...
                // -> simply delete all registrations and update the current devicePushToken
                // -> we would register one Miniserver after another as soon as we connect the next time! (registration must be done via Miniserver)

                var pnsFileMiniservers = pnsFile.miniservers,
                    i;

                for (i = 0; i < pnsFileMiniservers.length; i++) {
                    this.deleteMiniserverForMac(pnsFileMiniservers[i].mac);
                } // show popup, to inform user what's happening and what he should do (he has to connect to each Miniserver to enable push again)


                NavigationComp.showPoplatformObj.arePlayservicesAvailablepup({
                    title: _('error'),
                    message: _('notifications.update-error.message'),
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.orange,
                    buttonOk: true
                });
                return this._setDevicePushToken(newToken, true);
            }.bind(this));
        } else {
            console.info(" - no Miniservers registered for PN!");
            return this._setDevicePushToken(newToken, true);
        }
    }.bind(this));
};
/**
 * sends the payload to the server and handles error and retries
 * @param payload
 * @param [delay=1000]
 * @private
 */


PushNotificationService.prototype._sendUpdateRequestToServer = function _sendUpdateRequestToServer(payload, delay) {
    Debug.PushNotification && console.log("PNS _sendUpdateRequestToServer");
    var def = Q.defer(),
        nextDelay = delay > 0 ? delay * 2 : 1000; // build a retry function

    var retry = function () {
        var retryDef = Q.defer(); // set timeout and retry

        setTimeout(function () {
            retryDef.resolve(this._sendUpdateRequestToServer(payload, nextDelay));
        }.bind(this), nextDelay);
        return retryDef.promise;
    }.bind(this);

    Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.LXSVR, PNS_COMMANDS.WS_APIV1_UPDATE, payload);
    $.ajax({
        type: "POST",
        url: PNS_COMMANDS.WS_APIV1_UPDATE,
        processData: false,
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(payload),
        dataType: "json",
        success: function (data, textStatus, jqXHR) {
            Debug.PushNotification && console.log(JSON.stringify(data)); // TODO-thallth check error case (701 if no registration is known)

            if (data["error_code"] === 200) {
                Debug.PushNotification && console.log(" - Server update successful"); // update was successful

                def.resolve();
            } else {
                console.error(" - Server update failed");
                console.error(JSON.stringify(data));
                def.reject(new Error(data["error_code"] + " - " + data.error));
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.error(" - Server update failed");
            console.error(errorThrown);
            def.notify(new Error("retry in " + nextDelay / 1000 + " seconds.."));
            def.resolve(retry());
        }
    });
    return def.promise;
};
/**
 * loads the DevicePushToken from the Push.json file or requests it from the Plugin
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._getDevicePushToken = function _getDevicePushToken() {
    Debug.PushNotification && console.log("PNS _getDevicePushToken");
    var def = Q.defer();

    if (!this._getPlatform()) {
        Debug.PushNotification && console.log(" - no plugin on this platform:" + this.platform);
        def.reject("Platform not supported");
    } else if (this._devicePushToken != null) {
        // don't take the token from the file! (we must request it once per app-run!
        Debug.PushNotification && console.log(" - resolve with stored token:" + this._devicePushToken);
        def.resolve(this._devicePushToken);
    } else {
        Debug.PushNotification && console.log(" - request token from device");

        if (this._requestDevicePushTokenPromise) {
            Debug.PushNotification && console.log(" - use already running request");
        } else {
            Debug.PushNotification && console.log(" - start new request");
            this._requestDevicePushTokenPromise = this._requestDevicePushTokenFromPlugin(); // store the token as soon as we get one for future!

            this._requestDevicePushTokenPromise.then(function (token) {
                this._devicePushToken = token;

                this._setDevicePushToken(token);
            }.bind(this), function (error) {
                console.error(error);
            }).finally(function () {
                // delete the promise if the promise resolves or rejects
                delete this._requestDevicePushTokenPromise; // remove it
            }.bind(this));
        }

        def.resolve(this._requestDevicePushTokenPromise);
    }

    return def.promise;
};
/**
 * requests the device push token from the plugin
 * @param delay for exponential back-off
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._requestDevicePushTokenFromPlugin = function (delay) {
    Debug.PushNotification && console.log("PNS _requestDevicePushTokenFromPlugin:" + delay);
    var def = Q.defer(),
        nextDelay = delay > 0 ? delay * 2 : 1000; // build a retry function

    var retry = function () {
        var retryDef = Q.defer(); // set timeout and retry

        setTimeout(function () {
            retryDef.resolve(this._requestDevicePushTokenFromPlugin(nextDelay));
        }.bind(this), nextDelay);
        return retryDef.promise;
    }.bind(this);

    var settings = {};

    if (this.platform === PlatformType.Android) {// we don't have any settings until now..
    } else if (this.platform === PlatformType.IOS) {
        settings.badge = true;
        settings.sound = true;
        settings.alert = true;
    } // Wait 10 Seconds
    // Issue:
    // I noticed, that this promise won't be fullfilled on iOS on a specific unknown case.
    // According to the Apples Push Notification Troubleshooting Guide (https://developer.apple.com/library/content/technotes/tn2265/_index.html)
    // The callbacks we count on won't be called if no persistent connection between the device and Apples push server is not available
    // Eg:
    // • Blocked TCP port 5223
    // • No internet connection


    var backupRejector = setTimeout(function () {
        console.error("PNS native callback has not been called!");
        def.reject("Push Notifications not available!");
    }, 10000);
    pushNotification.isPushAvailable(() => {

        Debug.PushNotification && console.log("PNS", "_requestDevicePushTokenFromPlugin - isPushAvailable --> true, now check permission");
        this.checkPushPermissions().then((succ) => {
            console.error("PNS", "_requestDevicePushTokenFromPlugin - checkPushPermissions --> success! " + JSON.stringify(succ));
            pushNotification.registerPN((token) => {
                Debug.PushNotification && console.log(" - token from plugin:" + token);
                def.resolve(token);
            }, (tokenRegError) => {
                if (tokenRegError === "SERVICE_NOT_AVAILABLE") {
                    // could be on android, if you request right after app start!
                    Debug.PushNotification && console.log(" - SERVICE_NOT_AVAILABLE, retry!"); // best thing to do is to try again later!

                    def.resolve(retry());
                } else {
                    console.error("PNS registerPN error:");
                    console.error(tokenRegError);
                    def.reject(new Error(tokenRegError));
                }
            }, settings);
        }, (permissionError) => {
            console.error("PNS", "_requestDevicePushTokenFromPlugin - checkPushPermissions --> failed! " + JSON.stringify(permissionError));
            def.reject(new Error(permissionError));
        });

    }, () => {
        // not available
        def.reject("Push Notifications not available!");
    });
    return def.promise.finally(() => {
        // Clear the backupRejector to prevent rejecting an already rejected or resolved promise;
        clearTimeout(backupRejector);
    });
};

PushNotificationService.prototype.verifyPushPermissions = function verifyPushPermissions() {
    Debug.PushNotification && console.log("PNS", "verifyPushPermissions");
    return this.checkPushPermissions().fail(this.requestPushPermissions.bind(this));
}

PushNotificationService.prototype.checkPushPermissions = function checkPushPermissions() {
    Debug.PushNotification && console.log("PNS", "checkPushPermissions");
    var def = Q.defer();
    var platform = PlatformComponent.getPlatformInfoObj().platform;

    if (platform === PlatformType.IOS) {
        // We'll take care of it later when registering the device
        def.resolve(true);
    } else {
        // This will always fail on iOS when the permissions have not yet been granted
        cordova.plugins.diagnostic.isRemoteNotificationsEnabled(enabled => {
            if (enabled) {
                def.resolve(enabled);
            } else {
                def.reject(enabled);
            }
        }, error => {
            def.reject(error);
        });
    }

    return def.promise;
}

PushNotificationService.prototype.requestPushPermissions = function requestPushPermissions() {
    Debug.PushNotification && console.log("PNS", "requestPushPermissions");
    var def = Q.defer();

    cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.POST_NOTIFICATIONS, (result) => {
        Debug.PushNotification && console.log("PNS", "   -> requestPushPermissions resulted! " + JSON.stringify(result));
        if (result.hasPermission) {
            def.resolve(result);
        } else {
            def.reject(result);
        }
    }, (permissionError) => {
        console.error("PNS", "   -> requestPushPermissions failed! " + JSON.stringify(permissionError));
        def.reject(permissionError);
    });

    return def.promise;
}


/**
 * saves the DevicePushToken and saves the Push.json file
 * @param token
 * @param override {boolean} if the token should be overwritten if a token is already stored in the file
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._setDevicePushToken = function _setDevicePushToken(token, override) {
    Debug.PushNotification && console.log("PNS _setDevicePushToken:" + token);
    return this._loadPNSFile().then(function (pnsFile) {
        if (typeof token === "string" && token.length > 0) {
            if (!pnsFile.devicePushToken) {
                pnsFile.devicePushToken = token;
            } else if (override) {
                Debug.PushNotification && console.info(" - overriding token of Push.json..");
                pnsFile.devicePushToken = token;
            } else {
                Debug.PushNotification && console.info(" - not set, because override is false and a token is already stored in Push.json!");
            }
        } else {
            Debug.PushNotification && console.info(" - deleting DevicePushToken");
            delete pnsFile.devicePushToken; // TODO-thallth also unregister everything? (maybe if we disable push?)
        }

        return this._savePNSFile();
    }.bind(this));
};
/**
 * sets a fake token and uses it when requesting the DevicePushToken
 * set to null to reset (= request again from plugin)
 * @param fakeToken
 * @private
 */


PushNotificationService.prototype._setFakeDevicePushToken = function _setFakeDevicePushToken(fakeToken) {
    this._devicePushToken = fakeToken; // set to null to reset (= request again from plugin)
};
/**
 * returns one of the supported platforms or false
 * only use this for communicating with Loxone Server - to make sure that we use the same id's!
 * @returns {string|boolean}
 * @private
 */


PushNotificationService.prototype._getPlatform = function _getPlatform() {
    var platformObj = PlatformComponent.getPlatformInfoObj();
    this.platform = this.platform || platformObj.platform;
    var platformType = false;

    if (this.platform === PlatformType.Android) {
        if (platformObj.manufacturer === "Amazon") {
            platformType = "android-amazon";
        } else if (platformObj.arePlayservicesAvailable) {
            platformType = "android";
        } else {
            //for chinese devices
            switch (platformObj.manufacturer) {
                case "OPPO":
                    platformType = "oppo";
                    break;

                case "HUAWEI":
                    platformType = "huawei";
                    break;

                case "Xiaomi":
                    platformType = "xiaomi";
                    break;

                case "vivo":
                    platformType = "vivo";
                    break;

                default:
                    callbackContext.error(ERR_GooglePlayServicesNotAvailable);
            }
        }
    } else if (this.platform === PlatformType.IOS) {
        platformType = "ios";
    }

    return platformType;
};
/**
 * Returns a string containing the model (including manufacturer info) and, if available, the userdefined name of the
 * device. E.g. Markus' iPhone.
 * @returns {string} {manufacturer model};{name - if available} --> both parts uriEncoded
 * @private
 */


PushNotificationService.prototype._getPlatformDeviceInfo = function _getPlatformDeviceInfo() {
    var pf = PlatformComponent.getPlatformInfoObj(),
        model;
    model = pf.manufacturer + " " + pf.model; // Samsung SM-G991B, Apple iPad7,3, Apple iPhone12,8
    // ensure no semicolons are part of the name and model

    model = model.replaceAll(";", ","); // ensure it's uriEncoded

    model = encodeURIComponent(model);
    return model + ";";
};
/**
 * loads the PushToken of the current device
 * @returns {Promise}
 */


PushNotificationService.prototype.getCurrentPushToken = function getCurrentPushToken() {
    Debug.PushNotification && console.log("PNS getCurrentPushToken");
    return this._getDevicePushToken();
};
/**
 * loads the current PushToken for the Device (from the Plugin, or "cache")
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._getStoredDevicePushToken = function _getStoredDevicePushToken() {
    Debug.PushNotification && console.log("PNS _getStoredDevicePushToken");
    return this._loadPNSFile().then(function (pnsFile) {
        if (pnsFile.hasOwnProperty("devicePushToken")) {
            return pnsFile.devicePushToken;
        } else {
            throw new Error("No DevicePushToken stored");
        }
    });
};
/**
 * loads the PushToken for the Miniserver of the given mac
 * @param mac Mac Address of Miniserver
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._getStoredMiniserverPushToken = function _getStoredMiniserverPushToken(mac) {
    Debug.PushNotification && console.log("PNS _getStoredMiniserverPushToken");
    return this._getMiniserverForMac(mac).then(function (miniserver) {
        if (miniserver.hasOwnProperty("token")) {
            Debug.PushNotification && console.log(" - token:" + miniserver.token);
            return miniserver.token;
        } else {
            Debug.PushNotification && console.log(" - no MiniserverPushToken stored");
            throw new Error("No MiniserverPushToken stored");
        }
    });
};
/**
 * searches for MiniserverPushTokens to be deleted
 * @param mac
 * @private
 */


PushNotificationService.prototype._getMiniserverPushTokensToDelete = function _getMiniserverPushTokensToDelete(mac) {
    Debug.PushNotification && console.log("PNS _getMiniserverPushTokensToDelete");
    return this._getMiniserverForMac(mac).then(function (miniserver) {
        if (miniserver.hasOwnProperty("delete")) {
            Debug.PushNotification && console.log(" - tokens:" + JSON.stringify(miniserver.delete));
            return miniserver.delete;
        } else {
            Debug.PushNotification && console.log(" - no MiniserverPushTokens to delete stored");
            throw new Error("No MiniserverPushTokens to delete stored");
        }
    });
};
/**
 * removes the token from the delete array
 * removes delete array if it's empty
 * @param mac
 * @param [token]
 * @private
 */


PushNotificationService.prototype._removeMiniserverPushTokensFromDeleteArray = function _removeMiniserverPushTokensFromDeleteArray(mac, token) {
    Debug.PushNotification && console.log("PNS _removeMiniserverPushTokensFromDeleteArray");
    return this._getMiniserverForMac(mac).then(function (ms) {
        if (ms.hasOwnProperty("delete")) {
            var idx = ms.delete.indexOf(token);

            if (idx !== -1) {
                Debug.PushNotification && console.log(" - removing token:" + token);
                ms.delete.splice(idx, 1);
            } else if (typeof token === "string") {
                Debug.PushNotification && console.log(" - token '" + token + "' not in array:" + JSON.stringify(ms.delete));
            }

            if (ms.delete.length === 0) {
                Debug.PushNotification && console.log(" - removing empty delete array");
                delete ms.delete;
            }

            return this._savePNSFile();
        }
    }.bind(this));
};
/**
 * sets the PushToken to the Miniserver object of the given mac, then saves the Push.json
 * @param mac Mac Address of Miniserver
 * @param token PushToken for Miniserver
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._setMiniserverPushToken = function _setMiniserverPushToken(mac, token) {
    Debug.PushNotification && console.log("PNS _setMiniserverPushToken:" + mac + " token:" + token);
    var validToken = typeof token === "string" && token.length > 0;
    return this._getMiniserverForMac(mac, validToken).then(function (miniserver) {
        if (validToken) {
            // set the token
            if (miniserver.hasOwnProperty("token")) {
                //console.warn(" - why do you overwrite the MiniserverPushToken?");
                console.warn(" - overwriting token, add old to delete array");
                if (miniserver.delete) miniserver.delete.push(miniserver.token); else miniserver.delete = [token];
            }

            miniserver.token = token;
        } else {
            console.info(" - removing MiniserverPushToken");
            delete miniserver.token;
        }

        return this._savePNSFile();
    }.bind(this));
};
/**
 * loads the Miniserver info (stored in Push.json) for the given mac
 * @param mac Mac Address of Miniserver
 * @param initNew {boolean} if a new Miniserver should be initialized when not stored already
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._getMiniserverForMac = function _getMiniserverForMac(mac, initNew) {
    Debug.PushNotification && console.log("PNS _getMiniserverForMac:" + mac);
    return this._loadPNSFile().then(function (pnsFile) {
        var miniservers = pnsFile.miniservers || [],
            miniserver;

        for (var i = 0; i < miniservers.length; i++) {
            if (miniservers[i].mac === mac) {
                miniserver = miniservers[i];
                break;
            }
        }

        if (initNew && !miniserver && mac != null && mac.length > 0) {
            console.info(" - adding new Miniserver to Push.json");
            miniserver = {
                mac: mac
            };
            miniservers.push(miniserver);
            pnsFile.miniservers = miniservers; // set miniservers to pnsFile
            // save file first

            return this._savePNSFile().then(function () {
                return miniserver;
            });
        } else if (miniserver) {
            return miniserver;
        } else {
            throw new Error("Miniserver not configured for PNS!");
        }
    }.bind(this));
};
/**
 * loads the Push.json file
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._loadPNSFile = function _loadPNSFile() {
    //Debug.PushNotification && console.log("PNS _loadPNSFile");
    var def = Q.defer();

    if (this._pnsFile != null) {
        // PNS File is already loaded
        def.resolve(this._pnsFile);
    } else {
        // load PNS File
        def.resolve(PersistenceComponent.loadFile("Push.json", DataType.OBJECT).then(function (pnsFile) {
            Debug.PushNotification && console.log(" - loaded Push.json");
            this._pnsFile = pnsFile;
            return this._pnsFile;
        }.bind(this)).catch(function () {
            Debug.PushNotification && console.log(" - creating new Push.json");
            this._pnsFile = {}; // create new Object = new PNS File

            return this._pnsFile;
        }.bind(this)));
    }

    return def.promise;
};
/**
 * saves the Push.json file
 * @returns {Promise}
 * @private
 */


PushNotificationService.prototype._savePNSFile = function _savePNSFile() {
    Debug.PushNotification && console.log("PNS _savePNSFile");
    var def = Q.defer();

    if (this._pnsFile != null) {
        PersistenceComponent.saveFile("Push.json", this._pnsFile, DataType.OBJECT).then(function () {
            Debug.PushNotification && console.log("saved successfully Push.json");
            def.resolve();
        }).catch(function (e) {
            console.error("couldn't save Push.json");
            def.reject(e);
        });
    } else {
        def.reject(new Error("no PNS File to save!"));
    }

    return def.promise;
}; // create the service instance


window.pushNotificationService = new PushNotificationService();
