'use strict';

window.SearchUtils = function (SearchUtils) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var TYPE_RATING = 5,
            GROUP_RATING = 4,
            SYNONYM_RATING = 3;

        class ControlIndex extends SearchUtils.Index {
            constructor() {
                super(SearchLocation.CONTROLS);
            }

            destroy() {
                super.destroy(...arguments);
            }

            /**
             * Will put a control onto the search index stack.
             * @param ctrl
             */
            addControl(ctrl) {
                //TODO-woessto: 146482885: use a localized friendly name for the control type for searching
                this._addGroupKeywords(ctrl);

                this._addControlTypeAsKeyword(ctrl);

                this._addOtherKeywordsFor(ctrl);

                this.addItem(ctrl.uuidAction, ctrl.name, ctrl.groupDetail, ctrl);
            }

            _addGroupKeywords(ctrl) {
                var splitDetails = ctrl.groupDetail.split(SEPARATOR_SYMBOL);
                splitDetails.forEach(function (group) {
                    this.addKeyword(group, ctrl.uuidAction, GROUP_RATING);
                }.bind(this));
            }

            _addControlTypeAsKeyword(ctrl) {
                var ctrlTypeName = ctrl.getControlTypeName();

                if (ctrlTypeName) {
                    this.addKeyword(ctrlTypeName, ctrl.uuidAction, TYPE_RATING);
                } else {
                    Debug.Search.General && console.error("Could not determine a search keyword for the control " + "type '" + ctrl.type + "'");
                }
            }

            /**
             * Will add keywords that are provided especially for this control type.
             * @param ctrl
             * @private
             */
            _addOtherKeywordsFor(ctrl) {
                var ctrlType = ctrl.type,
                    translationId = "search.controltype." + ctrlType.toLowerCase() + ".keywords",
                    txt = _(translationId);

                if (txt !== translationId) {
                    txt.split(",").forEach(function (keyword) {
                        this.addKeyword(txt.trim(), ctrl.uuidAction, SYNONYM_RATING);
                    }.bind(this));
                }
            }

        }

        SearchUtils.ControlIndex = ControlIndex;
    }
    return SearchUtils;
}(window.SearchUtils || {});
