"use strict";
/**
 * is used to get Information of the possible connected watch - uses com.loxone.kerberos.utility's plugin watch.js
 * @type {WatchAdapter}
 */

var WatchAdapter = function () {
    function WatchAdapter() {
        this._watchAvailable = false;
        this._watchAppInstalled = false;

        if (this.hasWatchFunctionality()) {
            watch.getInfo(function (watchInfo) {
                this._watchAvailable = watchInfo["watchAvailable"];
                this._watchAppInstalled = watchInfo["watchAppIsInstalled"];
            }.bind(this));
        }
    }

    WatchAdapter.prototype.hasWatchFunctionality = function hasWatchFunctionality() {
        return window.hasOwnProperty("watch");
    };

    WatchAdapter.prototype.hasWatch = function hasWatch() {
        return this._watchAvailable;
    };

    WatchAdapter.prototype.watchAppInstalled = function watchAppInstalled() {
        return this._watchAppInstalled;
    };

    return WatchAdapter;
}();
