'use strict';
/**
 * This adapter is being used to gather information on what parts of the app are being used. It will remove any information
 * that allows to conclude which Miniserver did deliver this information.
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *        This feature is used to provide feedback from our Beta tests & is not used in release versions.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

/**
 * Events are being tracked in different types.
 * @type {{FEATURE: string, CONTROL: string, SETTING: string}}
 */

var EventType = {
    FEATURE: "F",
    CONTROL: "C",
    SETTING: "S",
    CONNECTION: "V"
};
var FeatureUsage = {
    Autopilot: {
        ID: "Autopilot",
        LIST: "list",
        CREATE: "create",
        UPDATE: "update",
        DELETE: "delete",
        SET_ACTIVE: "setActive"
    },
    Search: {
        ID: "Search",
        RESULTED: "resulted",
        USING_RESULT: "usingResult"
    },
    OperatingMode: {
        ID: "OperatingModeSchedule",
        CREATE: "create",
        DELETE: "delete",
        UPDATE: "update",
        LIST: "list"
    },
    ExpertMode: {
        ID: "ExpertMode",
        OPEN: "open",
        VERIFY: "verify",
        SAVE: "save",
        Type: {
            CONTROL: "control",
            GROUP: "group"
        }
    },
    QuickActions: {
        ID: "QuickActions",
        LIST: "list",
        ADD: "add",
        REMOVE: "remove"
    }
};
var SettingUsage = {
    IS_ADMIN: "IsAdmin",
    EXPERT_MODE: "ExpertMode",
    DARK_MODE: "DarkMode",
    QUICK_ACTION_SUPPORT: "SupportQuickActions",
    HOME_TAB: "HomeTab",
    Fingerprint: {
        ID: "Fingerprint",
        NOT_AVAILABLE: "n/a",
        ENABLED: "enabled",
        DISABLED: "disabled"
    },
    MS_COUNT: "MiniserversInApp"
};
var ConnectionUsage = {
    ReachMode: {
        ID: "ReachMode",
        Type: {
            INTERNAL: "Internal",
            EXTERNAL: "External"
        }
    }
};
var UsageDimensions = {
    LxAppVersion: 1 // required as iOS and Android Plugins track the version differently

};

VendorHub.Usage = function () {
    var usage = {},
        id = '',
        // tracking has been deactivated.
        prevUrl,
        currExpertModeType,
        DBG_NAME = "Usage";
    /**
     * Will start up the usage recorder.
     */

    usage.initialize = function initialize() {
        if (_doTrack()) {
            Debug.Usage.Logging && console.log(DBG_NAME, "*** initialize");

            _ga().startTrackerWithId(id);

            _ga().setAppVersion(PlatformComponent.getAppInfoObj().appVersion);

            _ga().setAnonymizeIp(true);

            _ga().setAllowIDFACollection(false);
        } else {
            Debug.Usage.Logging && console.log(DBG_NAME, "*** initialize without plugin, logging only.");
        }

        this._customDimension(UsageDimensions.LxAppVersion, PlatformComponent.getAppInfoObj().appVersion);
    };
    /**
     * Called when a new session is being started.
     * @param origin    how was it started, by resuming or by starting the app from scratch?
     */


    usage.newSession = function newSession(origin) {
        Debug.Usage.Logging && console.log(DBG_NAME, "*** recording new session: " + origin);
        _doTrack() && _ga().trackView(origin, '', true);

        if (prevUrl) {
            // resuming, track the previous current url again
            var urlCache = prevUrl;
            prevUrl = null;
            this.urlChanged(urlCache);
        }

        this._recordGlobalInfos();
    };
    /**
     * Will record all things that might change from one MS to another.
     */


    usage.recordAfterMSChange = function recordAfterMSChange() {
        try {
            this.settingBool(SettingUsage.DARK_MODE, NavigationComp.getDarkModeState()); //this.settingBool(SettingUsage.EXPERT_MODE, PersistenceComponent.expertModeEnabled());

            this.settingBool(SettingUsage.IS_ADMIN, SandboxComponent.hasAdminPermission());
            this.settingBool(SettingUsage.HOME_TAB, NavigationComp.getHomeScreenAvailability());
        } catch (ex) {
            console.error(DBG_NAME, "Error while recording usage infos after changing the Miniserver! ", ex);
        }
    };
    /**
     * Will add a command to the usage records
     * @param controlType   what control this cmd was sent from.
     * @param command       the command that has been sent
     * @param source        what screen did send the command
     * @param [value]       some numeric value that is important for this command
     */


    usage.controlCmd = function controlCmd(controlType, command, source, value) {
        try {
            source = source || CmdSrc.OTHER;

            this._recordEvent(EventType.CONTROL, controlType, command, source, value);
        } catch (ex) {
            console.error(DBG_NAME, "Couldn't log the cmd " + command + "!", ex.message);
        }
    };
    /**
     * Will record the apps location without any uuids.
     * @param newUrl
     */


    usage.urlChanged = function urlChanged(newUrl) {
        if (newUrl === prevUrl) {
            return; // no duplicates!
        }

        try {
            var parts = newUrl.split("/"); // update previous url attribute

            prevUrl = newUrl;

            if (parts.length > 2 && parts[1] === UrlIds.CONTROL) {
                // it's a control, translate the uuid into a control type. (remove pos 0 & 1, it's the SNR/Control)
                this._recordControlUrl(parts.splice(2, parts.length - 2));
            } else if (parts.length >= 2) {
                // remove the Serial number of the Miniserver
                this._recordView(parts.splice(1, parts.length - 1).join("/"));
            } else {
                this._recordView(newUrl);
            }
        } catch (ex) {
            console.error(DBG_NAME, "Couldn't log the url " + newUrl + "!", ex.message);
        }
    }; // ---------- Settings -----------------

    /**
     * Will record the value (numeric or text) of some setting inside the app
     * @param id       the ID of the setting (e.g. DarkMode)
     * @param [txtValue]    some label for this setting (e.g. active, n/a)
     * @param [numValue]    the current setting value (e.g. active)
     */


    usage.setting = function setting(id, txtValue, numValue) {
        _doTrack() && this._recordEvent(EventType.SETTING, id, txtValue, null, numValue);
    };
    /**
     * Will record the numeric value of some setting inside the app
     * @param id            the ID of the setting (e.g. DarkMode)
     * @param [numValue]    the current setting value (e.g. active)
     */


    usage.settingNum = function settingNum(id, numValue) {
        this.setting(id, null, numValue);
    };
    /**
     * Will record the numeric value of some setting inside the app
     * @param id            the ID of the setting (e.g. DarkMode)
     * @param value         the current setting value that represents a boolean value
     */


    usage.settingBool = function settingBool(id, value) {
        this.setting(id, null, _bool(value));
    };
    /**
     * Tracks the current setting concerning the fingerprint security
     * @param value enabled, disabled or not available
     */


    usage.fingerprint = function fingerprint(value) {
        this.setting(SettingUsage.Fingerprint.ID, value);
    }; // ---------- Features -----------------

    /**
     * Will record the usage of a feature (e.g. the autopilot).
     * @param id        what feature is being used
     * @param [action]  the action taken when using the feature
     * @param [arg]     some argument that is important for the action
     * @param [value]   some (numeric) value that is important for this event
     */


    usage.feature = function feature(id, action, arg, value) {
        _doTrack() && this._recordEvent(EventType.FEATURE, id, action, arg, value);
    };
    /**
     * Will record the usage of the autopilot designer
     * @param [action]  the action taken when using the feature
     * @param [label]     some label that is important for the action
     * @param [value]     some (numeric) that is important for the action
     */


    usage.autopilot = function feature(action, label, value) {
        this.feature(FeatureUsage.Autopilot.ID, action, label, value);
    };
    /**
     * Will record the usage of the operating mode schedule
     * @param [action]  the action taken when using the feature
     * @param [label]     some label that is important for the action
     * @param [value]     some (numeric) that is important for the action
     */


    usage.opModeSchedule = function opModeSchedule(action, label, value) {
        this.feature(FeatureUsage.OperatingMode.ID, action, label, value);
    };
    /**
     * Will record the usage of the search feature
     * @param [action]  the action taken when using the feature
     * @param [label]     some label that is important for the action
     * @param [value]     some (numeric) that is important for the action
     */


    usage.search = function search(action, label, value) {
        this.feature(FeatureUsage.Search.ID, action, label, value);
    };
    /**
     * Will record the usage of the search feature
     * @param [action]  the action taken when using the feature
     * @param [label]     some label that is important for the action
     * @param [value]     some (numeric) that is important for the action
     */


    usage.quickActions = function quickActions(action, label, value) {
        if (!PlatformComponent.isIOS()) {
            return;
        }

        this.feature(FeatureUsage.QuickActions.ID, action, label, value);
    };
    /**
     * Will record the usage of the expert mode feature
     * @param [action]  the action taken when using the feature
     * @param [type]    if the action affects a group or a category
     */


    usage.expertMode = function expertMode(action, type) {
        if (type) {
            // store it, as only the OPEN action will provide info on the type, verify and save will not.
            currExpertModeType = type;
        }

        this.feature(FeatureUsage.ExpertMode.ID, action, currExpertModeType);
    }; //--------- Connection --------------

    /**
     * Will record the usage of the expert mode feature
     * @param id            the type of connection info to log
     * @param [txtValue]       a textual label for this info (e.g. internal, external)
     * @param [numValue]       a numeric value to store for this info.
     */


    usage.connInfo = function connInfo(id, txtValue, numValue) {
        this._recordEvent(EventType.CONNECTION, id, null, txtValue, numValue);
    };
    /**
     * Will add the reach mode to the connection info record
     * @param rm
     */


    usage.recordReachMode = function recordReachMode(rm) {
        var record = false;

        if (rm === ReachMode.REMOTE) {
            record = ConnectionUsage.ReachMode.Type.EXTERNAL;
        } else if (rm === ReachMode.LOCAL) {
            record = ConnectionUsage.ReachMode.Type.INTERNAL;
        }

        if (record) {
            this.connInfo(ConnectionUsage.ReachMode.ID, record);
        }
    }; //--------- Helper methods --------------

    /**
     * Will record all things that are global all over the app.
     * @private
     */


    usage._recordGlobalInfos = function _recordGlobalInfos() {
        try {
            // how many miniservers are stored in the app?
            this.settingNum(SettingUsage.MS_COUNT, PersistenceComponent.getAllMiniserver(true).length); // does the device have fingerprint capabilities? if so, is it enabled?

            BiometricHelper.checkAvailability().then(function () {
                if (PersistenceComponent.getBiometricState()) {
                    this.fingerprint(SettingUsage.Fingerprint.ENABLED);
                } else {
                    this.fingerprint(SettingUsage.Fingerprint.DISABLED);
                }
            }.bind(this), function () {
                this.fingerprint(SettingUsage.Fingerprint.NOT_AVAILABLE);
            }.bind(this));
        } catch (ex) {
            console.error(DBG_NAME, "Error while recording global usage infos!", ex);
        }
    };
    /**
     * Will record a view. Will remove all uuids from the arguments
     * @param title the name of the view to record.
     */


    usage._recordView = function _recordView(title) {
        if (title === "") {
            return; // don't track empty strings.
        }

        title = this._removeUuids(title);
        Debug.Usage.Logging && console.log(DBG_NAME, "*** recording view: " + title);
        _doTrack() && _ga().trackView(title);
    };
    /**
     * Adds an event to the record, will remove all uuids from the arguments.
     * @param evType    if it's a settings, control or feature event.
     * @param category  the event-category
     * @param [action]  an action identifier
     * @param [label]   the label for this event.
     * @param [value]   an optional numeric value for this event.
     */


    usage._recordEvent = function _recordEvent(evType, category, action, label, value) {
        action = this._removeUuids(action);
        category = evType + "/" + this._removeUuids(category);
        label = this._removeUuids(label);
        var evStr = category + ": Action=" + action + " / Label=" + label + " / Value=" + value;
        Debug.Usage.Logging && console.log(DBG_NAME, "*** recording event: " + evStr);
        _doTrack() && _ga().trackEvent(category, action, label, value);
    };
    /**
     * Will convert the controls uuid into a control type to avoid having an identification in the usage records.
     * @param path
     * @private
     */


    usage._recordControlUrl = function _recordControlUrl(path) {
        if (path.length === 0 || path[0] === "") {
            return; // nothing to record. E.g. after a navigate back on a control content
        }

        Debug.Usage.Logging && console.log(DBG_NAME, "_control: " + path.join("/"));
        var ctrlUuid = path[0],
            ctrl;
        ctrl = ActiveMSComponent.getStructureManager().getControlByUUID(ctrlUuid);
        path[0] = ctrl.controlType;

        this._recordView(path.join("/"));
    };
    /**
     * Will record a value for an custom dimension if required.
     * @param dimId
     * @param value
     * @private
     */


    usage._customDimension = function _customDimension(dimId, value) {
        Debug.Usage.Logging && console.log(DBG_NAME, "*** custom dimension: " + _translateDim(dimId) + "=" + value);
        _doTrack() && _ga().addCustomDimension(dimId, value, function (succ) {
            Debug.Usage.Logging && console.log(DBG_NAME, _translateDim(dimId) + " " + succ);
        }, function (err) {
            console.error(DBG_NAME, "Dimension update failed! " + _translateDim(dimId) + "! ", err);
        });
    };
    /**
     * Will ensure that no uuids are in the path.
     * @param path  the original path that might contain uuids.
     * @returns {*} the path without any uuids in it.
     * @private
     */


    usage._removeUuids = function _removeUuids(path) {
        var text = path;

        try {
            var parts = path.split("/"),
                result = [],
                regex = Regex.UUID;
            parts.forEach(function (part) {
                if (!regex.test(part)) {
                    result.push(part);
                }
            });
            text = result.join("/");

            if (text.endsWith("/")) {
                text = text.substring(0, text.length - 1);
            }
        } catch (ex) {
        }

        return text;
    };
    /**
     * Will return true if GA is to be used for tracking.
     * @returns {boolean}
     * @private
     */


    var _doTrack = function _doTrack() {
        return false; // tracking is deactivated as of now.
        //return Debug.Usage.Remote && _ga();
    };
    /**
     * Will return a reference to google analytics for tracking.
     * @returns {ga}
     * @private
     */


    var _ga = function _ga() {
        return window.ga;
    };
    /**
     * Ensures that a boolean value (true/false) will be recorded as 0 for false and 1 for true.
     * @returns {number}
     * @private
     */


    var _bool = function _bool(val) {
        return val ? 1 : 0;
    };
    /**
     * Translates the dimension id into sth more user readable.
     * @param dimId
     * @returns {string}
     * @private
     */


    var _translateDim = function _translateDim(dimId) {
        var text = "-unknown-dimension-";
        Object.keys(UsageDimensions).some(function (dimKey) {
            if (UsageDimensions[dimKey] === dimId) {
                text = dimKey;
            }

            return text === dimKey;
        });
        return text;
    };

    return usage;
}();
