class ImgTagDownloader extends BaseDownloader {
    //region Static
    static TIMEOUT = 10; //endregion Static

    constructor() {
        super();
    }

    load(src) {
        var def = Q.defer(),
            timeout,
            imgElm = new Image();

        imgElm.onload = function () {
            clearTimeout(timeout);
            var canvas = document.createElement("canvas");
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(this, 0, 0); // After drawing the image we can create a blob from which we can extract an arrayBuffer

            canvas.toBlob(function (blob) {
                def.resolve(blob.arrayBuffer().then(function (aBuff) {
                    // Use the arrayBuffer with our method to get the base64String
                    return "data:image/png;base64," + arrayBufferToBase64String(aBuff);
                }));
            }.bind(this), "image/png");
        };

        imgElm.onerror = function (e) {
            clearTimeout(timeout);
            def.reject(e);
        };

        imgElm.crossOrigin = ""; // Use a cache buster to always request a new Image, or the Browser cache will return the cached one!
        // We use our own cache if available

        imgElm.src = src;
        timeout = setTimeout(function () {
            def.reject(new Error("Timeout: No response for " + this.constructor.TIMEOUT + " seconds..."));
        }.bind(this), this.constructor.TIMEOUT * 1000);
        Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.IMG_DL, src);
        return def.promise;
    }

}

window.ImgTagDownloader = ImgTagDownloader;
