'use strict';
/**
 * Created by loxone on 01.10.18.
 */

var ConnectivityTools = function (ConnectivityTools) {
    var LOCAL_TIMEOUT = 500,
        MAX_LOCAL_TIMEOUT = 2 * 1000,
        REMOTE_TIMEOUT = 2000,
        MAX_REMOTE_TIMEOUT = 5 * 1000,
        // in china on a poor connection, it took 4 seconds to respond, then the socket timed out
        LEGACY_START_CNTR = 8,
        // arbitrary. Not too soon and not too late.
        EXTEND_PROBES_CNTR = LEGACY_START_CNTR / 2;

    /**
     * The Reachability check will try to reach a Miniserver via HTTP requests. It will report via which host the
     * Miniserver can be reached. To do so, it will launch "probes" for each target. It can handle TLS, report alternative
     * serial numbers, ignore serial numbers & verify if a config version is okay for this UI.
     */

    class ReachabilityCheck {
        constructor() {
            this.name = "ReachabilityCheck";
            this._checkDeferred = null;
            this._localIp = null;
            this._remoteHost = null;
            this._targetSerialNr = null;
            this._tlsInfo = null;
            this._usesCloudDNS = false;
            this._serialsToIgnore = [];
            this._networkStatus = null;
            this._localProbe = null;
            this._safetyProbe = null;
            this._remoteProbe = null;
            this._localProbeHttps = null;
            this._safetyProbeHttps = null;
            this._remoteProbeHttps = null;
            this._alternativeLocalProbe = null;
            this._alternativeRemoteProbe = null;
            this._localLegacyProbe = null;
            this._remoteLegacyProbe = null;
            this._waitForLocalProbeAttemptsMap = {};
        }

        destroy() {
            this._destroyProbes();

            this._rejectPromise();
        }

        /**
         * Will re-launch the probes.
         * @param status
         */
        setNetworkStatus(status) {
            Debug.Connectivity && console.log(this.name, "setNetworkStatus: " + translateNWStatus(status));
            this._networkStatus = status;

            if (this._isCheckActive()) {
                Debug.Connectivity && console.log(this.name, "   active reach check, destroy and restart");

                this._destroyProbes();

                (this._localIp || this._remoteHost) && this._initializeProbes();
            } else {
                Debug.Connectivity && console.log(this.name, "   not checking reachability, nothing to do");
            }
        }

        getNetworkStatus() {
            return this._networkStatus;
        }

        detectReachability(local, remote, serial, tlsInfo, usesCloudDNS, certTld) {
            Debug.Connectivity && console.log(this.name, "detectReachability: L:'" + local + "' R:'" + remote + "' SNR:'" + serial + "' TLS:" + translateTlsInfo(tlsInfo) + " certTld:" + certTld);
            Debug.Connectivity && console.time(this.name);
            this._localIp = local;
            this._remoteHost = remote;
            this._targetSerialNr = cleanSerialNumber(serial); // ensure proper format.

            this._certTld = certTld || null;
            this._attemptCounterMap = {};
            this._attemptCounterMap[ReachMode.REMOTE] = 0;
            this._attemptCounterMap[ReachMode.LOCAL] = 0;
            this._usesCloudDNS = usesCloudDNS;
            this._tlsInfo = tlsInfo || {};

            if (usesCloudDNS && !serial) {
                console.error("Cannot use cloudDns without knowing the Miniservers serial number!");

                this._rejectPromise();

                return;
            }

            this._initializeProbes();

            return this._initializePromise();
        }

        /**
         * The serial numbers contained in this array will no longer be considered as valid alternatives.
         * @param snrsToIgnore
         */
        ignoreSerialNumbers(snrsToIgnore) {
            Debug.Connectivity && console.log(this.name, "ignoreSerialNumbers: " + JSON.stringify(snrsToIgnore));
            this._serialsToIgnore = snrsToIgnore || [];

            if (!this._isCheckActive()) {
                Debug.Connectivity && console.log("      reachability check no longer active, ignore");
                return;
            } // check is still running, verify if there is a confirmed probe with the updated serialsToIgnore


            if (this._evaluateProbeResponses()) {
                Debug.Connectivity && console.log("      with the updated info, a probe can already be considered successful!");
            }
        }

        /**
         * Used to start a detection for a new remote host. E.g. when the IP has been acquired via cloudDNS
         * @param remoteHost
         * @param remoteHostTLS
         * @param [dataCenter] may be provided when using remote connect and the .cn or other dataCenters are to be used.
         */
        setNewRemoteHost(remoteHost, remoteHostTLS, dataCenter) {
            Debug.Connectivity && console.log(this.name, "setNewRemoteHost: '" + remoteHost + "' - TLS -> '" + remoteHostTLS + "' - dataCenter: '" + dataCenter + "'");

            if (!this._isCheckActive()) {
                Debug.Connectivity && console.log("      reachability check no longer active, ignore");
                return;
            } // store remote host, otherwise a network change (destroy-/restartProbes) during an active check
            // will cause a loss of this information.


            this._remoteHost = remoteHostTLS || remoteHost;
            this._remoteProbe && this._remoteProbe.destroy();
            this._remoteProbeHttps && this._remoteProbeHttps.destroy(); // the cloudDns-Resolver reported a dataCenter, update the certificate TLD

            if (dataCenter) {
                this._certTld = getCertTldFromDataCenter(dataCenter);
            }

            this._remoteProbe = this._prepareProbe(remoteHost, REMOTE_TIMEOUT, MAX_REMOTE_TIMEOUT, ReachMode.REMOTE, false);

            this._launchProbe(this._remoteProbe);

            if (remoteHostTLS) {
                this._setHttpsAllowed(true);

                this._remoteProbeHttps = this._prepareProbe(remoteHostTLS, REMOTE_TIMEOUT, MAX_REMOTE_TIMEOUT, ReachMode.REMOTE, true);

                this._launchProbe(this._remoteProbeHttps);
            }
        }

        stopReachabilityDetection() {
            Debug.Connectivity && console.log(this.name, "stopReachabilityDetection");

            this._destroyProbes();

            if (this._checkDeferred != null) {
                Debug.Connectivity && console.log(this.name, "      promise still active, reject with OK (manual close)!");

                this._rejectPromise(ResponseCode.OK);
            }
        }

        // Private Methods

        /**
         * Prepares a set of probes that will determine the reachability of the possible targets.
         * @private
         */
        _initializeProbes() {
            Debug.Connectivity && console.log(this.name, "initializing probes");

            this._initializePlainProbes();

            this._initializeSafetyProbes();

            this._initializeTlsProbes();
        }

        _initializePlainProbes() {
            this._destroyPlainProbes(); // Create probes & store them


            this._localProbe = this._prepareProbe(this._localIp, LOCAL_TIMEOUT, MAX_LOCAL_TIMEOUT, ReachMode.LOCAL, false);
            this._remoteProbe = this._prepareProbe(this._remoteHost, REMOTE_TIMEOUT, MAX_REMOTE_TIMEOUT, ReachMode.REMOTE, false); // start all probes at once

            this._launchProbe(this._localProbe);

            this._launchProbe(this._remoteProbe);
        }

        _initializeSafetyProbes() {
            this._destroySafetyProbes(); // Create probes & store them


            this._safetyProbe = this._prepareProbe(this._localIp, LOCAL_TIMEOUT, MAX_LOCAL_TIMEOUT, ReachMode.LOCAL, false, true);
            this._safetyProbeHttps = this._prepareProbe(this._localIp, LOCAL_TIMEOUT, MAX_LOCAL_TIMEOUT, ReachMode.LOCAL, true, true);
        }

        _initializeTlsProbes() {
            this._destroyTlsProbes(); // Create probes & store them


            this._localProbeHttps = this._prepareProbe(this._localIp, LOCAL_TIMEOUT, MAX_LOCAL_TIMEOUT, ReachMode.LOCAL, true);
            this._remoteProbeHttps = this._prepareProbe(this._remoteHost, REMOTE_TIMEOUT, MAX_REMOTE_TIMEOUT, ReachMode.REMOTE, true); // start all probes at once

            this._launchProbe(this._localProbeHttps);

            this._launchProbe(this._remoteProbeHttps);
        }

        /**
         * Prepares a local and a remote legacy probe, these probes will resolve if a Miniserver responds to jdev/cfg/mac
         * --> no use starting legacy probes for firmwares <V6 if this target might even be able of TLS
         * @private
         */
        _initializeLegacyProbes() {
            this._destroyLegacyProbes();

            var local,
                remote,
                initialConnection = !this._targetSerialNr; // initially no serial is available, meaning HTTPs is allowed & would rule out a legacy check..

            if (this._localIp && this._localIp !== "" && (!this._allowHttps(ReachMode.LOCAL) || initialConnection)) {
                local = this._getPreparedTarget(this._localIp, false, ReachMode.LOCAL);
                this._localLegacyProbe = new ConnectivityTools.LegacyProbe(local, LOCAL_TIMEOUT, MAX_LOCAL_TIMEOUT, ReachMode.LOCAL, false);
            }

            if (this._remoteProbe && this._remoteProbe !== "" && (!this._allowHttps(ReachMode.REMOTE) || initialConnection)) {
                remote = this._getPreparedTarget(this._remoteHost, false, ReachMode.REMOTE);
                this._remoteLegacyProbe = new ConnectivityTools.LegacyProbe(remote, REMOTE_TIMEOUT, MAX_REMOTE_TIMEOUT, ReachMode.REMOTE, false);
            } // launch them right away


            this._launchProbe(this._localLegacyProbe);

            this._launchProbe(this._remoteLegacyProbe);
        }

        _launchProbe(probe) {
            probe && probe.launch().then(this._handleProbeSuccess.bind(this), this._handleProbeError.bind(this), this._handleProbeRetry.bind(this));
        }

        /**
         * Creates a new probe with the provided arguments (if the provided target is valid) & stores it into probes;
         * @param target
         * @param timeout
         * @param maxTimeout
         * @param reachMode
         * @param useTLS
         * @param isSafety
         * @return {*}          the probe that has just been created
         * @private
         */
        _prepareProbe(target, timeout, maxTimeout, reachMode, useTLS, isSafety) {
            var probe,
                adoptedTarget,
                allowed = useTLS ? this._allowHttps(reachMode) : this._allowHttp(reachMode);
            Debug.Connectivity && console.log(this.name, "_prepareProbe: allowed: " + allowed + ", target: " + target + ", reachMode: " + reachMode + ", tlsInfo: " + translateTlsInfo(this._tlsInfo)); // only return a probe if the target is okay and if it's a HTTPs probe, only launch if it should be tried.

            if (target && target !== "" && allowed) {
                adoptedTarget = this._getPreparedTarget(target, useTLS, reachMode);

                if (isSafety) {
                    probe = new ConnectivityTools.SafetyProbe(adoptedTarget, timeout, maxTimeout, reachMode, useTLS, this._serialsToIgnore);
                } else {
                    probe = new ConnectivityTools.ReachabilityProbe(adoptedTarget, timeout, maxTimeout, reachMode, useTLS, this._serialsToIgnore);
                }
            }

            return probe;
        }

        _destroyProbes() {
            Debug.Connectivity && console.log(this.name, "_destroyProbes: allowed");

            this._destroyPlainProbes();

            this._destroyTlsProbes();

            this._destroyLegacyProbes();

            this._destroyAlternativeProbes();

            this._destroySafetyProbes();
        }

        _destroyPlainProbes() {
            this._destroySpecificProbes(['_localProbe', '_remoteProbe']);
        }

        _destroyTlsProbes() {
            this._destroySpecificProbes(['_localProbeHttps', '_remoteProbeHttps']);
        }

        _destroyLegacyProbes() {
            this._destroySpecificProbes(['_localLegacyProbe', '_remoteLegacyProbe']);
        }

        _destroyAlternativeProbes() {
            this._destroySpecificProbes(['_alternativeLocalProbe', '_alternativeRemoteProbe']);
        }

        _destroySafetyProbes() {
            this._destroySpecificProbes(['_safetyProbe', '_safetyProbeHttps']);
        }

        /**
         * Handles destruction of given probes
         * @note Takes care of removing the probe from the this._waitForLocalProbeAttemptsMap
         * @param propertyNames
         * @private
         */
        _destroySpecificProbes(propertyNames) {
            var probe;
            propertyNames.forEach(function (propertyName) {
                probe = this[propertyName];

                if (probe) {
                    // Clear this._waitForLocalProbeAttemptsMap when we destroy a specific probe
                    delete this._waitForLocalProbeAttemptsMap[probe.name];
                    Object.keys(this._waitForLocalProbeAttemptsMap).forEach(function (probeName) {
                        delete this._waitForLocalProbeAttemptsMap[probeName][probe.name];
                    }.bind(this));
                    this[propertyName].destroy();
                    this[propertyName] = null;
                }
            }.bind(this));
        }

        _getPreparedTarget(target, useTLS, reachMode) {
            var prepared = removeProtocol(target),
                parts,
                port,
                isLocal = reachMode === ReachMode.LOCAL; // when using clouddns & encryption, the target needs to be adopted.

            if (useTLS && (this._usesCloudDNS || isLocal) && this._targetSerialNr) {
                parts = splitHostAndPort(target);
                parts.host = parts.host.replace(/[.|:]/g, "-"); // the "." is for IPv4 and the ":" is for IPv6

                parts.host = parts.host.replace(/[\[\]]/g, ""); // This will only match on IPv6, there is a "[" at the start and a "]" at the end of an IPv6 address

                port = parts.port || SSL_DEFAULT_PORT;

                if (isLocal) {
                    // The SSL Port is fixed to port 443, so force to use the default port to ensure the correct port
                    port = SSL_DEFAULT_PORT;
                }

                prepared = sprintf(getDynDnsSslHost(this._certTld || getCertTldFromDataCenter(ActiveMSComponent.getDataCenter())), parts.host, this._targetSerialNr, port);
            }

            return setProtocol(prepared, useTLS ? "https" : "http");
        }

        /**
         * Will return true if a reachability check is currently up and running.
         * @return {boolean}
         * @private
         */
        _isCheckActive() {
            return this._checkDeferred !== null;
        }

        _allowHttps(reachMode) {
            return this._tlsInfo.hasOwnProperty(reachMode) ? this._tlsInfo[reachMode] !== TlsState.UNSUPPORTED : true;
        }

        _allowHttp(reachMode) {
            // The Webinterface can be directly opened via HTTPS, thus an HTTP connection is not allowed per browser default!
            var platform = PlatformComponent.getPlatformInfoObj().platform;

            if (platform === PlatformType.Webinterface || platform === PlatformType.DeveloperInterface) {
                if (CommunicationComponent.getRequestProtocol().startsWith("https")) {
                    return false;
                }
            }

            return this._tlsInfo.hasOwnProperty(reachMode) ? this._tlsInfo[reachMode] !== TlsState.PROVEN : true;
        }

        /**
         * Will enable probing for both TLS and plain. Required e.g. if TLS probes fail too often
         * or a new TLS remote host is detected
         * @private
         */
        _setHttpsAllowed(forceRemote) {
            this._tlsInfo = this._tlsInfo || {};
            Object.keys(this._tlsInfo).forEach(function (reachMode) {
                // Only set the TLS state if it known to be not possible or unknown
                if (this._tlsInfo[reachMode] > TlsState.POSSIBLE) {
                    this._tlsInfo[reachMode] = TlsState.POSSIBLE;
                }
            }.bind(this));

            if (forceRemote) {
                this._tlsInfo[ReachMode.REMOTE] = TlsState.POSSIBLE;
            }
        }

        // Promise Handling
        _initializePromise() {
            this._checkDeferred = Q.defer();
            return this._checkDeferred.promise;
        }

        _notifyPromise(info) {
            var data = info || {};
            data.retryCount = this._attemptCounterMap[info.reachMode];
            this._checkDeferred && this._checkDeferred.notify(data);
        }

        _resolvePromise(probe) {
            var probeInfo = probe.getInfo(),
                cleanHost = probeInfo.target.replace("https://", "").replace("http://", ""),
                // useTLS sets the flag
                arg = {
                    reachMode: probeInfo.reachMode,
                    host: cleanHost,
                    useTLS: probeInfo.useTLS,
                    version: probeInfo.data.version,
                    serialNo: probeInfo.data.serialNo,
                    isInTrust: probeInfo.data.isInTrust,
                    certTld: probeInfo.data.certTld
                };
            Debug.Connectivity && console.log(this.name, " confirmed: " + arg.host + " (SNR=" + arg.serialNo + " V=" + arg.version + (probeInfo.useTLS ? " TLS" : "") + (probeInfo.certTld ? " " + arg.certTld : "") + ")");
            Debug.Connectivity && console.timeEnd(this.name);
            this._checkDeferred && this._checkDeferred.resolve(arg);
            this._checkDeferred = null;
            this.stopReachabilityDetection();
        }

        _rejectPromise(responseCode) {
            Debug.Connectivity && console.timeEnd(this.name, " not reachable");
            var errorObject = {
                code: responseCode || ResponseCode.NOT_FOUND
            };
            this._checkDeferred && this._checkDeferred.reject(errorObject);
            this._checkDeferred = null;
        }

        // PROBE Feedback!
        _handleProbeSuccess(probe) {
            if (this._isKnownProbe(probe)) {
                this._evaluateProbeResponses();
            } else {
                Debug.ReachabilityProbe && console.error("A probe success has been received of a probe that isn't known: " + JSON.stringify(probe.getInfo()));
            }
        }

        _handleProbeError(probe) {
            if (this._isKnownProbe(probe)) {
                this._evaluateProbeResponses();
            } else {
                Debug.ReachabilityProbe && console.error("A probe error has been received of a probe that isn't known: " + JSON.stringify(probe.getInfo()));
            }
        }

        _handleProbeRetry(probe) {
            var info = probe.getInfo();

            if (!this._isKnownProbe(probe)) {
                Debug.ReachabilityProbe && console.error("A probe retry has been received of a probe that isn't known: " + JSON.stringify(probe.getInfo()));
                return;
            }

            if (this._evaluateProbeResponses()) {// success, nothing to do!
            } else {
                if (info.data === ResponseCode.MS_OUT_OF_SERVICE) {
                    info.code = ResponseCode.MS_OUT_OF_SERVICE;

                    this._notifyPromise(info); // skip two, ensures out of service stays there longer.


                    this._attemptCounterMap[ReachMode.LOCAL] += 2;
                    this._attemptCounterMap[ReachMode.REMOTE] += 2;
                }

                if (info.data === ResponseCode.NO_MORE_EVENT_SLOTS) {
                    info.code = ResponseCode.NO_MORE_EVENT_SLOTS;

                    this._notifyPromise(info); // skip two, ensures out of service stays there longer.


                    this._attemptCounterMap[ReachMode.LOCAL] += 2;
                    this._attemptCounterMap[ReachMode.REMOTE] += 2;
                } else if (info.attempts > this._attemptCounterMap[info.reachMode]) {
                    // ensure to only notify if the attemptCounter for that reachmode advanced. Use two separate counters
                    // as otherwise the Remote probes will never notify as they are started at a later time and are slower.
                    this._attemptCounterMap[info.reachMode] = info.attempts;

                    this._notifyPromise(info);
                } // if only HTTPs has been attempted, maybe HTTP gets the job done?


                if (this._shouldStartPlainCheck()) {
                    this._setHttpsAllowed();

                    this._initializePlainProbes();
                } // if reachability has failed so far, check if the target might be a really old Miniserver < V6.0?


                if (this._shouldStartLegacyCheck()) {
                    this._initializeLegacyProbes();
                }
            }
        }

        /**
         * Checks all probes & returns true if one was successful.
         * @return {boolean}
         * @private
         */
        _evaluateProbeResponses() {
            Debug.Connectivity && console.log(this.name, "_evaluateProbeResponses");
            var success = true;

            if (this._isProbeSuccessful(this._localProbeHttps)) {
                this._resolvePromise(this._localProbeHttps);
            } else if (this._isProbeSuccessful(this._localProbe, [this._localProbeHttps])) {
                // if a local HTTPS probe exists, check if it did use the proper TLD, or if it needs to be adotped
                if (this._localProbe.getInfo().data.certTld && this._localProbeHttps && !this._localProbeHttps.hasTld(this._localProbe.getInfo().data.certTld)) {
                    console.warn(this.name, "The local https probe needs to be relaunched with an adopted TLD (" + this._localProbe.getInfo().data.certTld + ") + serialNo (" + this._localProbe.getInfo().data.serialNo + ")");
                    this._certTld = this._localProbe.getInfo().data.certTld;
                    this._targetSerialNr = cleanSerialNumber(this._localProbe.getInfo().data.serialNo);

                    this._initializeTlsProbes();
                } else {
                    this._resolvePromise(this._localProbe);
                }
            } else if (this._isProbeSuccessful(this._safetyProbeHttps)) {
                this._resolvePromise(this._safetyProbeHttps);
            } else if (this._isProbeSuccessful(this._safetyProbe, [this._safetyProbeHttps, this._localProbeHttps])) {
                this._resolvePromise(this._safetyProbe);
            } else if (this._isProbeSuccessful(this._remoteProbeHttps, [this._localProbeHttps, this._localProbe, this._safetyProbe, this._safetyProbeHttps])) {
                if (this._needsToLaunchSafetyProbes()) {
                    this._launchSafetyProbes(); // ensures that we don't connect remote when there could be a local conn.

                } else {
                    this._resolvePromise(this._remoteProbeHttps);
                }
            } else if (this._isProbeSuccessful(this._remoteProbe, [this._localProbe, this._localProbeHttps, this._remoteProbeHttps, this._safetyProbe, this._safetyProbeHttps])) {
                if (this._needsToLaunchSafetyProbes()) {
                    this._launchSafetyProbes(); // ensures that we don't connect remote when there could be a local conn.

                } else {
                    this._resolvePromise(this._remoteProbe);
                }
            } else if (this._isAlternativeAvailable(this._alternativeLocalProbe)) {
                this._notifyPromise({
                    code: ResponseCode.SERIAL_NO_CHANGED,
                    alternative: this._alternativeLocalProbe.getInfo()
                });

                success = false;
            } else if (this._isAlternativeAvailable(this._alternativeRemoteProbe)) {
                this._notifyPromise({
                    code: ResponseCode.SERIAL_NO_CHANGED,
                    alternative: this._alternativeRemoteProbe.getInfo()
                });

                success = false;
            } else if (this._allProbesFailed()) {
                success = false;

                this._rejectPromise();
            } else if (this._isLegacyTargetAvailable(this._localLegacyProbe, this._remoteLegacyProbe)) {
                this._rejectPromise(ResponseCode.MS_UPDATE_REQUIRED);

                success = false;
            } else {
                success = false;
            }

            return success;
        }

        /**
         * Checkes if the safety probes have been started yet. They are prepared initially and only launched if a remote
         * check succeeds and we should prefer a local connection.
         * @returns {boolean}
         * @private
         */
        _needsToLaunchSafetyProbes() {
            var needsToStart = false;
            needsToStart |= this._safetyProbe && this._safetyProbe.isReady();
            needsToStart |= this._safetyProbeHttps && this._safetyProbeHttps.isReady();
            return needsToStart && this._preferLocal();
        }

        _launchSafetyProbes() {
            Debug.Connectivity && console.log(this.name, "_launchSafetyProbes");

            this._launchProbe(this._safetyProbe);

            this._launchProbe(this._safetyProbeHttps); // Stops the local retries, so that no more request are started


            this._localProbe && this._localProbe.stopRetryTimeout();
            this._localProbeHttps && this._localProbeHttps.stopRetryTimeout();
        }

        /**
         * Will return true if this probe is successful and all other probes provided have failed or are retrying.
         * It will also check if the serial number matches, if not told to otherwise.
         * @param probe             the probe that is to be checked
         * @param waitForProbes     the other probes we need to wait for.
         * @param ignoreSerial      if true, there will be no serial check.
         * @return {boolean}
         * @private
         */
        _isProbeSuccessful(probe, waitForProbes, ignoreSerial) {
            var success = probe ? probe.isSuccessful() : false;
            Debug.Connectivity && success && console.log(this.name, "_isProbeSuccessful --> '" + probe.name + "' " + success); // if a target serial number is known, use it to verify that it's the right Miniserver

            if (success && !ignoreSerial) {
                success = this._checkSerial(probe);
            } // if another probe is waiting for a first response, wait for it.


            success && waitForProbes && waitForProbes.forEach(function (prioProbe) {
                // not all probes exist at all times - beware when checking the waitForProbes array
                if (prioProbe && prioProbe.isPending()) {
                    Debug.Connectivity && console.log(this.name, "       - " + prioProbe.name + " is still pending, wait");
                    success = false;
                }
            }.bind(this));
            return success;
        }

        /**
         * Indicates true if all launched probes have failed.
         * @return {boolean}
         * @private
         */
        _allProbesFailed() {
            var allFailed = true;
            allFailed = allFailed && (!this._localProbe || this._localProbe.didFail());
            allFailed = allFailed && (!this._localProbeHttps || this._localProbeHttps.didFail());
            allFailed = allFailed && (!this._remoteProbe || this._remoteProbe.didFail());
            allFailed = allFailed && (!this._remoteProbeHttps || this._remoteProbeHttps.didFail());
            return allFailed;
        }

        /**
         * Will check if the probe provided did return a serial number that  matches the one we're looking for.
         * If not, it will store this probe as possible alternative. If no serial is known, it will return true.
         * @param probe
         * @private
         */
        _checkSerial(probe) {
            var returnedSerial,
                success = probe !== null,
                probeInfo;

            if (success && this._targetSerialNr) {
                probeInfo = probe.getInfo();
                returnedSerial = probeInfo.data.serialNo;
                success = this._targetSerialNr.localeCompare(returnedSerial) === 0; // should this probe be considered as alternative?

                if (!success && !this._shouldIgnoreProbe(probe)) {
                    if (probeInfo.reachMode === ReachMode.LOCAL) {
                        this._alternativeLocalProbe = probe;
                    } else if (probeInfo.reachMode === ReachMode.REMOTE) {
                        this._alternativeRemoteProbe = probe;
                    }
                }
            }

            return success;
        }

        /**
         * When all probes have failed, but one did return a different serial - this will return true.
         * @param probe         the probe that returned a valid serial.
         * @return {*|boolean}
         * @private
         */
        _isAlternativeAvailable(probe) {
            var otherProbes,
                success = probe !== null;

            if (success) {
                otherProbes = [];
                otherProbes.pushObject(this._localProbe);
                otherProbes.pushObject(this._localProbeHttps);
                otherProbes.pushObject(this._remoteProbe);
                otherProbes.pushObject(this._remoteProbeHttps);
                success = this._isProbeSuccessful(probe, otherProbes, true); // ensure this probe isn't on the ignore list.

                if (success) {
                    success = !this._shouldIgnoreProbe(probe);
                }
            }

            return success;
        }

        /**
         * Checks if the probes serial number is on our ignore list.
         * @param probe
         * @return {boolean}
         * @private
         */
        _shouldIgnoreProbe(probe) {
            var serial = probe !== null ? probe.getInfo().data.serialNo : "-",
                ignore = false;

            if (serial && this._serialsToIgnore.includes(serial)) {
                ignore = true;
                Debug.Connectivity && console.log(this.name, "_shouldIgnoreProbe: '" + serial + "': " + ignore);
            }

            return ignore;
        }

        /**
         * Returns true if a legacy reachability check is to be triggerd.
         * @return {boolean}
         * @private
         */
        _shouldStartLegacyCheck() {
            var shouldStart = !this._localLegacyProbe && !this._remoteLegacyProbe;

            if (shouldStart) {
                // if a target serial number is known, it has been reachable already.
                shouldStart = this._attemptCounterMap[ReachMode.LOCAL] === LEGACY_START_CNTR && !this._targetSerialNr;
            }

            return shouldStart;
        }

        /**
         * After 4 unsuccessful attempts via TLS, it's probably a good idea to revert to plain.
         * @return {boolean}
         * @private
         */
        _shouldStartPlainCheck() {
            var shouldStart = !this._localProbe && !this._remoteProbe;

            if (shouldStart) {
                shouldStart = this._attemptCounterMap[ReachMode.LOCAL] === EXTEND_PROBES_CNTR;
            }

            return shouldStart;
        }

        /**
         * Returns true if all the other probes are no longer pending and one of the legacy probes is successful.
         * @param local     the local legacy probe
         * @param remote    the remote legacy probe
         * @return {boolean}
         * @private
         */
        _isLegacyTargetAvailable(local, remote) {
            var otherProbes,
                success = local !== null || remote !== null;

            if (success) {
                otherProbes = [];
                otherProbes.pushObject(this._localProbe);
                otherProbes.pushObject(this._localProbeHttps);
                otherProbes.pushObject(this._remoteProbe);
                otherProbes.pushObject(this._remoteProbeHttps);
                otherProbes.pushObject(this._alternativeLocalProbe);
                otherProbes.pushObject(this._alternativeRemoteProbe);
                success = local && this._isProbeSuccessful(local, otherProbes);

                if (!success && remote) {
                    otherProbes.pushObject(local);
                    success = this._isProbeSuccessful(remote, otherProbes);
                }
            }

            return success;
        }

        /**
         * This method ensures only known probes are handled. A probe might succeed and report its error while another
         * probe is waiting to report a retry or an error.
         * @param probe
         * @return {*}
         * @private
         */
        _isKnownProbe(probe) {
            var known;

            switch (probe) {
                case this._localProbe:
                case this._safetyProbe:
                case this._remoteProbe:
                case this._localProbeHttps:
                case this._safetyProbeHttps:
                case this._remoteProbeHttps:
                case this._localLegacyProbe:
                case this._remoteLegacyProbe:
                case this._alternativeLocalProbe:
                case this._alternativeRemoteProbe:
                    known = true;
                    break;

                default:
                    known = false;
                    break;
            }

            return known;
        }

        /**
         * If this returns true, the reachability check will not return a remote success unless the local one has failed
         * two times.
         * @return {boolean}
         * @private
         */
        _preferLocal() {
            return this._networkStatus === NetworkStatus.LAN;
        }

    }

    ConnectivityTools.ReachabilityCheck = ReachabilityCheck;
    return ConnectivityTools;
}(ConnectivityTools || {});
