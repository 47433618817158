'use strict';

var LxWebWorker = function () {
    // webkit handling
    var URL = window.URL || window.webkitURL;

    /**
     * ctor for LxWebWorker
     * @param {[]} [dependencies] optional
     * @returns {{}} LxWebWorker API
     * @constructor
     */

    function LxWebWorker(dependencies) {
        Debug.Worker.Basic && console.info("LxWebWorker requested");

        if (window.workerAvailable) {
            this.initWebWorker();
            this.addDependencies(dependencies);
        } // only return API


        return {
            addDependencies: this.addDependencies.bind(this),
            evalFunction: (window.workerAvailable ? this.evalFunction : this.fakeEvalFunction).bind(this) // bind to have the correct this object!

        };
    }

    /**
     * initializes the Worker
     */


    LxWebWorker.prototype.initWebWorker = function initWebWorker() {
        Debug.Worker.Basic && console.info("initWebWorker");
        this.workerReady = true;
        this.cache = {};
        this.currentWork = null;
        this.workQueue = []; // create blob of function

        var blob = new Blob([getFunctionBody(genericWebWorker)], {
            type: 'text/javascript'
        });
        Debug.Worker.Basic && console.log("created new Blob"); // create the actual WebWorker

        this.worker = new Worker(URL.createObjectURL(blob));
        Debug.Worker.Basic && console.log("created new Worker");
        /**
         * onmessage callback from native Worker
         * @param msg result or error
         */

        this.worker.onmessage = function onMessage(msg) {
            if (msg.data === "ready") {
                // we got the info to start with work!
                Debug.Worker.Basic && console.info("LxWebWorker is ready");
                this.workerReady = true;
            } else if (msg.data && msg.data.error) {
                // error handling
                this.currentWork.defer.reject(msg.data.error);
            } else {
                // everything went fine
                this.currentWork.defer.resolve(msg.data);
            }

            this.processNextWork();
        }.bind(this);
        /**
         * onerror callback from native Worker
         * @param e error
         */


        this.worker.onerror = function onError(e) {
            // TODO check if Worker works from now on..?!
            console.error(e);
            console.warn("check if Worker works from now on!");
            this.currentWork.defer.reject(e);
            this.processNextWork();
        }.bind(this);
    };
    /**
     * adds dependencies for the Worker
     * @param {[]} [dependencies] optional
     */


    LxWebWorker.prototype.addDependencies = function addDependencies(dependencies = []) {
        try {
            dependencies.forEach(dep => {
                if (typeof dep === "string") {
                    //debugger;
                    this.checkFile(dep);
                } else if (typeof dep === "function") {
                    this.checkFunction(dep);
                } else {
                    // not supported!
                    console.warn(typeof dependencies[i] + " dependency is not supported by LxWebWorker yet - implement if needed!");
                }
            });
        } catch (e) {
            console.error(e.stack);
        }
    };
    /**
     * checks whether a function is already loaded to the Worker
     * @param {function} fn
     */


    LxWebWorker.prototype.checkFunction = function checkFunction(fn) {
        if (!this.cache[fn.name]) {
            // this is the first time, we want to process this function
            // we have to tell the Worker to load the javascript file (Blob)
            Debug.Worker.Basic && console.log("LxWebWorker: add function dependency");
            var functionString = `self.functions = self.functions || {};\nself.functions["${fn.name}"] = ${fn};`;
            var blob = new Blob([functionString], {
                type: 'text/javascript'
            });
            var codeURL = URL.createObjectURL(blob);
            this.cache[fn.name] = codeURL; // tell the Worker to import the needed script

            this.worker.postMessage({
                type: "importScript",
                data: codeURL,
                args: fn.name
            });
        }
    };
    /**
     * checks whether a file is already loaded to the Worker
     * @param {string} fileURL
     */


    LxWebWorker.prototype.checkFile = function checkFile(fileURL) {
        if (!this.cache[fileURL]) {
            // this is the first time, we want to load this file
            // we have to tell the Worker to load the javascript file
            Debug.Worker.Basic && console.log("LxWebWorker: add file dependency");
            this.cache[fileURL] = "file loaded at " + new Date().toString(); // tell the Worker to import the needed script
            if (fileURL.endsWith("statistic.js")) {
                debugger;
            }
            this.worker.postMessage({
                type: "importScript",
                data: fileURL,
                args: fileURL
            });
        }
    };
    /**
     * calls the function in Worker-Thread or add's it to the queue
     * @param {function|string} fn function or string to execute
     * @param {[]} args check out 'https://developer.mozilla.org/en-US/docs/Web/Guide/API/DOM/The_structured_clone_algorithm' for supported types!
     * @param {[]} [dependencies] optional array of functions for the function to be executed properly
     * @returns {promise}
     */


    LxWebWorker.prototype.evalFunction = function evalFunction(fn, args, dependencies) {
        var def = Q.defer();

        try {
            // check dependencies first
            this.addDependencies(dependencies);

            if (typeof fn === "function") {
                // function is also a kind of dependency!
                this.checkFunction(fn);
            }
        } catch (e) {
            def.reject(e);
        } // make sure that args is always an array!


        if (!(args instanceof Array)) args = [args];
        this.workQueue.push({
            defer: def,
            fnName: fn.name || fn,
            args: args
        });
        this.processQueue();
        return def.promise.fail((err) => {
            console.error("LxWebWorker: failed on webworker, retry on main thread to get detailed error info! ", err);
            developerAttention("A common issue is that the executed function is not self contained and has external dependencies like 'cloneObject' or some enums.");
            return this.fakeEvalFunction(fn, args, dependencies);
        });
    };
    /**
     * fake eval function if no Workers are available
     * calls the function synchronously, but is async due to promises!
     * @param {function|string} fn function or string to execute
     * @param {[]} args
     * @returns {promise}
     */


    LxWebWorker.prototype.fakeEvalFunction = function fakeEvalFunction(fn, args) {
        Debug.Worker.Basic && console.log("LxWebWorker: fakeEvalFunction");
        var def = Q.defer();

        try {
            if (typeof fn === "string") {
                fn = window[fn];
            }

            var result = fn.apply(null, args);
            def.resolve(result);
        } catch (e) {
            console.error(e);
            def.reject(e);
        }

        return def.promise;
    };
    /**
     * processing the next work if idle
     */


    LxWebWorker.prototype.processQueue = function processQueue() {
        Debug.Worker.Basic && console.log("LxWebWorker: processQueue");

        if (!this.currentWork && this.workerReady) {
            // process next request
            this.currentWork = this.workQueue.shift()
            this.currentWork && this.worker.postMessage({
                type: "evalScript",
                data: this.currentWork.fnName,
                args: this.currentWork.args
            });
        } else if (!this.workerReady) {
            Debug.Worker.Basic && console.info("waiting for LxWebWorker");
        }
    };
    /**
     * processes the next work
     */


    LxWebWorker.prototype.processNextWork = function processNextWork() {
        this.currentWork = null;
        this.processQueue();
    };
    /**
     * this is the actual Worker!
     * listens for messages and either loads a script or evaluates something
     */


    var genericWebWorker = function genericWebWorker() {
        //console.log("init native Worker");
        // ATTENTION: console not available in Android 4.4.2!!
        var console = {
            log: function () {
            },
            info: function () {
            },
            error: function (e) {//self.postMessage({error: "Webworker error " + e});
            },
            dir: function () {
            },
            warn: function () {
            },
            history: function () {
            },
            debug: function () {
            },
            time: function () {
            },
            timeEnd: function () {
            },
            group: function () {
            },
            groupEnd: function () {
            }
        };
        var functions = {}; // each function will be added to this object

        self.addEventListener('message', onMessage);

        function onMessage(msg) {
            //console.log("worker got message");
            switch (msg.data.type) {
                case "importScript":
                    try {
                        importScripts(msg.data.data);
                    } catch (e) {
                        console.error(e);
                        self.postMessage({
                            error: "Webworker did fail importing script " + msg.data.data
                        });
                    }

                    break;

                case "evalScript":
                    try {
                        var res = self.functions[msg.data.data].apply(null, msg.data.args);
                        self.postMessage(res);
                    } catch (e) {
                        console.error(e);
                        self.postMessage({
                            error: "Webworker did fail evaluating script " + msg.data.data
                        });
                    }

                    break;

                default:
                    //console.warn("LxWebWorker don't know what to do with", msg.data.type);
                    break;
            }
        }

        self.postMessage("ready");
    };

    return LxWebWorker;
}();
