class DLSocketDownloader extends BaseDownloader {

    load(src) {
        return CommunicationComponent.download(src, EncryptionType.NONE).then(res => {
            if (typeof res === "string") {
                return res;
            } else if (res instanceof ArrayBuffer) {
                return asyncArrayBufferToBase64String(res).then(b64 => {
                    let comps = src.split("."),
                        suffix = comps[comps.length - 1];
                    return `data:image/${suffix};base64,${b64}`;
                });
            }
        })
    }

};

window.DLSocketDownloader = DLSocketDownloader;
