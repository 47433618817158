'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        // required as there is the update types needed to be adopted to avoid issues with legacy apps (143956584)
        var PlatformUpdateId = {};
        var APP_UPDATE_LVL_ID = "AppUpdateLevel";
        var CHANGELOGS = {
            ALPHA: "https://docs.google.com/document/d/1VMEuAB3_d1lGaYEis9KPS2qBbAgb62tAZsojlJFB3SY/edit",
            BETA: "https://www.loxone.com/dede/infos/publicbeta/",
            RELEASE: "https://www.loxone.com/enen/products/apps/"
        };

        class AppUpdateExt extends Components.Update.extensions.PlatformUpdateBaseExt {
            constructor(component, extensionChannel) {
                super(...arguments);

                this._preparePlatformUpdateIds();
            }

            // ---------- METHODS TO OVERRIDE -----------------

            /**
             * Returns the update file ID of the current platform
             * @param [platform]
             * @return {string}
             */
            getPlatformId(platform = PlatformComponent.getPlatformInfoObj().platform) {
                // We do have to take the different architectures linux in account!
                // Note: We may not be in an electron environment, check if the process variable exists!
                if (platform === PlatformType.Linux && "electron" in window) {
                    platform = platform + "-" + electron.remote.arch;
                    if (electron.remote.isAppImage) {
                        platform += "-appImage"
                    }
                } // This is the new identifier to also use the minOS version


                platform += "-v2";
                return platform.toLowerCase();
            }

            /**
             * Gets the appropriated StoreLink for the given platform and download source (MacAppStore, TestFlight, Microsoft Store, ...)
             * @returns {string}
             */
            getStoreLink() {
                let platformId = this.getPlatformId(),
                    storeLink;

                switch (platformId) {
                    case PlatformUpdateId.Mac:
                    case PlatformUpdateId.Windows:
                        if ("electron" in window) {
                            if (electron.remote.testFlight) {
                                storeLink = "itms-beta://beta.itunes.apple.com/v1/app/1159613409";
                            } else if (electron.remote.mas) {
                                storeLink = "itms-apps://apps.apple.com/us/app/loxone/id1159613409";
                            } else if (electron.remote.windowsStore) {
                                storeLink = "ms-windows-store://pdp/?productid=9PL9LVQGKKFR";
                            }
                        }

                        break;

                    case PlatformUpdateId.Android:
                        if (PlatformComponent.getPlatformInfoObj().manufacturer === "Amazon") {
                            // Amazon Devices don't have a playStore, but the amazon store!
                            storeLink = "amzn://apps/android?p=com.loxone.kerberos";
                        } else {
                            storeLink = "market://details?id=com.loxone.kerberos";
                        }

                        break;

                    case PlatformUpdateId.IOS:
                        if (this._isTestVersion()) {
                            storeLink = "itms-beta://beta.itunes.apple.com/v1/app/924399400";
                        } else {
                            storeLink = "itms-apps://apps.apple.com/us/app/loxone/id924399400";
                        }

                        break;

                    default:
                        break;
                }

                return storeLink;
            }

            /**
             * Returns the update level of the current platform
             * Note: The update level will be saved if it is a none release update level
             *       This enables our users to upgrade to release software but still be able to receive Alpha or Beta builds
             * @return {string}
             */
            getUpdateLevel() {
                var currentUpdateLevel = window.UPDATE_LEVEL,
                    previousUpdateLevel = PersistenceComponent.getLocalStorageItem(APP_UPDATE_LVL_ID),
                    updateLevel = this.getHighestUpdateLevel(currentUpdateLevel, previousUpdateLevel); // Only save the update level if it is not yet saved

                if (updateLevel !== previousUpdateLevel) {
                    PersistenceComponent.setLocalStorageItem(APP_UPDATE_LVL_ID, updateLevel);
                }

                Debug.Update.AppUpdate && console.log(this.name, "getUpdateLevel: " + updateLevel);
                return updateLevel;
            }

            /**
             * Returns the platforms current version
             * @return {string}
             */
            getCurrentVersion() {
                return PlatformComponent.getAppInfoObj().appVersion;
            }

            /**
             * Returns the message to show in the popup and in the update notification
             * @param newVersion    the version of the available update
             * @param currVersion
             * @param overdue       if true, the update is overdue already - should result in a firm message.
             * @return {string}
             */
            getMessage(newVersion, currVersion, overdue) {
                return _("update.available.app.text", {
                    version: newVersion
                }) + "<br>" + _("update.current-version", {
                    version: currVersion
                });
            }

            /**
             * Returns the title to show in the popup and in the update notification
             * @param overdue       if true, the update is overdue already - should result in a firm title
             * @return {string}
             */
            getTitle(overdue) {
                return _("update.app-available.title");
            }

            // ---------- METHODS that may be overridden -----------------

            /**
             * Returns the update info part for this platform with the proper update level
             * @return {*}
             */
            getUpdateInfo() {
                var info = super.getUpdateInfo(...arguments);

                if (info && !info.changelog) {
                    info.changelog = CHANGELOGS[this.getUpdateLevel()];
                }

                return info;
            }

            /**
             * Specifies what icon to use when prompting for updates!
             * @return {string}
             */
            getUpdateIcon() {
                return Icon.Menu.APP_ICON;
            }

            /**
             * Returns the function to call when an update is to be started.
             * @param link the link to the file or to the place where the update is available (app/play store)
             */
            getStartUpdateFunction(link) {
                Debug.Update.AppUpdate && console.log(this.name, "getStartUpdateFunction");
                let fn = null,
                    storeLink = this.getStoreLink();

                switch (this.getPlatformId()) {
                    case PlatformUpdateId.Mac:
                    case PlatformUpdateId.Windows:
                    case PlatformUpdateId.Linux:
                        fn = this._startElectronUpdate.bind(this, link, storeLink);
                        break;

                    case PlatformUpdateId.Android:
                        fn = this._startAndroidUpdate.bind(this, link, storeLink);
                        break;

                    case PlatformUpdateId.IOS:
                        fn = this._startIOSUpdate.bind(this, link, storeLink);
                        break;

                    default:
                        break;
                }

                return fn;
            }

            isUpdateAvailable(showUpdatePrompt) {
                var available = super.isUpdateAvailable(...arguments),
                    updInfo = this.getUpdateInfo(),
                    isVersionSupported = true;

                if (updInfo && (updInfo.hasOwnProperty("minos") || updInfo.hasOwnProperty("MinOS"))) {
                    // What is PlatformComponent.getPlatformInfoObj().version
                    // • iOS: The user visible iOS version as in the Settings App like 13.0.0
                    // • Android: The user visible Android version as in the Settings App like 8.0.0
                    // • macOS: The Darwin Kernel Version like 14.0.0 for OS X Yosemite -> https://de.wikipedia.org/wiki/Darwin_(Betriebssystem)
                    // • Linux: Different per distribution -> Min version not supported
                    // • Windows: The Windows version like 10.0.18363 for Windows 10 Patch 18363 -> https://docs.microsoft.com/en-us/windows/win32/api/winnt/ns-winnt-osversioninfoa#remarks
                    isVersionSupported = isEqualOrOlderVersionThan(updInfo.minos || updInfo.MinOS, PlatformComponent.getPlatformInfoObj().version);
                }

                return available && isVersionSupported && showUpdatePrompt;
            }

            /**
             * Returns the highest update level or Release if no update levels have been provided
             * The update levels can be passed as individual arguments
             * @note ALPHA (Internal) > BETA > RELEASE
             * @returns {string|*}
             */
            getHighestUpdateLevel() {
                var argsArray,
                    highestUpdateLevel = this.component.UpdateLevel.RELEASE;

                if (arguments.length) {
                    // Convert the arguments object to an Array to be able to use the Arrays prototype functions
                    argsArray = [].slice.call(arguments); // NOTE: We need to override this due ALPHA vs. ALPHA_LEGACY

                    if (argsArray.indexOf(this.component.UpdateLevel.ALPHA) !== -1) {
                        highestUpdateLevel = this.component.UpdateLevel.ALPHA;
                    } else if (argsArray.indexOf(this.component.UpdateLevel.BETA) !== -1) {
                        highestUpdateLevel = this.component.UpdateLevel.BETA;
                    }
                }

                return highestUpdateLevel;
            }

            // --------------------- start update fns ----------------------------------
            _startAndroidUpdate(link, storeLink) {
                link = storeLink || link;
                Debug.Update.AppUpdate && console.log(this.name, `_startAndroidUpdate: ${link}`);
                NavigationComp.openWebsite(link); // if this fails, maybe downloading the APK would be an option?
            }

            _startIOSUpdate(link, storeLink) {
                link = storeLink || link;
                Debug.Update.AppUpdate && console.log(this.name, `_startIOSUpdate: ${link}`);
                NavigationComp.openWebsite(link);
            }

            _startElectronUpdate(link, storeLink) {
                Debug.Update.AppUpdate && console.log(this.name, `_startElectronUpdate: ${storeLink || link}`);

                if (storeLink) {
                    NavigationComp.openWebsite(storeLink);
                    return;
                }

                Debug.Update.AppUpdate && console.log(this.name, "_startElectronUpdate");
                var updateLoaded = false,
                    updatePath,
                    loadingNotification = GUI.Notification.createGeneralNotification({
                        iconSrc: "resources/Images/ActiveMiniserver/QRCode/icon-save.svg",
                        iconColor: window.Styles.colors.green,
                        title: _("update.loading"),
                        clickable: true
                    }, NotificationType.SUCCESS);
                loadingNotification.on(GUI.Notification.CLICK_EVENT, function () {
                    if (updateLoaded) {
                        loadingNotification.remove(false);
                        loxoneControl.startUpdate(updatePath);
                    }
                }.bind(this));
                loadingNotification.on("destroy", function () {
                    loadingNotification = null;
                }.bind(this));
                loxoneControl.downloadUpdate(function (filePath) {
                    if (loadingNotification) {
                        updateLoaded = true;
                        loadingNotification.setIcon("resources/Images/General/TitleBar/titlebar_tickmark.svg");
                        loadingNotification.setTitle(_('update.install.now'));
                        loadingNotification.setSubtitle(null); // Removes the percentage

                        updatePath = filePath;
                    }
                }.bind(this), function () {
                    this._askAgainAfterDays(1);

                    loadingNotification.remove();
                }.bind(this), link, function (downloadProgress) {
                    if (loadingNotification) {
                        loadingNotification.setSubtitle(Math.round(downloadProgress) + "%");
                    }
                });
            }

            // -------------------- Private -------------------

            /**
             * Returns true if the update level is on beta or alpha
             * @returns {boolean}
             * @private
             */
            _isTestVersion() {
                return !UpdateComp.isRelease(this.getUpdateLevel());
            }

            /**
             * Ensures that the PlatformupdateId contains an id for each of the supported Platforms. It converts all
             * platformType values to lower case strings. This is important because of a legacy issue due to a lacking
             * platform check in older app versions (143956584)
             * @private
             */
            _preparePlatformUpdateIds() {
                var newIds = cloneObject(PlatformType);
                Object.keys(newIds).forEach(function (pfKey) {
                    newIds[pfKey] = this.getPlatformId(newIds[pfKey]);
                }.bind(this));
                PlatformUpdateId = newIds;
            }

            /**
             * Returns the fill color of the notification icon
             * @private
             */
            _getNotificationIconColor() {
                // Prevents any issues with icons which may use the style tag instead of the fill tag for its color
                return "initial";
            }

            /**
             * Returns the fill color of the Popup icon
             * @returns string
             * @private
             */
            _getPopupIconColor() {
                return null; // Defaults to window.Styles.colors.green. Don't use "initial", it will make the Popup buttons transparent
            }

        }

        Components.Update.extensions.AppUpdateExt = AppUpdateExt;
    }
    return Components;
}(window.Components || {});
