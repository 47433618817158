'use strict';

VendorHub.factory("FileSystemAdapter", function () {
    let useLocalStorage = !window.cordova && !window.electron,
        platformChecked = false,
        weakThis,
        name = "",
        content = "",
        def = {};

    function FileSystemAdapter() {
        weakThis = this
    } // write to the filesystem


    FileSystemAdapter.prototype.saveFile = function saveFile(fileName, fileContent) {
        name = fileName;
        content = fileContent;
        def = Q.defer();
        Debug.Filesystem && console.log("save file " + name);

        if (useLocalStorage) {
            try {
                localStorage.setItem(fileName, fileContent);
                def.resolve("saved to localstorage!");
            } catch (e) {
                if (_isQuotaExceeded(e)) {
                    Debug.Filesystem && console.log("Localstorage full, cleaning up!");
                    clearLocalStorage();

                    try {
                        localStorage.setItem(fileName, fileContent);
                        Debug.Filesystem && console.log("Successfully saved file after cleaning up!");
                        def.resolve("saved to localstorage!");
                    } catch (error) {
                        def.reject("local storage full, cleaning up!");
                        console.error(error.stack);
                    }
                } else {
                    def.reject("Unknown local storage error!");
                    console.error(e.stack);
                }
            }
        } else if (window.cordova) {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, gotFSWrite, failWrite);
        } else if (window.electron) {
            def.resolve(window.electron.remote.FS.writeFile(fileName, content, {
                encoding: "utf8",
                flag: "w"
            }));
        }

        return def.promise;
    }; // read from the filesystem


    FileSystemAdapter.prototype.loadFile = function loadFile(fileName) {
        name = fileName;
        def = Q.defer();
        Debug.Filesystem && console.log("load file " + name);

        if (useLocalStorage) {
            var file = localStorage.getItem(fileName);

            if (file) {
                def.resolve(file);
            } else {
                def.reject({
                    code: 404,
                    message: "no file found in localStorage!"
                });
            }
        } else if (window.cordova) {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, gotFSRead, failRead);
        } else if (window.electron) {
            def.resolve(window.electron.remote.FS.readFile(fileName, "utf8"));
        }

        return def.promise;
    }; // delete from the filesystem


    FileSystemAdapter.prototype.deleteFile = function deleteFile(fileName) {
        name = fileName;
        def = Q.defer();
        Debug.Filesystem && console.log("delete file " + name);

        if (useLocalStorage) {
            localStorage.removeItem(fileName);
            def.resolve(fileName);
        } else if (window.cordova) {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, gotFSDelete, failDelete);
        } else if (window.electron) {
            def.resolve(window.electron.remote.FS.unlink(fileName));
        }

        return def.promise;
    }; // write to the filesystem


    var gotFSWrite = function gotFSWrite(fileSystem) {
        Debug.Filesystem && console.log("gotFSWrite: " + name);
        fileSystem.root.getFile(name, {
            create: true,
            exclusive: false
        }, gotFileEntryWrite, failWrite);
    };

    var gotFileEntryWrite = function gotFileEntryWrite(fileEntry) {
        Debug.Filesystem && console.log("gotFileEntryWrite: " + name);
        fileEntry.createWriter(gotFileWriter, failWrite);
    };

    var gotFileWriter = function gotFileWriter(writer) {
        // binary for devices

        /*var data = new ArrayBuffer(content.length),
            dataView = new Int8Array(data);
        for (var i=0; i < content.length; i++) {
            dataView[i] = content.charCodeAt(i);
        }
        console.log("dataView = " + dataView);*/
        writer.onwriteend = function (evt) {
            Debug.Filesystem && console.log("write successfully " + name);
            def.resolve();
        }; // binary for devices
        //writer.write(data);


        var toWrite;

        if (useLocalStorage) {
            // Create a new Blob and write it to .json file
            toWrite = new Blob([content], {
                type: "text/json"
            });
        } else {
            toWrite = content;
        }

        writer.write(toWrite);
    }; // read from filesystem


    var gotFSRead = function gotFSRead(fileSystem) {
        Debug.Filesystem && console.log("gotFSRead: " + name);
        fileSystem.root.getFile(name, null, gotFileEntryRead, failRead);
    };

    var gotFileEntryRead = function gotFileEntryRead(fileEntry) {
        Debug.Filesystem && console.log("gotFileEntryRead: " + fileEntry.fullPath);
        fileEntry.file(gotFileRead, failRead);
    };

    var gotFileRead = function gotFileRead(file) {
        Debug.Filesystem && console.log("gotFileRead");
        readAsText(file); //readAsArrayBuffer(file);
    };

    var readAsText = function readAsText(file) {
        Debug.Filesystem && console.log("readAsText");
        var reader = new FileReader();

        reader.onloadend = function (evt) {
            Debug.Filesystem && console.log("read successfully"); //console.log("        content: '" + JSON.stringify(evt.target.result)  + "'");

            def.resolve(evt.target.result);
        };

        reader.onprogress = function () {
            Debug.Filesystem && console.log("progress while reading");
        };

        reader.onerror = function (error) {
            Debug.Filesystem && console.error("Error while reading");
            def.reject(error);
        };

        reader.onabort = function () {
            Debug.Filesystem && console.error("Aborted reading");
        };

        reader.readAsText(file);
    };

    var readAsArrayBuffer = function readAsArrayBuffer(file) {
        var reader = new FileReader();

        reader.onloadend = function (evt) {
            Debug.Filesystem && console.log("Read as array buffer");
            var arr = new Uint8Array(evt.target.result);
            var str = String.fromCharCode.apply(null, arr);
            def.resolve(str);
        };

        reader.onprogress = function () {
            Debug.Filesystem && console.log("progress while reading");
        };

        reader.onerror = function (error) {
            Debug.Filesystem && console.error("Error while reading");
            def.reject(error);
        };

        reader.onabort = function () {
            Debug.Filesystem && console.error("Aborted reading");
        };

        reader.readAsArrayBuffer(file);
    }; // delete from filesystem


    function gotFSDelete(fileSystem) {
        Debug.Filesystem && console.log("gotFSDelete: name = " + fileSystem.fullPath);
        fileSystem.root.getFile(name, {
            create: false,
            exclusive: false
        }, gotRemoveFileEntry, failDelete);
    }

    function gotRemoveFileEntry(fileEntry) {
        Debug.Filesystem && console.log("gotFileEntryDelete = " + fileEntry.fullPath);
        fileEntry.remove(successDelete, failDelete);
    }

    function successDelete() {
        // be aware when trying to use the fileEntry, the parameter isn't available!
        Debug.Filesystem && console.log("delete successfully");
        def.resolve();
    } // error handling


    var failWrite = function failWrite(error) {
        Debug.Filesystem && console.log("write error: " + name + "(" + error.message + ")");
        def.reject(error.message);
    };

    var failRead = function failRead(error) {
        Debug.Filesystem && console.log("read error: " + name + "(" + error.message + ")");
        def.reject(error.message);
    };

    var failDelete = function failRead(error) {
        Debug.Filesystem && console.log("delete error: " + name + "(" + error.message + ")");
        def.reject(error.message);
    }; // required by the persistence extension, it needs to know wether or not the filesystem is ready.


    FileSystemAdapter.prototype.isFilesystemReady = function isFilesystemReady() {
        // TODO-sulzean shouldn't be needed anymore since macOS
        if (!platformChecked && PlatformComponent) {
            platformChecked = true;
        }

        return platformChecked;
    }; // ----------------------------
    //       Mac
    // ----------------------------


    FileSystemAdapter.prototype._macSaveFile = function _macSaveFile(def, name, content) {
        try {
            window.WebViewJavascriptBridge.callHandler("storageHandler", {
                'type': 'save',
                'fileName': name,
                'fileContent': content
            }, function (responseData) {
                if (responseData.success) {
                    def.resolve("saved using local file system!");
                } else {
                    def.reject("no success indicator!");
                }
            });
        } catch (e) {
            def.reject("storage failed");
            console.error(e.stack);
        }
    };

    FileSystemAdapter.prototype._macLoadFile = function _macLoadFile(def, name) {
        try {
            window.WebViewJavascriptBridge.callHandler("storageHandler", {
                'type': 'load',
                'fileName': name
            }, function (responseData) {
                Debug.Filesystem && console.log("response data received!");
                var responseObject = JSON.parse(responseData);

                if (responseObject.code === 200) {
                    def.resolve(JSON.stringify(responseObject.fileContent));
                } else {
                    Debug.Filesystem && console.log("about to reject it!");
                    def.reject({
                        code: responseObject.code,
                        message: "no file found in localStorage!"
                    });
                }
            });
        } catch (e) {
            def.reject({
                code: 500,
                message: "exception thrown"
            });
            console.error(e.stack);
        }
    };

    FileSystemAdapter.prototype._macDeleteFile = function _macDeleteFile(def, name) {
        try {
            window.WebViewJavascriptBridge.callHandler("storageHandler", {
                'type': 'delete',
                'fileName': name
            });
            def.resolve({
                code: 200
            });
        } catch (e) {
            def.reject({
                code: 500,
                message: "exception thrown"
            });
            console.error(e.stack);
        }
    }; // private


    var _isQuotaExceeded = function _isQuotaExceeded(e) {
        var quotaExceeded = false;

        if (e) {
            if (e.code) {
                switch (e.code) {
                    case 22:
                        quotaExceeded = true;
                        break;

                    case 1014:
                        // Firefox
                        if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                            quotaExceeded = true;
                        }

                        break;
                }
            } else if (e.number === -2147024882) {
                // Internet Explorer 8
                quotaExceeded = true;
            }
        }

        return quotaExceeded;
    };

    return new FileSystemAdapter();
});
