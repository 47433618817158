'use strict';

window.Components = function (Components) {
    /**
     * This extension will take care of loading the update file for each miniserver after the StartUpdateCheck event
     * was received. It will only download it once a day.
     */
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var UPDATE_CHECK_PATH = "https://update.loxone.com/updatecheck.xml",
            UPDATE_FILE_NAME = "MS_UPDATE_FILE.json",
            // for storage
            ID_UPDATE = "UPDATE",
            ID_CHANGELOGS = "CHANGELOGS",
            ID_CHANGELOG = "CHANGELOG",
            ID_ATTR = "@attributes";
        var DEFAULT_LANG = "ENU",
            DEFAULT_CHANGELOG = "https://www.loxone.com/please-update/";

        class UpdateFileExt extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.registerExtensionEv(this.component.ECEvent.MiniserverChanged, this._handleMSChange.bind(this));
                this.registerExtensionEv(this.component.ECEvent.StartUpdateCheck, this._startUpdateCheck.bind(this));

                this._resetData();
            }

            _resetData() {
                this._updateInfo = null;
                this._miniserverSerial = null;
                this._miniserverFirmware = null;
            }

            /**
             * Called whenever a miniserver connects or is disconnected (session stop, not connection loss). As soon
             * as a LOCAL connection is here, the firmware will be aqcuired & an update check is started.
             * @param ev
             * @param arg
             * @private
             */
            _handleMSChange(ev, arg) {
                var connected = arg.connected;
                Debug.Update.UpdateFile && console.log(this.name + ": _handleMSChange: connected? " + connected);

                if (connected) {
                    Debug.Update.UpdateFile && console.log("   local connection, download after timeout");
                    this._miniserverSerial = ActiveMSComponent.getMiniserverSerialNo();
                    this._miniserverFirmware = ActiveMSComponent.getConfigVersion();

                    this._loadStoredData();
                } else {
                    Debug.Update.UpdateFile && console.log("   connection closed. If not started already, don't download."); //save everything we've got right now.

                    this._storeData(); // then reset the data.


                    this._resetData();
                }
            }

            // --------------------------------------------------
            // -----------   Update Check File        -----------
            // --------------------------------------------------

            /**
             * Entry point to kick off the update file acquisition.
             * @private
             */
            _startUpdateCheck(ev, args) {
                // check if the updateFile has already been loaded, or if they need to be redownloaded
                if (this._shouldDownload() || args && args.forced) {
                    this._loadUpdateCheck();
                } else {
                    // already loaded, return. Don't return it sync as it'd cause issues in other extensions when re-checking for updates.
                    setTimeout(this._emitUpdateFile.bind(this), 0);
                }
            }

            /**
             * Specifies whether or not the download should be started.
             * @returns {boolean}
             * @private
             */
            _shouldDownload() {
                Debug.Update.UpdateFile && console.log(this.name + ": _shouldDownload");
                var shouldDownload = true;

                if (this._updateInfo) {
                    shouldDownload = this._getCurrentDay() !== this._updateInfo.downloaded;
                }

                Debug.Update.UpdateFile && console.log("    _shouldDownload -> " + shouldDownload);
                return shouldDownload;
            }

            /**
             * Will download the update check file from the webserver. as soon as the result is here, the update check
             * procedure is being started (if the other data is ready).
             * @private
             */
            _loadUpdateCheck() {
                // STORE and only load once a day!
                Debug.Update.UpdateFile && console.log(this.name + ": _loadUpdateCheck");
                var url = UPDATE_CHECK_PATH; // append serial number and firmware version, the server might respond different based on these infos.

                url += "?serial=" + this._miniserverSerial;
                url += "&version=" + this._miniserverFirmware;
                url += "&reason=App"; // Tell the server that we're an app that is asking for a MS update check.

                $.ajax({
                    method: 'get',
                    url: url,
                    cache: false,
                    success: this._processUpdateFileLoaded.bind(this),
                    error: function (reason) {
                        console.error('could not load news update check file because: ' + reason.message);
                    }.bind(this)
                });
            }

            /**
             * As the file is in XML format and JavaScript really really likes JSON, the XML is converted to
             * a JSON object to make it easier to work with.
             * @param file  the xml file to transform.
             * @private
             */
            _processUpdateFileLoaded(file) {
                Debug.Update.UpdateFile && console.log(this.name + ": _processUpdateFileLoaded");
                var jsonFile;

                try {
                    jsonFile = xmlToJson(file)["MINISERVERSOFTWARE"];
                    jsonFile = this._cleanUpdateLevels(jsonFile);
                    this._updateInfo = this._translateUpdateFile(jsonFile);
                    this._updateInfo.downloaded = this._getCurrentDay();
                    Debug.Update.UpdateFile && console.log(JSON.stringify(this._updateInfo));

                    this._storeData();

                    this._emitUpdateFile();
                } catch (ex) {
                    console.error("Could not process the downloaded update file! " + ex);
                }
            }

            /**
             * Will dispatch the update file to other extensions.
             * @private
             */
            _emitUpdateFile() {
                this.channel.emit(this.component.ECEvent.UpdateFileLoaded, this._updateInfo.entries);
            }

            // ----------------------------------------
            // ----------   Persistence  --------------
            // ----------------------------------------

            /**
             * Will load any data available that has been stored.
             * @private
             */
            _loadStoredData() {
                Debug.Update.UpdateFile && console.log(this.name + ": _loadStoredData");
                this._updateInfo = null;

                if (!this._miniserverSerial) {
                    Debug.Update.UpdateFile && console.error("Cannot load update file without an active Miniserver!");
                    return;
                }

                PersistenceComponent.loadFile(this._getUpdateFileName()).then(function (file) {
                    Debug.Update.UpdateFile && console.log("   update file loaded for " + this._miniserverSerial);

                    try {
                        this._updateInfo = JSON.parse(file);

                        if (!this._updateInfo.hasOwnProperty("entries") || !this._updateInfo.hasOwnProperty("downloaded")) {
                            console.warn("Update file for " + this._miniserverSerial + " was outdated, did remove it");
                            this._updateInfo = null;
                        }
                    } catch (ex) {
                        this._updateInfo = null;
                    }
                }.bind(this), function () {
                    console.warn("No update file stored for " + this._miniserverSerial);
                }.bind(this));
            }

            /**
             * Will store any data that should be reused next time.
             * @private
             */
            _storeData() {
                Debug.Update.UpdateFile && console.log(this.name + ": _storeData");

                if (!this._miniserverSerial) {
                    Debug.Update.UpdateFile && console.error("Cannot store update file without an active Miniserver!");
                    return;
                }

                try {
                    PersistenceComponent.saveFile(this._getUpdateFileName(), JSON.stringify(this._updateInfo));
                } catch (ex) {
                    console.error("Could not persist updateFile for " + this._miniserverSerial + " - " + ex);
                }
            }

            /**
             * Returns the udpate file name for the current miniserver.
             * @returns {string}
             * @private
             */
            _getUpdateFileName() {
                return this._miniserverSerial + "_" + UPDATE_FILE_NAME;
            }

            /**
             * Returns a number representing the current day. Used e.g. to ensure the updatecheck.xml is only downloaded
             * once a day. If the current day number changes, the updatefile is redownloaded.
             * @returns {number}
             * @private
             */
            _getCurrentDay() {
                return new Date().getDate(); // returns the day of the month. sufficient for this purpose.
            }

            /**
             * Will go through the entire update file and ensure all update levels are in the most recent format
             * @private
             */
            _cleanUpdateLevels(input) {
                Debug.Update.UpdateFile && console.log(this.name + ": _cleanUpdateLevels");
                var str = JSON.stringify(input);
                str = str.replace(/("default")/gi, '"' + UpdateComp.UpdateLevel.RELEASE + '"');
                str = str.replace(/("release")/gi, '"' + UpdateComp.UpdateLevel.RELEASE + '"');
                str = str.replace(/("beta")/gi, '"' + UpdateComp.UpdateLevel.BETA + '"');
                str = str.replace(/("test")/gi, '"' + UpdateComp.UpdateLevel.ALPHA_LEGACY + '"');
                str = str.replace(/("intern")/gi, '"' + UpdateComp.UpdateLevel.ALPHA_LEGACY + '"');
                str = str.replace(/("internal")/gi, '"' + UpdateComp.UpdateLevel.ALPHA_LEGACY_V2 + '"');
                str = str.replace(/("internalv2")/gi, '"' + UpdateComp.UpdateLevel.ALPHA + '"'); // This is our apps new Alpha

                Debug.Update.UpdateFile && console.log("    cleaned: " + str);
                return JSON.parse(str);
            }

            /**
             * Converts the gibberish of the xml2json transformation into a more usable updateInfo Object.
             * @param rawInfo
             * @returns {{entries: Array}}
             * @private
             */
            _translateUpdateFile(rawInfo) {
                Debug.Update.UpdateFile && console.log(this.name + ": _translateUpdateFile");
                var updateInfo = {
                        entries: []
                    },
                    pfName,
                    pfType; // root entry, for config

                updateInfo.entries.push({
                    name: UpdateComp.TargetType.CONFIG,
                    type: UpdateComp.TargetType.CONFIG,
                    levels: this._readUpdateLevels(rawInfo)
                });
                updateInfo.entries[0].levels[UpdateComp.UpdateLevel.RELEASE].changelog = this._readChangelog(rawInfo); // app platforms & music server & devices

                rawInfo[ID_UPDATE].forEach(function (rawPf) {
                    pfName = rawPf[ID_ATTR].name;
                    pfType = rawPf[ID_ATTR].type.toLowerCase();
                    updateInfo.entries.push({
                        name: pfName,
                        type: pfType,
                        levels: this._readUpdateLevels(rawPf)
                    });
                }.bind(this));
                return updateInfo;
            }

            /**
             * Reads the update levels from a platform entry of the raw update file.
             * @param rawPf
             * @returns {{}}
             * @private
             */
            _readUpdateLevels(rawPf) {
                var levels = {};
                Object.values(UpdateComp.UpdateLevel).forEach(function (updLvl) {
                    if (rawPf.hasOwnProperty(updLvl)) {
                        levels[updLvl] = rawPf[updLvl][ID_ATTR];
                    }
                });
                return levels;
            }

            /**
             * The changelog for the config. Picks the right language too.
             * @param rawInfo
             * @private
             */
            _readChangelog(rawInfo) {
                var changelogs = {},
                    language = _("menu.news.countryCode").toLowerCase(),
                    changelog = DEFAULT_CHANGELOG;

                if (rawInfo.hasOwnProperty(ID_CHANGELOGS) && rawInfo[ID_CHANGELOGS].hasOwnProperty(ID_CHANGELOG)) {
                    rawInfo[ID_CHANGELOGS][ID_CHANGELOG].forEach(function (rawObj) {
                        changelogs[rawObj[ID_ATTR].lang.toLowerCase()] = rawObj[ID_ATTR].url;
                    });
                }

                if (changelogs.hasOwnProperty(language)) {
                    changelog = changelogs[language];
                } else if (changelogs.hasOwnProperty(DEFAULT_LANG)) {
                    changelog = changelogs[DEFAULT_LANG];
                }

                return changelog;
            }

        }

        Components.Update.extensions.UpdateFileExt = UpdateFileExt;
    }
    return Components;
}(window.Components || {});
