'use strict';

{
    class ImageBox {
        //region Static
        static MINISERVER_INFO_BOX_VERSION = 7; //endregion Static

        constructor() {
            // internal variables
            this._downloader = null;
            this._miniserverImageBox = null;
            this._miniserverImageBoxCache = {};
            this._miniserverImageBoxFilename = "";
            this._requestMiniserverImageBox = {};
            this._currentMSSnr = "";
            this._saveMiniserverImageBoxTimeout = null;
        }

        /**
         * Setter for this._downloader
         * @param dl the new this._downloader
         */
        setDownloader(dl) {
            this._downloader = dl;
        }

        /**
         * Setter for the serial number of active miniserver
         * @param srnNr
         */
        setMSInformation(srnNr) {
            Debug.ImageBox && console.log("ImageBox: setMSInformation: " + srnNr + " (currentMSSnr: " + this._currentMSSnr + ")");
            if (this._currentMSSnr === srnNr) return; // same snrNr, return!

            this._currentMSSnr = srnNr;
            this._miniserverImageBoxCache = {};
            this._requestMiniserverImageBox = {};
            this._miniserverImageBoxFilename = this._currentMSSnr + "_ImageBox.json";
            this._miniserverImageBox = {
                version: this.constructor.MINISERVER_INFO_BOX_VERSION,
                images: []
            };
            PersistenceComponent.loadFile(this._miniserverImageBoxFilename, DataType.OBJECT).then(function (result) {
                // check the version of the image box!
                Debug.ImageBox && console.log("ImageBox: loaded");

                if (result.version !== this.constructor.MINISERVER_INFO_BOX_VERSION) {
                    this.deleteImageBoxOf(this._currentMSSnr);
                    this._miniserverImageBox = {
                        version: this.constructor.MINISERVER_INFO_BOX_VERSION,
                        images: []
                    };
                } else {
                    this._miniserverImageBox = result;
                }
            }.bind(this), function () {
                Debug.ImageBox && console.log("ImageBox: couldn't load " + this._miniserverImageBoxFilename);
            });
        }

        /**
         * Got called when we should save the miniserver image box
         * @param reset if imageBox should be resetted
         */
        saveMSImageBox(reset) {
            var def = Q.defer();
            Debug.ImageBox && console.log("ImageBox: saveMSImageBox: " + reset);
            clearTimeout(this._saveMiniserverImageBoxTimeout);

            if (this._miniserverImageBox && Object.keys(this._miniserverImageBox).length > 0) {
                PersistenceComponent.saveFile(this._miniserverImageBoxFilename, this._miniserverImageBox, DataType.OBJECT).then(function () {
                    def.resolve();
                    Debug.ImageBox && console.log("ImageBox: successfully saved " + this._miniserverImageBoxFilename);
                }, function (error) {
                    def.reject(error);
                    Debug.ImageBox && console.error("ImageBox: couldn't save " + this._miniserverImageBoxFilename + " (" + error + ")");
                });

                if (reset) {
                    this._currentMSSnr = null;
                    this._miniserverImageBox = {
                        version: this.constructor.MINISERVER_INFO_BOX_VERSION,
                        images: []
                    };
                }
            } else {
                def.resolve();
            }

            return def.promise;
        }

        /**
         * Deletes the image box of given miniserver
         * @param imageBox file name
         */
        deleteImageBoxOf(imageBox) {
            Debug.ImageBox && console.log("ImageBox: deleteImageBoxOf: " + imageBox);
            PersistenceComponent.deleteFile(imageBox + "_ImageBox.json").then(function () {
                Debug.ImageBox && console.log("ImageBox: successfully deleted " + imageBox);
            }, function (error) {
                Debug.ImageBox && console.error("ImageBox: couldn't delete " + imageBox + " (" + error + ")");
            });
        }

        /**
         * Deletes an image from the current imageBox
         * @param imageUrl file name
         */
        deleteImage(imageUrl) {
            if (this._miniserverImageBox[imageUrl]) {
                delete this._miniserverImageBox[imageUrl];
                return this.saveMSImageBox();
            } else {
                Debug.ImageBox && console.log("ImageBox: deleteImage --> no image with imageUrl found:  " + imageUrl);
            }
        }

        /**
         * returns instantly an image from the cache!
         * @param imageName
         * @returns {*}
         */
        getResourceImage(imageName) {
            var imgString = ResourceImageCache[imageName];

            if (!imgString) {
                console.info("INFO: resource not cached (" + imageName + ") - please run grunt task ('grunt lxresourcecache') to cache resources!");
                imgString = ResourceImageCache[Icon.DEFAULT];
            }

            return imgString;
        }

        /**
         * returns instantly an image from the cache and adds the classes string!
         * @param imageName
         * @param [classes] optional a list of classes for the image.
         * @returns {*}
         */
        getResourceImageWithClasses(imageName, classes) {
            var imgString = this.getResourceImage(imageName);
            return classes ? this.addClassesToSVG(imgString, classes) : imgString;
        }

        /**
         * Adds the classes provided to the svg string. It keeps in mind that outerHtml isn't supported on earlier android
         * os versions (e.g. 4.4.2).
         * @param imgString
         * @param classes
         * @return {*}
         */
        addClassesToSVG(imgString, classes) {
            if (typeof classes !== "string" || classes.length === 0) {
                return imgString;
            }

            var el = stringToHTML(imgString),
                result,
                tmp;
            classes.trim().split(" ").forEach(function (c) {
                el.classList.add(c);
            });
            result = el.outerHTML;

            if (!result) {
                // outerHtml is not supported on SVGs in earlier Android versions (e.g. 4.4.2)
                tmp = document.createElement('div');
                tmp.appendChild(el);
                result = tmp.innerHTML;
            }

            return result;
        }

        /**
         * Download image by number or uuid over websocket
         * @param imageName The number or uuid of the image
         * @param mimeType (expected) mimeTypeof requested image
         * @param hasPrio  ensures it's loaded ASAP
         */
        getImage(imageName, mimeType, hasPrio) {
            if (imageName === Icon.DEFAULT) {
                return this._makePromise({
                    image: ResourceImageCache[Icon.DEFAULT],
                    mimeType: "svg"
                });
            }

            if (imageName.split(".").length === 1 && imageName.indexOf("camimage/") === -1) {
                //console.log("download image without extension - use '.svg'");
                if (mimeType) {
                    imageName = imageName + "." + mimeType;
                } else {
                    imageName = imageName + ".svg";
                }
            } else {// has extension or is camimage.. ok!
            }

            if (imageName.indexOf("camimage/") === -1) {
                var imgObj = this._miniserverImageBox[imageName];

                if (imgObj && imgObj.image && imgObj.image.length > 0 && imgObj.mimeType !== "n/a") {
                    return this._makePromise(imgObj);
                }
            }

            if (this._miniserverImageBoxCache[imageName]) {
                //console.log("from cache");
                return this._makePromise(this._miniserverImageBoxCache[imageName]);
            }

            var imageIdx = this._miniserverImageBox.images.indexOf(imageName);

            if (imageIdx !== -1) {
                return PersistenceComponent.loadFile(encodeURIComponent(imageName), DataType.OBJECT).then(function (result) {
                    //console.log("got image");
                    this._miniserverImageBoxCache[imageName] = result;
                    return result;
                }.bind(this), function () {
                    //console.error("image loading failed, download");
                    this._miniserverImageBox.images.splice(imageIdx, 1);

                    return this.getImage(imageName, mimeType);
                }.bind(this));
            } else if (this._requestMiniserverImageBox[imageName]) {
                //console.info("requesting miniserver image while download in progress!");
                return this._requestMiniserverImageBox[imageName];
            } else {
                var result = this._downloader(imageName, !!hasPrio),
                    parts = imageName.split(".");

                if (!mimeType) {
                    mimeType = parts[parts.length - 1];
                }

                this._requestMiniserverImageBox[imageName] = Q.when(result).then(function (imageObj) {
                    if (typeof imageObj === "string") {// nothing to do
                    } else {
                        if (imageObj) {
                            if (mimeType === "svg") mimeType = "png"; // if Miniserver has no .svg, it searches for .png!

                            imageObj = "data:image/" + mimeType + ";base64," + arrayBufferToBase64String(imageObj);
                        } else {
                            mimeType = "n/a";
                            imageObj = null;
                        }
                    }

                    var newImg = {
                        image: imageObj,
                        mimeType: mimeType
                    };

                    if (newImg.mimeType !== "n/a") {
                        if (imageName.indexOf("camimage/") === -1) {
                            //console.log("add to ImageBox");
                            this._miniserverImageBox[imageName] = newImg;
                            clearTimeout(this._saveMiniserverImageBoxTimeout);
                            this._saveMiniserverImageBoxTimeout = setTimeout(this.saveMSImageBox.bind(this, false), 2000);
                        } else {
                            var platform = PlatformComponent.getPlatformInfoObj().platform;

                            if (platform === PlatformType.Webinterface || platform === PlatformType.DeveloperInterface) {
                                this._miniserverImageBoxCache[imageName] = newImg;
                            } else {
                                PersistenceComponent.saveFile(encodeURIComponent(imageName), newImg, DataType.OBJECT).then(function () {
                                    //console.log("file saved!");
                                    this._miniserverImageBoxCache[imageName] = newImg;

                                    this._miniserverImageBox.images.push(imageName); // clean up old images


                                    while (this._miniserverImageBox.images.length > 20) {
                                        var imageToDelete = this._miniserverImageBox.images.shift();

                                        PersistenceComponent.deleteFile(encodeURIComponent(imageToDelete)).then(function () {//console.log("removed old file");
                                        });
                                    }

                                    clearTimeout(this._saveMiniserverImageBoxTimeout);
                                    this._saveMiniserverImageBoxTimeout = setTimeout(this.saveMSImageBox.bind(this, false), 2000);
                                }.bind(this));
                            }
                        }
                    }

                    delete this._requestMiniserverImageBox[imageName];
                    return newImg;
                }.bind(this), function (e) {
                    console.error("ERROR: image download failed: " + JSON.stringify(e));
                    delete this._requestMiniserverImageBox[imageName]; // return default image!

                    return {
                        image: ResourceImageCache[Icon.DEFAULT],
                        mimeType: "svg"
                    };
                }.bind(this));

                this._requestMiniserverImageBox[imageName].instant = function instant() {
                };

                return this._requestMiniserverImageBox[imageName];
            }
        }

        /**
         * Decides based on the image id wether the image is to be loaded from the image cache, the miniserver or if it's an
         * image already that needs to be wrapped into an element. Either way, it will return a promise that resolves once
         * the image element is ready and the classes have been assigned.
         * @param imageId       either the url, the resource path or the image itself.
         * @param classes       the classes that need to be set onto the resulting query element.
         * @param [mimeType]
         * @return {*}
         */
        getImageElement(imageId, classes, mimeType, fallbackSrc) {
            var isIconElement = imageId.startsWith("<svg"),
                isHttpIcon = imageId.startsWith("http"),
                isResourceImage = imageId.startsWith("resources/"),
                isMsIcon = !isHttpIcon && !isIconElement && !isResourceImage;

            if (isMsIcon) {
                return this._getMsImageElement(imageId, classes, mimeType);
            } else if (isIconElement) {
                return Q.when($(this.addClassesToSVG(imageId, classes)));
            } else if (isResourceImage) {
                return Q.when($(this.getResourceImageWithClasses(imageId, classes)));
            } else {
                return this._getImageElementWithContent(imageId, classes, fallbackSrc);
            }
        }

        /**
         * Download camimage from miniserver
         * @param uuid
         * @param timestamp
         */
        getCamImage(uuid, timestamp) {
            return this.getImage("camimage/" + uuid + "/" + timestamp, "jpg");
        }

        _getMsImageElement(imageId, classes, mimeType) {
            // wrap in q.when as this.getImage does not return a proper promise, but a "custom" one.
            return Q.when(this.getImage(imageId, mimeType).then(function (image) {
                if (image.mimeType === "svg") {
                    return $(this.addClassesToSVG(image.image, classes));
                } else {
                    // wrap the image into an svg, as it won't be shown properly (jpg or PNG)
                    return this._getImageElementWithContent(image.image, "icon__sub-image").then(function (elem) {
                        return $('<svg class="' + classes + '"></svg>').append(elem);
                    });
                }
            }.bind(this)));
        }

        _getImageElementWithContent(source, classes, fallbackSrc) {
            var args = arguments,
                cache = this._miniserverImageBoxCache[source],
                def = Q.defer();

            var imageIdx = this._miniserverImageBox.images.indexOf(source);

            if (cache) {
                source = cache;
            } else if (imageIdx !== -1) {
                def.resolve(PersistenceComponent.loadFile(encodeURIComponent(source), DataType.OBJECT).then(function (result) {
                    //console.log("got image");
                    this._miniserverImageBoxCache[source] = result;
                    return this._getImageElementWithContent.apply(this, args);
                }.bind(this), function () {
                    //console.error("image loading failed, download");
                    this._miniserverImageBox.images.splice(imageIdx, 1);

                    return this._getImageElementWithContent.apply(this, args);
                }.bind(this)));
                return def.promise;
            }

            getIconElement(source, null, null, function onImgLoaded(elm, src, b64DataUri) {
                var platform = PlatformComponent.getPlatformInfoObj().platform;

                if (b64DataUri && platform !== PlatformType.Webinterface && platform !== PlatformType.DeveloperInterface) {
                    PersistenceComponent.saveFile(encodeURIComponent(src), b64DataUri, DataType.OBJECT).then(function () {
                        //console.log("file saved!");
                        this._miniserverImageBoxCache[src] = b64DataUri;

                        this._miniserverImageBox.images.push(src);

                        clearTimeout(this._saveMiniserverImageBoxTimeout);
                        this._saveMiniserverImageBoxTimeout = setTimeout(this.saveMSImageBox.bind(this, false), 2000);
                    }.bind(this));
                }

                if (classes) {
                    classes.split(" ").forEach(function (className) {
                        elm[0].classList.add(className);
                    });
                }

                def.resolve(elm);
            }.bind(this), function onError() {
                def.resolve(this.getImageElement(fallbackSrc || "", classes));
            }.bind(this));
            return def.promise;
        }

        _makePromise(imgObj) {
            // make my own "promise" and "resolve" it immediately (brings 200x the speed of angulars promise!)
            var myPromise = {
                result: imgObj
            };

            myPromise.then = function then(fn) {
                return fn(this.result);
            };

            myPromise.instant = function instant() {
                //fn(this.result);
                return this.result;
            };

            return myPromise;
        }

    }

    window.ImageBox = new ImageBox();
}
