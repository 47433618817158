'use strict';

var lxUnitConverter = function () {
    // Format definitions
    var ValueUnitCode = {
        UNKNOWN: -1,
        LOWEST: 0,
        KILO: 1,
        MEGA: 2,
        GIGA: 3,
        TERA: 4
    };
    var FormatCode = {
        UNKNOWN: -1,
        WATT: 0,
        WATTHOUR: 1,
        BYTE: 2
    };
    var unitValues = {};
    unitValues[ValueUnitCode.LOWEST] = 1;
    unitValues[ValueUnitCode.KILO] = 1000;
    unitValues[ValueUnitCode.MEGA] = 1000000;
    unitValues[ValueUnitCode.GIGA] = 1000000000;
    unitValues[ValueUnitCode.TERA] = 1000000000000;
    var byteUnitValues = {};
    byteUnitValues[ValueUnitCode.LOWEST] = 1;
    byteUnitValues[ValueUnitCode.KILO] = byteUnitValues[ValueUnitCode.LOWEST] * 1024;
    byteUnitValues[ValueUnitCode.MEGA] = byteUnitValues[ValueUnitCode.KILO] * 1024;
    byteUnitValues[ValueUnitCode.GIGA] = byteUnitValues[ValueUnitCode.MEGA] * 1024;
    byteUnitValues[ValueUnitCode.TERA] = byteUnitValues[ValueUnitCode.GIGA] * 1024;
    var unitValueIdentifier = {};
    unitValueIdentifier[ValueUnitCode.LOWEST] = '';
    unitValueIdentifier[ValueUnitCode.KILO] = 'k';
    unitValueIdentifier[ValueUnitCode.MEGA] = 'M';
    unitValueIdentifier[ValueUnitCode.GIGA] = 'G';
    unitValueIdentifier[ValueUnitCode.TERA] = 'T';
    var formatStrIdentifiers = {};
    formatStrIdentifiers[FormatCode.WATTHOUR] = 'Wh';
    formatStrIdentifiers[FormatCode.WATT] = 'W';
    formatStrIdentifiers[FormatCode.BYTE] = 'B';

    /**
     * used e.g. to avoid 0.003 W --> According to AH watt should not have decimals
     * @param formatCode
     * @param valueUnitCode
     * @returns {number}
     */
    const getMaxNDecimals = (formatCode, valueUnitCode) => {
        let limit = 3;
        if (formatCode === FormatCode.WATT || formatCode === FormatCode.WATTHOUR) {
            switch (valueUnitCode) {
                case ValueUnitCode.LOWEST: // Watt doesn't need a smaller unit.
                    limit = 0;
                    break;
                case ValueUnitCode.KILO:
                    limit = 1;
                    break;
                default:
                    limit = 2;
                    break;
            }
        }
        return limit;
    }

    function LxUnitConverter(input) {
        if (input.value === undefined) {
            // don't modify the value unit - no value provided.
            input.formatStr = "--";
            return;
        }

        let formatId = FormatCode.UNKNOWN, // watt, kilowatt
            value = input.value,
            lowestValue,
            nFractions;

        if (value % 1 > 0.999999) {
            value = Math.round(value);
        }

        // identify what kind of value is used and what unit it's given in
        if (identifyWattHours(input)) {
            formatId = FormatCode.WATTHOUR;
        } else if (identifyWatt(input)) {
            formatId = FormatCode.WATT;
        } else if (identifyByte(input)) {
            formatId = FormatCode.BYTE;
        } else {
            input.valueUnit = ValueUnitCode.UNKNOWN;
            return;
        }

        input.originalUnitValue = unitValues[input.valueUnit];

        lowestValue = computeToLowestUnit(value, input.valueUnit, formatId); // Find what's the best unit for this value!

        input.valueUnit = findNewValueUnit(lowestValue, formatId === FormatCode.BYTE ? byteUnitValues : unitValues);

        // compute the new value
        input.newUnitValue = unitValues[input.valueUnit];
        value = lowestValue / unitValues[input.valueUnit];

        if (!/^%(?:.[0-6])?f/.test(input.formatStr)) {
            // the format is not okay, don't adopt it
            return;
        }
        // if the unit wasn't modified, don't modify the decimals

        if (input.originalUnitValue > input.newUnitValue) { // Did convert down
            nFractions = 0;
        } else if (input.originalUnitValue < input.newUnitValue) { // Did convert up
            nFractions = 2;
        } else { // Didn't convert at all
            nFractions = _detectInputFormatFractions(input.formatStr);
        }

        input.value = value;
        input.formatStr = `%.${nFractions}f ${unitValueIdentifier[input.valueUnit]}${formatStrIdentifiers[formatId]}`
    }

    var _detectInputFormatFractions = function _detectInputFormatFractions(inputFormat) {
        let decimalFormatRegex = /.*%([0-9]*).[0-9]f.*/,
            maxDecimals = 3;
        try {
            if (decimalFormatRegex.test(inputFormat)) { // ensures its sth like: %.3f kWh or %04.2fkW
                let dotLoc = inputFormat.indexOf("."),
                    fLoc = inputFormat.indexOf("f");
                if (dotLoc >= 0 && fLoc >= 2 && dotLoc < fLoc) {
                    let subStr = inputFormat.substring(dotLoc + 1, dotLoc + 2);
                    maxDecimals = parseInt(subStr);
                }

            }
        } catch (ex) {
            console.error("UnitConverter", "Failed trying to look up the maxDecimals of the input!", ex);
        }
        return maxDecimals;
    }

    var identifyWattHours = function identifyWattHours(input) {
        return identifyUnitFormat(formatStrIdentifiers[FormatCode.WATTHOUR], input);
    };

    var identifyWatt = function identifyWatt(input) {
        return identifyUnitFormat(formatStrIdentifiers[FormatCode.WATT], input);
    };

    var identifyByte = function identifyByte(input) {
        return identifyUnitFormat(formatStrIdentifiers[FormatCode.BYTE], input);
    };

    var identifyUnitFormat = (unit, input) => {
        let identifyRegex = new RegExp(`f ?[${Object.values(unitValueIdentifier).join("")}]?${unit}$`),
            isUnitFormat = false;

        if (identifyRegex.test(input.formatStr)) {
            let unitKey = input.formatStr.match(new RegExp(`(.)${unit}`))[1]
            if (unitKey === " ") {
                unitKey = "";
            }
            if (!Object.values(unitValueIdentifier).includes(unitKey)) {
                isUnitFormat = false;
            } else {
                input.valueUnit = parseInt(Object.keys(unitValueIdentifier).find(key => unitValueIdentifier[key] === unitKey));
                isUnitFormat = input.valueUnit !== ValueUnitCode.UNKNOWN;
            }
        }
        return isUnitFormat;
    }

    var computeToLowestUnit = function computeToLowestUnit(value, valueUnit, formatCode) {
        if (formatCode === FormatCode.BYTE) {
            return value * byteUnitValues[valueUnit];
        } else {
            return value * unitValues[valueUnit];
        }
    };


    var findNewValueUnit = function findNewValueUnit(lowestValue, unitValueArray, valueUnitLimit) {
        var newValueUnit;
        let checkValue = Math.abs(lowestValue); // comparison assumes positive values!

        if (checkValue < unitValueArray[ValueUnitCode.KILO]) {
            newValueUnit = ValueUnitCode.LOWEST;
        } else if (checkValue < unitValueArray[ValueUnitCode.MEGA]) {
            newValueUnit = ValueUnitCode.KILO;
        } else if (checkValue < unitValueArray[ValueUnitCode.GIGA]) {
            newValueUnit = ValueUnitCode.MEGA;
        } else {
            newValueUnit = ValueUnitCode.GIGA;
        }

        if (valueUnitLimit && newValueUnit > valueUnitLimit) {
            newValueUnit = valueUnitLimit;
        }

        return newValueUnit;
    };

    LxUnitConverter.isConvertable = function isConvertable(formatStr) {
        if (!formatStr) {
            return false;
        }

        let formatObj = {
            formatStr
        };
        return identifyWatt(formatObj) || identifyWattHours(formatObj) || identifyByte(formatObj);
    };

    LxUnitConverter.convertAndApply = function convertAndApply(formatStr, value) {
        let formatObject = {
            formatStr,
            value
        };
        !Debug.DisableUnitConversion && LxUnitConverter(formatObject);
        return lxFormat(formatObject.formatStr, formatObject.value, true);
    };
    /**
     * Formats a value and converts the formated value to a suitable unit
     * @param formatStr the format string that is to be applied to the value
     * @param value the value that is to be formated
     * @param [toLowerOnly] if set, the value isn't converted e.g. from kW to MW, but only to W if neccessary
     * @returns returns an object carrying the predText, valText & succText
     */


    LxUnitConverter.convertSplitAndApply = function convertSplitAndApply(formatStr, value, autoDropDecimals = true) {
        if (isNaN(value)) {
            return { valueTxt: "--", succTxt: "" };
        }
        let inputObject = {
            formatStr,
            value
        };
        !Debug.DisableUnitConversion && LxUnitConverter(inputObject);
        let splitFormat = lxSplitFormat(inputObject.formatStr);
        splitFormat.valueTxt = lxFormat(splitFormat.valFormat, inputObject.value, autoDropDecimals);
        return splitFormat;
    };

    LxUnitConverter.getUnitInformation = function getUnitInformation(formatStr, value) {
        var inputObject = {};
        inputObject.formatStr = formatStr;
        inputObject.value = value;
        !Debug.DisableUnitConversion && LxUnitConverter(inputObject);
        var splitFormat = lxSplitFormat(inputObject.formatStr);
        return {
            formatStr: inputObject.formatStr,
            valFormat: splitFormat.valFormat,
            unitStr: splitFormat.succTxt.trim(),
            originalUnitValue: inputObject.originalUnitValue || 0,
            newUnitValue: inputObject.newUnitValue || 0,
            divisor: (inputObject.newUnitValue || 1) / (inputObject.originalUnitValue || 1),
        };
    };

    return LxUnitConverter;
}();
