'use strict';
/**
 * Created by TW on 05.07.21.
 *
 * Especially on iOS changes between WiFi and Carrier network may occur frequently. Up to now, on
 * each change from carrier to WiFi, the app did close the connection and hence did reconnect fully.
 *
 * In order to reduce the number of unneccesary reconnects, this verifier will check if a local
 * connection is possible.
 */

var ConnectivityTools = function (ConnectivityTools) {
    class ReachabilityVerifier {
        constructor(activeMs) {
            this.name = "ReachabilityVerifier";
            this._verificationDefer = null;
            this._reachability = new ConnectivityTools.ReachabilityCheck();
            this._maxRetries = 0;
            this._retryCount = 0;
        }

        destroy() {
            this.stop();
        }

        isActive() {
            return this._verificationDefer !== null;
        }

        isActiveTarget(local, serial) {
            return this.isActive() && local === this._local && serial === this._serial;
        }

        verify(local, serial, tlsSupport, usesCloudDNS, maxRetries) {
            Debug.ReachabilityVerification && console.log(this.name, "verify");

            if (this.isActiveTarget(serial, local)) {
                Debug.ReachabilityVerification && console.log(this.name, "   verification for same target ongoing, return promise");
                return this._verificationDefer.promise;
            } else if (this._verificationDefer != null) {
                Debug.ReachabilityVerification && console.log(this.name, "   previous verification ongoing, stop first");
                this.stop();
            }

            if (maxRetries) {
                this._maxRetries = maxRetries || 0;
            }

            this._retryCount = 0;
            this._verificationDefer = Q.defer();
            this._local = local;
            this._serial = serial;

            this._reachability.detectReachability(local, null, serial, tlsSupport, usesCloudDNS).done(this._handleReachabilityConfirmed.bind(this), this._handleReachabilityError.bind(this), this._handleReachabilityNotification.bind(this));

            return this._verificationDefer.promise;
        }

        stop() {
            Debug.ReachabilityVerification && console.log(this.name, "stop");

            if (this._verificationDefer) {
                this._reject();
            }

            this._reachability.stopReachabilityDetection();
        }

        _handleReachabilityConfirmed() {
            Debug.ReachabilityVerification && console.log(this.name, "_handleReachabilityConfirmed");

            this._resolve();
        }

        _handleReachabilityError() {
            Debug.ReachabilityVerification && console.log(this.name, "_handleReachabilityError");

            this._reject();
        }

        _handleReachabilityNotification() {
            this._retryCount++;
            Debug.ReachabilityVerification && console.log(this.name, "_handleReachabilityNotification - " + this._retryCount);

            if (this._retryCount > this._maxRetries) {
                this._reachability.stopReachabilityDetection();

                this._reject();
            }
        }

        _resolve() {
            if (this._verificationDefer) {
                this._verificationDefer.resolve();

                this._verificationDefer = null;
            }
        }

        _reject() {
            if (this._verificationDefer) {
                this._verificationDefer.reject();

                this._verificationDefer = null;
            }
        }

    }

    ConnectivityTools.ReachabilityVerifier = ReachabilityVerifier;
    return ConnectivityTools;
}(ConnectivityTools || {});
