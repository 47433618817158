'use strict';

window.Components = function (Components) {
    class MusicServerUpdateExt extends Components.Update.extensions.PlatformUpdateBaseExt {
        constructor(component, extensionChannel, serverObject) {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
            this.musicServer = serverObject;
            this.currentVersion = false;
            this.updateLevel = false;
            this.isServerOn = false; // music servers might be offline
            // needs the states to know if the Music Server is online and what version it's currently running on.

            this._registerForStates(this.musicServer.uuidAction);
        }

        destroy() {
            this._unregisterStates(this.musicServer.uuidAction);

            this.musicServer = null;
            this.currentVersion = false;
            this.updateLevel = false;
            this.isServerOn = false;
            super.destroy(...arguments);
        }

        // ---------- METHODS TO OVERRIDE -----------------

        /**
         * Returns the update file ID of the current platform
         * @return {string}
         */
        getPlatformId() {
            return UpdateComp.TargetType.MUSICSERVER;
        }

        /**
         * Called when the update checks are initiated. The Update file download will also start at that time. E.g.
         * the update level can be loaded here, or the current version
         * @return {Promise} a promise that will resolve when the data acquired by this ext is ready for the update check.
         */
        prepareForUpdateCheck() {
            Debug.Update.UpdateBase && console.log(this.name + ": prepareForUpdateCheck");

            var basePromise = super.prepareForUpdateCheck(...arguments),
                promise = this._loadData();

            return Q.all([promise, basePromise]);
        }

        /**
         * Returns the update level of the current platform
         * @return {string}
         */
        getUpdateLevel() {
            return this.updateLevel;
        }

        /**
         * Returns the platforms current version
         * @return {string}
         */
        getCurrentVersion() {
            return this.currentVersion;
        }

        /**
         * Returns the message to show in the popup and in the update notification
         * @param newVersion    the version of the available update
         * @param currVersion
         * @param overdue       if true, the update is overdue already - should result in a firm message.
         * @return {string}
         */
        getMessage(newVersion, currVersion, overdue) {
            return _("update.available.music.text", {
                version: newVersion
            }) + "<br>" + _("update.current-version", {
                version: currVersion
            });
        }

        /**
         * Returns the title to show in the popup and in the update notification
         * @param overdue       if true, the update is overdue already - should result in a firm title
         * @return {string}
         */
        getTitle(overdue) {
            return _("update.music-available.title");
        }

        /**
         * Specifies what icon to use when prompting for updates!
         * @return {string}
         */
        getUpdateIcon() {
            return Icon.AudioZone.NOTE;
        }

        // ---------- METHODS that may be overridden -----------------

        /**
         * Returns the update info part for this platform with the proper update level
         * @return {*}
         */
        getUpdateInfo() {
            Debug.Update.MusicUpdate && console.log(this.name + ": getUpdateInfo");
            var info = super.getUpdateInfo(...arguments);

            if (info) {
                info.changelog = this._getChangelog();
            } else {
                console.error("No update info for " + this.name);
            }

            return info;
        }

        /**
         * Returns the string that is used to store the data of this platforms update handling
         * @return {string}
         */
        getStorageName() {
            return this.name + this.musicServer.name;
        }

        /**
         * Will return true if an update is available based on the current version and the current updateInfo for
         * this platform.
         * @return {boolean}
         */
        isUpdateAvailable() {
            var available = super.isUpdateAvailable(...arguments); // Don't promote updates for a music server that isn't running!

            available = available && this.isServerOn; // never promote Music Server updates to users without admin rights

            available = available && SandboxComponent.hasAdminPermission();
            return available;
        }

        // -------------------- Delegates -----------------------------------------
        receivedStates(states) {
            Debug.Update.MusicUpdate && console.log("receivedStates: " + JSON.stringify(states));
            var def = this.statesDef;
            this.currentVersion = states.version;
            this.isServerOn = states.serverState === MediaEnum.ServerState.ONLINE;

            if (def) {
                this.statesDef = false; // reset

                def.resolve();
            }
        }

        // --------------------- Private Methods ----------------------------------

        /**
         * Loads the required update data from the Music Server
         * @private
         */
        _loadData() {
            Debug.Update.MusicUpdate && console.log(this.name + ": _loadData");
            var promises = []; // Current Version

            promises.push(this._requestCurrentVersion()); // Update Level

            promises.push(this._requestUpdateLevel());
            return Q.all(promises);
        }

        /**
         * Requests the current version of the Music Server. It can be read from the states.
         * @private
         */
        _requestCurrentVersion() {
            if (this.currentVersion) {
                return Q.when(this.currentVersion);
            } else {
                if (!this.statesDef) {
                    this.statesDef = Q.defer();
                }

                return this.statesDef.promise;
            }
        }

        /**
         * Will request the update level of the Music Server.
         * @return {*}
         * @private
         */
        _requestUpdateLevel() {
            Debug.Update.MusicUpdate && console.log(this.name + ": _requestUpdateLevel");
            var prms;

            if (this.updateLevel) {
                prms = Q.when(this.updateLevel);
            } else {
                // Older music servers cannot handle the cache buster attributes properly, that is why the cache is enabled here. https://www.wrike.com/open.htm?id=167129665
                prms = CommunicationComponent.sendViaHttpUnauthorized(MediaEnum.Commands.UPD_LVL, this.musicServer.host, true);
                prms.then(this._handleUpdateLevelReceived.bind(this), this._handleUpdateLevelReceived.bind(this) // error won't be parsable, Release is assumed.
                );
            }

            return prms;
        }

        /**
         * Returns the path to the Music Server (http://ip) with port 80 instead of 7091 as stored in the obj.
         * @return {string}
         * @private
         */
        _getChangelog() {
            var host = this.musicServer.host; // includes :7091 port, we need :80

            host = host.replace(":7091", ":80");
            return "http://" + host;
        }

        /**
         * Will be called once the music server responds with it's current update level.
         * @param result
         * @return {string} the update level currently configured on the Miniserver.
         * @private
         */
        _handleUpdateLevelReceived(result) {
            switch (result.UPDATELEVEL) {
                case UpdateComp.UpdateLevel.ALPHA_LEGACY:
                case UpdateComp.UpdateLevel.BETA:
                case UpdateComp.UpdateLevel.RELEASE:
                    // nothing to do.
                    this.updateLevel = res.UPDATELEVEL;
                    break;

                case "intern":
                    this.updateLevel = UpdateComp.UpdateLevel.ALPHA_LEGACY;
                    break;

                default:
                    this.updateLevel = UpdateComp.UpdateLevel.RELEASE;
                    break;
            }

            return this.updateLevel;
        }

    }

    Components.Update.extensions.MusicServerUpdateExt = MusicServerUpdateExt;
    return Components;
}(window.Components || {});
