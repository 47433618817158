'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var UPDATE_STORE_NAME = "UpdateStorage";

        class UpdateStorageExt extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);

                this._getUpdateStorage();

                this.registerExtensionEv(this.component.ECEvent.SaveStorage, this._saveUpdateStorage.bind(this));
                this.registerExtensionEv(this.component.ECEvent.MiniserverChanged, this._saveUpdateStorage.bind(this));
            }

            saveUpdateInfo(updateId, infoObj) {
                return this._getUpdateStorage().then(function (store) {
                    store[updateId] = infoObj;
                }.bind(this));
            }

            loadUpdateInfo(updateId) {
                return this._getUpdateStorage().then(function (store) {
                    return store[updateId];
                });
            }

            _getUpdateStorage() {
                if (this.storage) {
                    return Q.when(this.storage);
                } else {
                    return PersistenceComponent.loadFile(UPDATE_STORE_NAME, DataType.OBJECT).then(function (store) {
                        this.storage = store;
                        return store;
                    }.bind(this), function (err) {
                        // initialize a new one.
                        this.storage = {};
                        return this.storage;
                    }.bind(this));
                }
            }

            _saveUpdateStorage() {
                if (this.storage) {
                    PersistenceComponent.saveFile(UPDATE_STORE_NAME, this.storage, DataType.OBJECT);
                }
            }

        }

        Components.Update.extensions.UpdateStorageExt = UpdateStorageExt;
    }
    return Components;
}(window.Components || {});
