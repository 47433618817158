'use strict';

var VideoAdapter = {};
VendorHub.service('VideoAdapter', function () {
    var hasVideo = typeof Video !== 'undefined';
    var videoInitialized = false;
    var videoAdapter = {};
    var currentVideo;
    /**
     * This is the default configuration for video settings.
     * @type {{stretchedVideo: boolean}}
     */

    var DEFAULT_VIDEO_SETTINGS = {
        stretched: true
    };
    var VIDEO_SETTINGS_STORAGE_KEY = "IntercomVideoSettings";

    var videoUpdateHandler = function videoUpdateHandler(videoState) {
        //console.warn('received video update: ' + videoState.code + " - " + videoState.message);
        currentVideo.notify(videoState);
    };

    var videoErrorHandler = function videoErrorHandler(error) {
        console.error('received video error: ' + error.message);
        currentVideo.reject(error);
    };

    var videoCallUpdateHandler = function videoCallUpdateHandler(callInfo) {
        console.log(callInfo);
        var callStates;

        if (!callInfo.code) {
            callInfo = {
                message: "fix",
                isClosed: false,
                code: callInfo
            };
        }

        if (currentVideo) {
        } else {
            console.error('received call update even though no call is active: [' + callInfo.code + '] - ' + callInfo.message);
        }
    };

    var videoCallErrorHandler = function videoCallErrorHandler(reason) {
        console.log('video error!');

        if (currentVideo) {
            currentVideo.reject(reason);
            currentVideo = null;
        }
    };

    videoAdapter.playVideo = function playVideo(path, user, pass) {
        if (currentVideo) {
            videoAdapter.stopVideo();
            currentVideo.reject('new call has been made');
        }

        currentVideo = Q.defer();

        if (hasVideo) {
            Video.playVideo(path, user, pass, videoUpdateHandler, videoErrorHandler);
        } else {
            currentVideo.reject('Video not supported');
        }

        return currentVideo.promise;
    };

    videoAdapter.stopVideo = function stopVideo() {
        console.log("videoAdapter stopVideo");

        if (hasVideo) {
            Video.stopVideo(function success(s) {
                console.log("stop video successful " + s);
            }, function error(e) {
                console.log("error while stopping video" + e);
            });
        } else {
            console.log("video not supported!");
        }
    };

    videoAdapter.hasVideo = hasVideo; // ----------------------------------------------------------------------------------------
    //                  Video Settings
    // ----------------------------------------------------------------------------------------

    /**
     * Will load the video settings for this intercom. if not provided, it'll return a default
     * settings object.
     * @param intercomUuid  the uuidAction of the intercom we're intereseted in.
     * @returns {null}
     */

    videoAdapter.getVideoSettings = function getVideoSettings(intercomUuid) {
        var settingsCntr = _getCurrentVideoSettingsCntr(),
            settings = settingsCntr ? settingsCntr[intercomUuid] : null;

        if (!settings) {
            settings = DEFAULT_VIDEO_SETTINGS;
        }

        return settings;
    };
    /**
     * Will store the settings object provided for this intercom
     * @param intercomUuid  what intercom to save the settings for
     * @param settings      the settings for the intercom video.
     */


    videoAdapter.storeVideoSettings = function storeVideoSettings(intercomUuid, settings) {
        var settingsCntr = _getCurrentVideoSettingsCntr();

        settingsCntr[intercomUuid] = settings;
        PersistenceComponent.saveForMiniserver(VIDEO_SETTINGS_STORAGE_KEY, settingsCntr);
    };
    /**
     * Helper method that retrieves the settings from the persistence comp and returns an empty obj if no
     * settings have been stored so far.
     * @returns {*}
     * @private
     */


    var _getCurrentVideoSettingsCntr = function _getCurrentVideoSettingsCntr() {
        var settingsCntr = PersistenceComponent.getForMiniserver(VIDEO_SETTINGS_STORAGE_KEY);

        if (!settingsCntr) {
            settingsCntr = {};
        }

        return settingsCntr;
    };

    VideoAdapter = videoAdapter;
    return videoAdapter;
});
