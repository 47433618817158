'use strict';

var LoxoneControl = function () {
    return class LoxoneControl {
        constructor() {
        }

        hasLoxoneControl() {
            return window.hasOwnProperty("loxoneControl");
        }

        /**
         * Will either resolve with a roomUuid or with nothing if the presenceDetector is deactivated
         */
        getPresenceRoomUuid() {
            var def = Q.defer(),
                result;

            if (this.hasLoxoneControl()) {
                result = loxoneControl.getPresenceControlRoomUUID(); // since mid 2020 we're supporting multiple presence blocks per room, so the result may be an object
                // containing a room uuid instead of just returning the room uuid.

                if (typeof result === 'object' && result !== null && result.roomUuid) {
                    def.resolve(result.roomUuid);
                } else {
                    def.resolve(result);
                }
            } else {
                def.resolve();
            }

            return def.promise;
        }

        // Application Manager
        isVisibleInStatusBar() {
            if (this.hasLoxoneControl()) {
                return loxoneControl.isVisibleInStatusBar();
            } else {
                return false;
            }
        }

        isStartingWithSystem() {
            if (this.hasLoxoneControl()) {
                return loxoneControl.doesAppStartWithSystem();
            } else {
                return false;
            }
        }

        setVisibleInStatusBar(shouldBeVisible) {
            var defer = Q.defer();
            loxoneControl.showStatusBarIcon(shouldBeVisible);
            this.setStartWithSystem(shouldBeVisible).done(function () {
                defer.resolve();
            }, function () {
                defer.reject();
            });
            return defer.promise;
        }

        setStartWithSystem(shouldStart) {
            var defer = Q.defer();
            defer.resolve(loxoneControl.setAsStartupApp(shouldStart));
            return defer.promise;
        }

        // Storage Manager
        archiveDidChange() {
            loxoneControl.archiveDidChange();
        }

        structureDidChange(serialNo) {
            loxoneControl.structureDidChange(serialNo);
        }

        settingsDidChange() {
            loxoneControl.settingsDidChange(ActiveMSComponent.getMiniserverSerialNo());
        }

        quickActionsDidChange() {
            loxoneControl.quickActionsDidChange();
        }

    };
}();
