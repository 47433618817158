"use strict";

var QuickActionUtility = function () {
    var DEFAULT_QUICK_ACTION_URL = "loxone://?loc=quickactions",
        DEFAULT_QUICK_ACTION_UUID = "B6D6599B-1645-4CE7-816A-D33A186E9643"; // keep synchronous with native platform

    function QuickActionAdapter() {
        this._quickActions = [];
        this.isAvailable = false;

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
            this.isAvailable = true;
            this._quickActions = PersistenceComponent.getDevQuickActions();

            this._checkQuickActions();

            this.setActions(this._quickActions);
        } else if (window.hasOwnProperty("quickActions")) {
            quickActions.isAvailable(function (isAvailable) {
                this.isAvailable = isAvailable;

                if (isAvailable) {
                    quickActions.getItems(function (actions) {
                        this._quickActions = actions;

                        this._checkQuickActions();

                        this.setActions(this._quickActions);
                        VendorHub.Usage.quickActions(FeatureUsage.QuickActions.LIST, null, this._quickActions.length);
                    }.bind(this), function () {
                        this._quickActions = [];
                    }.bind(this));
                }
            }.bind(this));
        }
    }

    QuickActionAdapter.prototype.verifyQuickActions = function verifyQuickActions() {
        Debug.QuickActions && console.log("QuickAction: verifyQuickActions");

        if (this.isAvailable) {
            this._checkQuickActions();

            this.setActions(this._quickActions);
        }
    };

    QuickActionAdapter.prototype.hasQuickActions = function hasQuickActions() {
        return this.isAvailable;
    };

    QuickActionAdapter.prototype.addPosition = function addPosition(title, url) {
        Debug.QuickActions && console.log("QuickAction: addPosition: title: " + title + " url: " + url); // check params, subtitle can be empty

        if (!title || !url) {
            var def = Q.defer();
            def.reject(new Error("missing params!"));
            return def.promise;
        } else {
            var serialNumber = ActiveMSComponent.getMiniserverSerialNo(),
                shortCutObj = {
                    uuid: url === DEFAULT_QUICK_ACTION_URL ? DEFAULT_QUICK_ACTION_UUID : generateUUID(),
                    serialNumber: serialNumber,
                    title: title[0].toUpperCase() + title.slice(1),
                    url: url
                };
            return this.addItem(shortCutObj);
        }
    };

    QuickActionAdapter.prototype.addCommand = function addCommand(cmdUrl, mainText, subText) {
        Debug.QuickActions && console.log("QuickAction: addCommand: cmdUrl: " + cmdUrl + " mainText: " + mainText + " subText: " + subText);
        var params = getURIParameters(cmdUrl); // We create an array of commands, so we can handle multiple commands

        var cmds = [params.cmd];

        if (params.cmd2) {
            cmds.push(params.cmd2);
        } // check params, subText can be empty


        if (cmds.length === 0 || !mainText || !subText && subText !== "") {
            var def = Q.defer();
            def.reject(new Error("missing params!"));
            return def.promise;
        } else {
            var serialNumber = ActiveMSComponent.getMiniserverSerialNo(),
                shortCutObj = {
                    uuid: generateUUID(JSON.stringify(cmds)),
                    serialNumber: serialNumber,
                    title: mainText,
                    subtitle: subText,
                    cmds: cmds
                };

            try {
                shortCutObj.url = this._getUrlStartForCommand(serialNumber, cmds[0]);
            } catch (e) {// omit
            }

            return this.addItem(shortCutObj);
        }
    };

    QuickActionAdapter.prototype.addItem = function addItem(shortCutObj) {
        Debug.QuickActions && console.log("QuickAction: addItem");
        VendorHub.Usage.quickActions(FeatureUsage.QuickActions.ADD);

        this._quickActions.push(shortCutObj);

        this._checkQuickActions();

        return this.setActions(this._quickActions);
    };

    QuickActionAdapter.prototype.setActions = function setActions(actions) {
        var def = Q.defer(); // Directly resolve the promise if its a Developer Webinterface, just for testing

        if (PlatformComponent.getPlatformInfoObj().platform === PlatformType.DeveloperInterface) {
            this._quickActions = actions;
            PersistenceComponent.setDevQuickActions(actions);
            def.resolve();
        } else {
            quickActions.setItems(function () {
                this._quickActions = actions;
                def.resolve();
            }.bind(this), function () {
                console.error("settings quick actions failed!");
                def.reject();
            }, actions);
        }

        return def.promise;
    };

    QuickActionAdapter.prototype.removeAction = function removeAction(uuid) {
        Debug.QuickActions && console.log("QuickAction: removeAction uuid: " + uuid);
        VendorHub.Usage.quickActions(FeatureUsage.QuickActions.REMOVE);
        this._quickActions = this._quickActions.filter(function (action) {
            return action.uuid !== uuid;
        });

        this._checkQuickActions();

        return this.setActions(this._quickActions);
    };

    QuickActionAdapter.prototype.getItems = function getItems(activeMSOnly) {
        var result = this._quickActions || [];
        result = result.filter(function (action) {
            return action.uuid !== DEFAULT_QUICK_ACTION_UUID;
        });

        if (activeMSOnly && ActiveMSComponent.getActiveMiniserver()) {
            var serialNo = ActiveMSComponent.getMiniserverSerialNo();
            result = result.filter(function (action) {
                return action.serialNumber === serialNo;
            });
        }

        return result;
    }; // Private methods


    QuickActionAdapter.prototype._checkQuickActions = function _checkQuickActions() {
        Debug.QuickActions && console.log("QuickAction: _checkQuickActions");
        var title;
        var platform = PlatformComponent.getPlatformInfoObj().platform;

        if (platform === PlatformType.Android) {
            title = _("shortcuts.android.new");
        } else {
            title = _('quick-action.create');
        }

        if (this._quickActions.length > 0 && !this._quickActions[0].uuid) {
            // check and convert old QuickActions
            Debug.QuickActions && console.info("converting old QuickActions to new..");
            this._quickActions = this._convertQuickActions(this._quickActions);

            this._checkQuickActions();

            this.setActions(this._quickActions);
            return;
        }

        if (this._quickActions.length > 1) {
            // go through all and remove the standard QuickAction..
            this._quickActions = this._quickActions.filter(function (action) {
                return action.uuid !== DEFAULT_QUICK_ACTION_UUID;
            });
        } else if (this._quickActions.length === 1 && this._quickActions[0].uuid === DEFAULT_QUICK_ACTION_UUID) {
            return;
        }

        var displayMsNames = this._shouldDisplayMsNames(),
            miniservers = PersistenceComponent.getAllMiniserver(),
            ms;

        this._quickActions.forEach(function (action) {
            if (displayMsNames) {
                // add Miniserver names
                ms = miniservers[action.serialNumber];
                action.msName = ms.msName;
            } else if (action.msName) {
                delete action.msName;
            }
        }); // check if we have stored the Miniserver


        this._quickActions = this._quickActions.filter(function (action) {
            return !action.serialNumber || this._validateQuickAction(miniservers, action);
        }.bind(this)); // add default if empty

        if (this._quickActions.length === 0) {
            this._quickActions.push({
                uuid: DEFAULT_QUICK_ACTION_UUID,
                title: title,
                url: DEFAULT_QUICK_ACTION_URL
            });
        }
    };
    /**
     * Will check whether an action is still valid.
     * @param miniservers
     * @param action
     * @returns {boolean}
     * @private
     */


    QuickActionAdapter.prototype._validateQuickAction = function _validateQuickAction(miniservers, action) {
        Debug.QuickActions && console.log("QuickAction: _validateQuickAction: " + JSON.stringify(action));
        var isValid = true,
            ms = miniservers[action.serialNumber],
            ctrlUuid,
            ctrl; // at first verify a miniserver still exists

        if (!ms) {
            console.error("QuickAction: Dropping invalid quickaction! Miniserver not found. " + JSON.stringify(action));
            isValid = false;
        } else if (action.serialNumber !== ActiveMSComponent.getMiniserverSerialNo()) {// not an active Miniserver, cannot update/validate the quickaction
        } else if (action.cmds && action.cmds.length > 0) {
            // active Miniserver --> dig deeper.
            ctrlUuid = UrlHelper.getControlUUIDFromCommandString(action.cmds[0]);
            ctrl = ActiveMSComponent.getControlByUUID(ctrlUuid);

            if (ctrl) {
                this._validateQuickActionForCtrl(action, ctrl, action.cmds[0]);
            } else {
                // may happen during startup of android
                console.error("QuickAction: Control not found. " + JSON.stringify(action) + ", cannot validate");
            }
        } else {// probably a location, not minded here.
        }

        return isValid;
    };
    /**
     * Will ensure both title and subtitle are properly set on the action/scene provided.
     * @param action    the action object (quickaction)
     * @param ctrl      the control of which this action origins from
     * @param cmd       the (first) command that is executed when this action is performed
     * @private
     */


    QuickActionAdapter.prototype._validateQuickActionForCtrl = function _validateQuickActionForCtrl(action, ctrl, cmd) {
        Debug.QuickActions && console.log("QuickAction: _validateQuickActionForCtrl: " + ctrl.getControlTypeName() + " - " + ctrl.getName());
        var taskRecName = null,
            cleanedCmd = null; // check title

        if (ctrl.type === ControlType.PUSHBUTTON && ctrl.isAutomaticScene && ctrl.getName() !== action.title) {
            // if it's a scene, check if the name has changed!
            Debug.QuickActions && console.log("   scene title updated! now is: " + ctrl.getName());
            action.title = ctrl.getName();
        } else {
            // regular control, use task recorder logic to verify name is up to date!
            cleanedCmd = cmd.replace("jdev/sps/io/", "").replace("jdev/sps/ios/", "");
            taskRecName = SandboxComponent.getTaskNameForControlCommand(cleanedCmd, ctrl.uuidAction);

            if (taskRecName !== action.title) {
                Debug.QuickActions && console.log("   task title updated! now is: " + taskRecName);
                action.title = taskRecName;
            }
        } // Check subtitle


        if (ctrl.groupDetail !== action.subtitle) {
            Debug.QuickActions && console.log("   subtitle updated! now is: " + ctrl.groupDetail);
            action.subtitle = ctrl.groupDetail;
        }
    };

    QuickActionAdapter.prototype._convertQuickActions = function _convertQuickActions(actions) {
        Debug.QuickActions && console.log("QuickAction: _convertQuickActions");
        var newActions = [],
            miniservers = PersistenceComponent.getAllMiniserver(),
            ms,
            action,
            newAction,
            i;

        for (i = 0; i < actions.length; i++) {
            action = actions[i];

            if (!action.serialNumber || !miniservers[action.serialNumber]) {
                continue;
            }

            newAction = null; // set to null, it may be not possible to convert quick action

            ms = miniservers[action.serialNumber];

            if (action.cmds && action.cmds.length > 0) {
                // command!
                var subtitle,
                    msNamePrefix = ms.msName + SEPARATOR_SYMBOL;

                if (typeof action.subtitle === "string") {
                    var idx = action.subtitle.indexOf(msNamePrefix);

                    if (idx !== -1) {
                        // remove msName prefix!
                        subtitle = action.subtitle.substr(msNamePrefix.length);
                    } else {
                        subtitle = action.subtitle;
                    }
                }

                newAction = {
                    uuid: generateUUID(),
                    serialNumber: action.serialNumber,
                    title: action.title,
                    subtitle: subtitle,
                    cmds: action.cmds
                };

                try {
                    newAction.url = this._getUrlStartForCommand(action.serialNumber, action.cmds[0]);
                } catch (e) {// omit
                }
            } else if (action.url) {
                // position!
                if (action.url === DEFAULT_QUICK_ACTION_URL) {
                    newAction = {
                        uuid: DEFAULT_QUICK_ACTION_UUID
                    };
                } else {
                    newAction = {
                        uuid: generateUUID()
                    };
                }

                newAction.serialNumber = action.serialNumber;
                newAction.title = action.title;
                newAction.url = action.url; // never use the action.subtitle, it only can be msName -> we don't need it anymore!
            }

            if (newAction) {
                newActions.push(newAction);
            }
        }

        return newActions;
    };

    QuickActionAdapter.prototype._shouldDisplayMsNames = function _shouldDisplayMsNames() {
        var actions = this._quickActions,
            shouldDisplayMsNames = false,
            serialNumber = actions && actions[0] && actions[0].serialNumber;
        actions.forEach(function (action) {
            if (serialNumber !== action.serialNumber) {
                shouldDisplayMsNames = true;
            }
        });
        return shouldDisplayMsNames;
    };

    QuickActionAdapter.prototype._getUrlStartForCommand = function _getUrlStartForCommand(serialNo, cmdStr) {
        return UrlHelper.createURLStart({
            mac: serialNo
        }, {
            control: UrlHelper.getControlUUIDFromCommandString(cmdStr)
        });
    };

    return QuickActionAdapter;
}();
