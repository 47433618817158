var BaseDownloader = class {
    constructor() {
        this.name = this.constructor.name;
    }

    load() {
        //fast-class-es6-converter: auto generated
        throw "Implement the 'load' function in your class";
    }

};
