'use strict';
/**
 * Used to get IPs from serialnumbers via cloudDNS. Used for both remote connect and cloud dns.
 * Capable of handling multiple datacenters to query for the resolved IP.
 *
 * Initially all clients will try to access the default datacenter loxonecloud.com, if this request
 * times out, the chinese datacenter will be tried as fallback.
 *
 * When an IP can be resolved, the data center that resolved will be returned, so it can be stored
 * on the miniserver and used for the next resolve attempts by passing it as knowDataCenter.
 */

var ConnectivityTools = function (ConnectivityTools) {
    var ResponseAttr = {
        DATA_CENTER: "DataCenter",
        PORT_OPEN_HTTP: "PortOpen",
        PORT_OPEN_HTTPS: "PortOpenHTTPS",
        DNS_STATUS: "DNS-Status",
        REMOTE_CONNECT: "RemoteConnect",
        IP_HTTP: "IP",
        IP_HTTPS: "IPHTTPS",
        CODE: "Code"
    };
    var DataCenter = {
        DEFAULT: "loxonecloud.com"
    };
    var RESOLVE_REQUEST = "https://dns.%s/?getip&snr=%s&json=true";
    var INITIAL_DNS_TIMEOUT = 1000;
    var MAX_DNS_TIMEOUT = 4000;

    class CloudDNSResolver {
        constructor() {
            this.name = "CloudDNSResolver";
            Debug.CloudDNS && console.log(this.name, " + ctor");
            this.region = getDeviceRegionCode();
        }

        destroy() {
            Debug.CloudDNS && console.log(this.name, "- destroy");
            this.stop();
        }

        resolve(snr, knownDataCenter) {
            Debug.CloudDNS && console.log(this.name, "resolve " + snr + " from: " + knownDataCenter || "-defaultDataCenter-"); // use a deferred instead of the requests promise --> there may be two resolve requests required (dataCenter)!

            this._resolveDeferred = Q.defer(); // cancel potentially active old requests.

            this._pendingRequest && this._stopRequest(); // store new data

            this._snr = snr; // initialize data center list.

            this._initializeDataCenterStorage(knownDataCenter); // initialize timeout setting


            this.__dnsTimeout = INITIAL_DNS_TIMEOUT; // start request

            this._startRequest();

            return this._resolveDeferred.promise;
        }

        stop() {
            Debug.CloudDNS && console.log(this.name, "stop");

            this._stopRequest();
        }

        isPending() {
            return this._pendingRequest !== null;
        }

        // ------------------------------------------------------------------------------------------------
        //    Private Methods
        // ------------------------------------------------------------------------------------------------
        _startRequest() {
            Debug.CloudDNS && console.log(this.name, "_startRequest (Timeout: " + this.__dnsTimeout + "ms)");

            var reqUrl = this._getRequestUrl();

            this._pendingRequest = $.ajax(reqUrl, {
                dataType: "json",
                cache: false,
                timeout: this.__dnsTimeout
            });
            Debug.Communication && CommTracker.track(this._pendingRequest, CommTracker.Transport.LXSVR, reqUrl);
            return this._pendingRequest.then(this._handleResponse.bind(this), this._handleFail.bind(this));
        }

        _stopRequest() {
            Debug.CloudDNS && console.log(this.name, "_stopRequest");
            this._pendingRequest && this._pendingRequest.abort();
            this._pendingRequest = null;
        }

        _getRequestUrl() {
            var rqUrl = sprintf(RESOLVE_REQUEST, this._getCurrentDataCenter(), this._snr);
            rqUrl += "&rnd=" + getRandomIntInclusive(0, 100000); // random portion to avoid potential caching.

            Debug.CloudDNS && console.log(this.name, "_getRequestUrl: " + rqUrl);
            return rqUrl;
        }

        _handleResponse(data) {
            Debug.CloudDNS && console.log(this.name, "_handleResponse: " + JSON.stringify(data));
            this._pendingRequest = null;
            var code = data[ResponseAttr.CODE],
                dataCenter = data[ResponseAttr.DATA_CENTER],
                portOpenHttp,
                portOpenHttps;

            if (dataCenter && dataCenter !== "") {
                Debug.CloudDNS && console.log(this.name, "   response contains a dataCenter: " + dataCenter);

                this._addDataCenter(dataCenter);
            }

            if (code === CloudDnsErrorCode.OK) {
                // okay, check ports!
                portOpenHttp = data[ResponseAttr.PORT_OPEN_HTTP];
                portOpenHttps = data[ResponseAttr.PORT_OPEN_HTTPS];

                if (typeof portOpenHttp === "boolean" || typeof portOpenHttps === "boolean") {
                    // check if ports are open
                    if (portOpenHttp === true || portOpenHttps === true) {
                        this._resolveWith(data[ResponseAttr.IP_HTTP], data[ResponseAttr.IP_HTTPS], !!data[ResponseAttr.REMOTE_CONNECT], this._getCurrentDataCenter());
                    } else {
                        // Neither https nor http port open!
                        this._handleFailWith(ResponseCode.CLOUDDNS_PORT_CLOSED, data, null, this._getCurrentDataCenter());
                    }
                } else {
                    // port open info missing, some other fail
                    this._handleFailWith(ResponseCode.CLOUDDNS_ERROR, data, null, this._getCurrentDataCenter());
                }
            } else {
                this._handleFailWith(this._mapErrorCode(code), data, null, this._getCurrentDataCenter());
            }
        }

        _handleFail(jqXHR, textStatus, errorThrown) {
            this._pendingRequest = null;

            if (textStatus === "abort") {
                return; // not failed, just cancelled.
            }

            var mappedErrorCode;

            if (textStatus === "timeout") {
                return this._handleRequestTimeout();
            } else {
                mappedErrorCode = this._mapErrorCode(jqXHR.status);
                return this._handleFailWith(mappedErrorCode, jqXHR.responseJSON, errorThrown, this._getCurrentDataCenter());
            }
        }

        _mapErrorCode(code) {
            var mappedCode;

            switch (code) {
                case CloudDnsErrorCode.PORT_NOT_OPENED:
                    // Miniserver registered / Cloud DNS configured / Port not opened
                    mappedCode = ResponseCode.CLOUDDNS_PORT_CLOSED;
                    break;

                case CloudDnsErrorCode.CLOUD_DNS_NOT_CONFIGURED:
                    // Miniserver registered / Cloud DNS not configured
                    mappedCode = ResponseCode.CLOUDDNS_NOT_CONFIGURED;
                    break;

                case CloudDnsErrorCode.MS_NOT_REGISTERED:
                    // Miniserver not registered / Cloud DNS not configured
                    mappedCode = ResponseCode.CLOUDDNS_NOT_REGISTERED;
                    break;

                case CloudDnsErrorCode.SECURE_PWD_REQUIRED:
                    // admin:admin access is possible, we don't allow the connection to these Miniservers
                    mappedCode = ResponseCode.CLOUDDNS_SECURE_PWD_REQUIRED;
                    break;

                case CloudDnsErrorCode.DENIED_CUSTOM_MESSAGE:
                    // the external access is denied because of some reason
                    mappedCode = ResponseCode.CLOUDDNS_DENIED_CUSTOM_MESSAGE;
                    break;

                case CloudDnsErrorCode.MISSING_MQTT_CONNECTION:
                    // Miniserver is currently not connected to the MQTT Broker
                    mappedCode = ResponseCode.CLOUDDNS_MISSING_MQTT_CONNECTION;
                    break;

                case CloudDnsErrorCode.VPN_TIMEOUT:
                    // Miniserver has not connected to the VPN Server in the specified time
                    mappedCode = ResponseCode.CLOUDDNS_VPN_TIMEOUT;
                    break;

                case CloudDnsErrorCode.MS_REBOOT:
                    // Miniserver is rebooting
                    mappedCode = ResponseCode.MS_OUT_OF_SERVICE;
                    break;

                default:
                    mappedCode = ResponseCode.CLOUDDNS_ERROR;
            }

            Debug.CloudDNS && console.log(this.name, "_mapErrorCode: " + code + " -> " + mappedCode);
            return mappedCode;
        }

        /**
         * Will increase the timeout if possible. If not, it'll try to use other dataCenters - if there are any.
         * @private
         */
        _handleRequestTimeout() {
            Debug.CloudDNS && console.log(this.name, "_handleRequestTimeout (" + this.__dnsTimeout + "ms)");
            this.__dnsTimeout = this.__dnsTimeout * 2;
            this.__dnsTimeout = Math.min(this.__dnsTimeout, MAX_DNS_TIMEOUT);
            Debug.CloudDNS && console.log(this.name, "  new timeout: " + this.__dnsTimeout + "ms");

            if (this.__dnsTimeout >= MAX_DNS_TIMEOUT) {
                Debug.CloudDNS && console.log(this.name, "  try other datacenters!");

                if (this._dataCenterList.length <= 1) {
                    // no others available, fill up list with all possible ones
                    Object.values(DataCenter).forEach(value => {
                        this._addDataCenter(value);
                    });
                }

                this._moveToNextDataCenter();
            }

            this._startRequest();
        }

        _handleFailWith(errorCode, data, detail, dataCenter) {
            var errorInfo = {
                code: errorCode,
                data: data,
                detail: detail,
                dataCenter: dataCenter
            };
            Debug.CloudDNS && console.log(this.name, "_handleFailWith: " + JSON.stringify(errorInfo));

            this._dropCurrentDataCenter();

            if (this._getCurrentDataCenter() !== null) {
                this._startRequest();
            } else {
                this._rejectWith(errorInfo);
            }
        }

        _rejectWith(errorInfo) {
            Debug.CloudDNS && console.log(this.name, "_rejectWith: " + JSON.stringify(errorInfo));

            this._resolveDeferred.reject(errorInfo);
        }

        _resolveWith(ipHttp, ipHttps, isRemoteConnect, dataCenter) {
            var resolveInfo = {
                ipHttp: ipHttp,
                ipHttps: ipHttps,
                connectionType: isRemoteConnect ? CONNECTION_TYPE.REMOTE_CONNECT : CONNECTION_TYPE.CLOUD_DNS,
                dataCenter: dataCenter,
                snr: this._snr
            };
            Debug.CloudDNS && console.log(this.name, "_resolveWith: " + JSON.stringify(resolveInfo));

            this._resolveDeferred.resolve(resolveInfo);
        }

        _initializeDataCenterStorage(dataCenter) {
            Debug.CloudDNS && console.log(this.name, "_initializeDataCenterStorage: " + dataCenter);
            this._dataCenterList = [];
            this._droppedDataCenters = [];
            dataCenter && this._addDataCenter(dataCenter);

            if (this.region === "cn") {
                this._addDataCenter(DataCenter.CHINA);
            }

            this._addDataCenter(DataCenter.DEFAULT);
        }

        /**
         * Adds the datacenter(s) provided to the list of centers to try when checking reachability.
         * @param dataCenter {Array|String}
         * @private
         */
        _addDataCenter(dataCenter) {
            var intDataCenter = dataCenter;

            if (Array.isArray(intDataCenter)) {
                Debug.CloudDNS && console.warn(this.name, "_addDataCenter: array passed in, iterate & call recursively " + dataCenter);
                dataCenter.forEach(this._addDataCenter.bind(this));
            } else {
                var alreadyStored = this._dataCenterList.indexOf(intDataCenter) >= 0,
                    alreadyTried = this._droppedDataCenters.indexOf(intDataCenter) >= 0;

                if (!alreadyStored && !alreadyTried) {
                    Debug.CloudDNS && console.log(this.name, "_addDataCenter: " + intDataCenter);

                    this._dataCenterList.push(intDataCenter);
                } else if (alreadyStored) {
                    Debug.CloudDNS && console.log(this.name, "_addDataCenter: " + intDataCenter + " - ignore, already stored!");
                } else if (alreadyTried) {
                    Debug.CloudDNS && console.log(this.name, "_addDataCenter: " + intDataCenter + " - ignore, already tried!");
                }
            }
        }

        _getCurrentDataCenter() {
            var dataCenter = this._dataCenterList.length > 0 ? this._dataCenterList[0] : null;
            Debug.CloudDNS && console.log(this.name, "_getCurrentDataCenter: " + dataCenter);
            return dataCenter;
        }

        _dropCurrentDataCenter() {
            var droppedDataCenter = this._dataCenterList.splice(0, 1)[0];

            Debug.CloudDNS && console.log(this.name, "_dropCurrentDataCenter: " + droppedDataCenter);

            this._droppedDataCenters.push(droppedDataCenter);
        }

        /**
         * If there is more than one dataCenter available, it will retry the next one after this call.
         * Will move the current data center to the back of the list.
         * @private
         */
        _moveToNextDataCenter() {
            if (this._dataCenterList.length <= 1) {
                return;
            }

            var poppedDc = this._dataCenterList.splice(0, 1);

            if (poppedDc && poppedDc.length > 0) {
                this._dataCenterList.push(poppedDc[0]);
            }
        }

    }

    ConnectivityTools.CloudDNSResolver = CloudDNSResolver;
    return ConnectivityTools;
}(ConnectivityTools || {});
