"use strict";

var Commands = {
    API_INFORMATION: "jdev/cfg/api",
    API_KEY_INFORMATION: "jdev/cfg/apiKey",
    MAC_INFORMATION: "jdev/cfg/mac",
    // if api fails, try mac - identifies miniserver that are on an old cfg
    GET_KEY: "jdev/sys/getkey",
    AUTHENTICATE: "authenticate/%s",
    // hash user:pw
    CONFIG_VERSION: "jdev/cfg/version",
    // === DEPRECATED ===
    // Use INITIAL_APP_INFO instead, this webservice combines the three deprecated webservices in one
    STRUCTURE_FILE_DATE: "jdev/sps/LoxAPPversion3",
    STRUCTURE_FILE: "data/LoxAPP3.json",
    GET_DATE_TIME: "jdev/sys/SetDateTime",
    // === DEPRECATED ===
    INITIAL_APP_INFO: "jdev/sys/appinitinfo",
    // === DEPRECATED ===
    SET_DATE_TIME: "jdev/sys/SetDateTime/%s",
    SET_DATE_TIME_UTC: "jdev/sys/setdatetimeutc/%s", // since 14.2.5.23 - sets the date and time in UTC to avoid time zone issues
    // 2015-09-14 12:13:05
    TIMEZONE: "jdev/cfg/timezoneoffset",
    ENABLE_STATE_UPDATE: "jdev/sps/enablebinstatusupdate",
    REQUEST_STATES_FOR: "jdev/sps/requeststatesfor/%s",
    // A comma separated list of control UUIDs
    REBOOT_MINISERVER: "jdev/sys/reboot",
    REBOOT_AUDIO_SERVICES: "jdev/sps/restartaudio",
    AUTO_UPDATE: "jdev/sys/autoupdate",
    GET_LICENSES: "/licenses",
    UPDATE_LEVEL: "jdev/cfg/updatelevel",
    // without args it "gets" the current update level.
    GET_FILE: "dev/fsget/%s",
    // Path to file
    ADDONS: {
        GET_ADDON_LIST: "jdev/sps/addons/getlist",
        CREATE_ADDON: "jdev/sps/addons/create/%s",
        // %s id of the new addon
        DELETE_ADDON: "jdev/sps/addons/delete/%s",
        // %s control uuid
        MODIFY_ADDON: "jdev/sps/addons/modify/%s",
        // %s control uuid
        GET_PLUGIN_OWNER: "getUser",
        // %s uuid plugin control
        HOMEKIT_STATUS: "status",
        // get homekit plugin status
        HOMEKIT_PAIRING_INFO: "pairingInfo",
        // get pairing info
        HOMEKIT_UNPAIR: "unpair",
        // unpair homekit plugin
        HOMEKIT_GET_ACCESSORIES_LIST: "accessoriesList",
        // get the accessories list
        HOMEKIT_UPDATE_ACCESSORIES_LIST: "accessoriesUpdate",
        // updates the accessories list
        HOMEKIT_SET_USER: "setApiUser/%s",
        // %s uuid of the HomeKit api user
        HOMEKIT_RESTART: "exit" // exits the addon, then restarts

    },
    GET_ALL_ICONS: "jdev/sps/geticonlist",
    USERS: {
        //GET_USERGROUPS: "jdev/sps/getusergroups",
        //CREATE_USERGROUP: "jdev/sps/createusergroup/%s",    // name
        //UPDATE_USERGROUP: "jdev/sps/updateusergroup/%s/%s", // uuid, JSON String URLComponent encoded
        //DELETE_USERGROUP: "jdev/sps/deleteusergroup/%s",    // uuid
        GET_USERS: "jdev/sps/getuserlist",
        GET_USERS_V2: "jdev/sps/getuserlist2",
        // with this command you get a smaller version of the userlist
        //CREATE_USER: "jdev/sps/createuser/%s",              // name
        //UPDATE_USER: "jdev/sps/updateuser/%s/%s",           // uuid, JSON String URLComponent encoded
        //DELETE_USER: "jdev/sps/deleteuser/%s",              // uuid
        UPDATE_USER_PWD: "jdev/sps/updateuserpwd/%s/%s",
        // user uuid, pw
        UPDATE_USER_VISU_PWD: "jdev/sps/updateuservisupwd/%s/%s",
        // user uuid, visu pw
        UPDATE_USER_ACCESS_CODE: "jdev/sps/updateuseraccesscode/%s/%s",
        // user uuid, access code
        CHANGE_USER_PASSWORD: "jdev/sps/editUser/%s/%s",
        // username, pw (legacy)
        HASHED_CHANGE_VISU_PWD: "jdev/sps/updateuservisupwdh/%s/%s",
        // user uuid, sha1 or sha256 hashed visu pw 159744071
        HASHED_CHANGE_PWD: "jdev/sps/updateuserpwdh/%s/%s", // username, sha1 or sha256 hashed pw 159744071
        GET_CUSTOM_CAPTIONS: "jdev/sps/getcustomuserfields",
        VERIFY_UNIQUE_ID: "jdev/sps/checkuserid/%s", // used to check if an unique id is really unique. returns the uuid of any user who already has that uuid
        GET_PROPERTY_OPTIONS: "jdev/sps/getuserpropertyoptions", // returns an object with e.g. the existing companies or departments.
    },
    ENCRYPTION: {
        GET_PUBLIC_KEY: "jdev/sys/getPublicKey",
        KEY_EXCHANGE: "jdev/sys/keyexchange/%s",
        // RSA encrypted session key+iv in base64
        AUTHENTICATE: "authenticateEnc/%s",
        // AES encrypted hash in base64
        AES_PAYLOAD: "salt/%s/%s",
        // [salt, payload] --> this is the part that will be AES encrypted.
        AES_NEXT_SALT: "nextSalt/%s/%s/%s",
        // [currSalt, nextSalt, payload] --> this is the part that will be AES encrypted.
        RSA_PAYLOAD: "%s:%s",
        // [aesKey, aesIV] --> this the session key that will be RSA encrypted (on HTTP commands)
        RSA_CMD: "%s?sk=%s",
        // [encCmd, rsaSessionKey] --> this will be appended to the AES encrypted http command.
        COMMAND: "jdev/sys/enc/%s",
        // cipher
        COMMAND_AND_RESPONSE: "jdev/sys/fenc/%s" // cipher, also the response will be encoded

    },
    USER_MANAGEMENT: {
        GETGROUPLIST: "jdev/sps/getgrouplist",
        DELETE_USER: "jdev/sps/deleteuser/%s",
        ADD_USER: "jdev/sps/createuser/%s",
        ADDOREDIT_USER: "jdev/sps/addoredituser/%s",
        GETUSER: "jdev/sps/getuser/%s",
        GET_TRUST_PEERS: "jdev/sps/trustusermanagement/peers",
        GET_PEER_USERS: "jdev/sps/trustusermanagement/discover/%s",
        // %s = peer serial
        EDIT_PEER_USERS: "jdev/sps/trustusermanagement/edit" // The payload needs to be transported as HTTP Post Data

    },
    DEVICE_MANAGEMENT: {
        GET_RESULTS: 'jdev/sps/livelearn/results',
        GET_DEVICE_TYPES: 'jdev/sps/io/%s/getdevicetypes',
        CREATE: 'jdev/sps/livelearn/create/%s/%s/%s',
        // extension serialNr - device serialNr - config JSON as string
        REPLACE: 'jdev/sps/livelearn/replace/%s/%s',
        // new device serial - old device uuid
        GET_AVAILABLE_EXTENSIONS: 'jdev/sps/livelearn/list',
        GET_AVAILABLE_EXTENSIONS_RAW: 'jdev/sps/livelearn/listraw', // available since 2023.02.09
        START_SEARCH_ON_EXTENSION: 'jdev/sps/livelearn/search/%s/%s',
        // extension serial | device-identifier
        STOP_SEARCH_ON_EXTENSION: 'jdev/sps/livelearn/stop',
        START_IDENTIFY: 'jdev/sps/livelearn/identify/%s/%s',
        // extension serial | device serial
        STOP_IDENTIFY: 'jdev/sps/livelearn/identify/%s/0',
        STOP_IDENTIFY_UNIVERSAL: "jdev/sps/livelearn/identifystop",
        IDENTIFY_LIGHTGROUP: "jdev/sps/livelearn/identifylightgroup/%s", // group-uuid
        // extension serial | 0 means no device
        LEARN_KEEPALIVE: 'jdev/sps/livelearn/keepalive',
        GET_LIGHT_GROUPS: 'jdev/sps/livelearn/getoptions/lightgroup',
        GET_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/switchboard',
        GET_HEAT_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/heatswitchboard',
        GET_WALLBOX_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/wallboxswitchboard',
        CREATE_LIGHT_GROUP: 'jdev/sps/livelearn/createlightgroup/%s/%s',
        // name | configJson as string
        CREATE_ROOM: 'jdev/sps/createroom/%s', // creates a new room with name (%s)
        GW_GET_LIGHT_GROUPS: 'jdev/sps/livelearn/getoptions/all_lightgroup',
        GW_GET_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/all_switchboard',
        GW_GET_HEAT_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/all_heatswitchboard',
        GW_GET_WALLBOX_SWITCHBOARDS: 'jdev/sps/livelearn/getoptions/all_wallboxswitchboard',
        GET_AVAILABLE_MINISERVERS: 'jdev/sps/livelearn/listtree/miniservers', // returns a list of miniservers with their technologies, or an empty list
        GET_AVAILABLE_TECHNOLOGIES: 'jdev/sps/livelearn/listtree/techs/-1',
        GET_AVAILABLE_TECHNOLOGIES_FOR_MINISERVER: 'jdev/sps/livelearn/listtree/techs/%s/%s', // snr for specific in a C-GW setups | -1 for all, tag
        GET_AVAILABLE_DEVICES_FOR_TECHNOLOGY: 'jdev/sps/livelearn/listtree/techs/%s/%s/devs', // snr, tag
        GET_AVAILABLE_DEVICES_FOR_EXTENSION: 'jdev/sps/livelearn/listtree/techs/%s/%s/devs/%s', // snr, tag, extension snr
    },
    TOKEN: {
        GET_USERSALT: "jdev/sys/getkey2/%s",
        // [user] --> requests both a one-time-salt (key) and a user-salt
        GET_VISUSALT: "jdev/sys/getvisusalt/%s",
        // [user] --> requests both a one-time-salt (key) and a salt for the users visu-pw
        GET_TOKEN: "jdev/sys/gettoken/%s/%s/%d/%s/%s",
        // [hash, user, type, uuid, info] --> requests a token, type specifies the lifespan, uuid is used to identify who requested the token & info is a userfriendly info on the platform/device used.
        GET_TOKEN_ID: "jdev/sys/gettoken/",
        // to detect the command
        AUTHENTICATE: "authwithtoken/%s/%s",
        // [hash, user]
        AUTHENTICATE_ID: "authwithtoken/",
        // [hash, user] --> to detect the command
        REFRESH: "jdev/sys/refreshtoken/%s/%s",
        // [tokenHash, user]    // from 10.0.9.13 on the token might change after refreshing
        CHECK: "jdev/sys/checktoken/%s/%s",
        // [tokenHash, user]    // available since 10.0.9.13, successful if the token is valid.
        KILL: "jdev/sys/killtoken/%s/%s",
        // [tokenHash, user]
        AUTH_ARG: "autht=%s&user=%s",
        // [tokenHash, user]
        HTTP_AUTH: "%s?autht=%s&user=%s",
        // [cmd, tokenHash, user]
        GET_JWT_TOKEN: "jdev/sys/getjwt/%s/%s/%d/%s/%s",
        // [hash, user, type, uuid, info] --> requests a JSON web token, type specifies the lifespan, uuid is used to identify who requested the token & info is a userfriendly info on the platform/device used.
        REFRESH_JWT: "jdev/sys/refreshjwt/%s/%s",
        // [tokenHash, user]    --> will refresh a JWT token (= return a new one) or it will convert an existing plain token to a JWT Token
        GET_JWT_WITH_TOKEN_HASH: "jdev/sys/getjwt/%s/%s/%d/%s/%s?tokenHash=true", // [tokenHash, user, type, uuid, info] --> requests a JSON web token, type specifies the lifespan, uuid is used to identify who requested the token & info is a userfriendly info on the platform/device used.
        GET_JWT_WITH_BEARER_HEADER: "jdev/sys/getjwt/bearer/%s/%d/%s/%s", // MUST be TLS [user, type, uuid, info] --> requests a JSON web token, type specifies the lifespan, uuid is used to identify who requested the token & info is a userfriendly info on the platform/device used.
        GET_JWT_WITH_BEARER_ARG: "jdev/sys/getjwt/bearer/%s/%d/%s/%s?auth_token=%s" // Can be used with ApplLayerEnc. [user, type, uuid, info, uriEncodedBearer] --> requests a JSON web token, type specifies the lifespan, uuid is used to identify who requested the token & info is a userfriendly info on the platform/device used.
    },
    HTTP_AUTH: "%s?auth=%s",
    // [cmd, authHash]
    ControlSettings: {
        LOAD: "jdev/sps/io/%s/edit/load",
        // uuid
        VERIFY: "jdev/sps/io/%s/edit/verify/%s/%s",
        // uuid, id, value
        SAVE: "jdev/sps/io/%s/edit/save",
        // uuid
        CANCEL: "jdev/sps/io/%s/edit/cancel",
        // uuid
        REFRESH: "jdev/sps/io/%s/edit/refresh",
        // uuid --> will keep the expert mode session alive.
        RATING: "jdev/sps/setrating/%s/%i/%i" // uuid, rating, is favorite // TODO remove

    },
    ControlSettingsLight: {
        LOAD: "jdev/sps/io/%s/editlight/load",
        // uuid
        VERIFY: "jdev/sps/io/%s/editlight/verify/%s/%s",
        // uuid, id, value
        SAVE: "jdev/sps/io/%s/editlight/save",
        // uuid
        CANCEL: "jdev/sps/io/%s/editlight/cancel" // uuid

    },
    ControlPreset: {
        RESET_CTRL: "jdev/sps/io/%s/resettodefault",
        // control uuid
        UPDATE_CTRL_PRESET: "jdev/sps/io/%s/updatecontroldefault",
        // control uuid
        RESET_ALL: "jdev/sps/resettodefaultall/%s",
        // preset uuid
        GET_CTRL_INFO: "jdev/sps/io/%s/getcontrolpresetinfo" // control uuid

    },
    ControlHistory: {
        GET: "jdev/sps/io/%s/gethistory"
    },
    PNS: {
        REGISTER: "jdev/pns/register/v1/%s/%s",
// platform ('android', 'ios'), devicePushToken
        REGISTER_WITH_INFO: "jdev/pns/register/v1/%s/%s/%s",
        // platform ('android', 'ios'), devicePushToken, deviceInfo = {manufacturer model};{name} - both uriEncoded
        CHECK_REG: "jdev/pns/checkregistration/v1/%s",
        // devicePushToken
        CHECK_REG_WITH_INFO: "jdev/pns/checkregistration/v1/%s/%s",
        // devicePushToken, deviceInfo = {manufacturer model};{name} - both uriEncoded
        UNREGISTER: "jdev/pns/unregister/v1/%s",
        // pushToken
        SET_SETTINGS: "jdev/pns/settings/v1/%s/%s",
        // pushToken, settings
        GET_SETTINGS: "jdev/pns/settings/v1/%s",
        // pushToken
        MARK_READ: "jdev/pns/readMessage/v1/%s" // uid array
        //MARK_READ: "jdev/pns/markread/v1/%s",                  // uid of notification
        //GET_UNREAD: "jdev/pns/getunread/v1",
        //GET_HISTORY: "jdev/pns/gethistory/v1",
        //GET_HISTORY_FILTERD: "jdev/pns/gethistory/v1/%s"      // ts

    },
    CONTROL: {
        COMMAND: "jdev/sps/io/%s/%s",
        // uuid, command
        SECURED_COMMAND: "jdev/sps/ios/%s/%s/%s",
        // hash, uuid, command
        SECURED_DETAILS: "jdev/sps/io/%s/securedDetails",
        // uuid
        SECURED_DETAILS_V2: "jdev/sps/io/%s/securedDetails/%s",
        // uuid | internal/external
        SELECTED: "selectedcontrols/%s/%s",
        // selected control id's (, separated), command
        HELP_TEXT: "jdev/sps/io/%s/controlnotes",
        // returns the help text of the control (%s) its important to sanitize the response as it may contain HTML elements
        // available since 11.3.2.11 (Feature.CONTROL_LOCK_HANDLING)
        LOCK_CTRL: "lockcontrol/%i/%s",
        // islocked - boolean (true/false converted to 1/0) / uriEncoded reason
        UNLOCK_CTRL: "unlockcontrol"
    },
    STATISTIC: {
        STATISTIC_DATES_VERSION: "jdev/sps/getstatsdate",
        STATISTIC_DATES_FILE: "statistics.json",
        STATISTIC_DATES_FILE_FILTERED: "statistics.json/%s",
        // uuid of control
        DATA_MONTH: "binstatisticdata/%s/%d"
    },
    StatisticV2: {
        GET: "jdev/sps/getStatistic/%s/%s/%i/%i/%s/%s/%s", // ctrlUuid, raw/diff ,unixUtcFrom, unixUtcUntil, dataPointUnit, groupId, [outputName]
    },
    EnergyFlowMonitor: {
        GET_NODE_VALUE: "getNodeValue/%s/%s", // viewType, semi-colon-separated list of uuids
        GET: "get/%s",
    },
    TASK_RECORDER: {
        GET_TASKS: "jdev/sps/listcmds",
        // jdev/sps/removecmd/2014-07-22 19:02:00/Neuer Task/0a205ad1-035e-0c1a-ffffeee0009800b5/5
        DELETE_TASK: "jdev/sps/removecmd/%s/%s/%s",
        // date, name, command
        // jdev/sps/addcmd/2014-07-22 19:02:00/Neuer Task/0a205ad1-035e-0c1a-ffffeee0009800b5/5
        ADD_TASK: "jdev/sps/addcmd/%s/%s/%s",
        // date, name, command
        // jdev/sps/addscmd/2014-07-22 20:24:55/44bfa4f51a3eda3e021eda2656dfcc99a69a8934/Neuer Task/0a3d639e-00f1-141c-ffffeee0009800b5/1/off
        ADD_SEC_TASK: "jdev/sps/addscmd/%s/%s/%s/%s",
        // date, hash, name, command
        // API V2
        V2: {
            ADD_TASK: "jdev/sps/addtask/%s",
            // task string
            ADD_SEC_TASK: "jdev/sps/addsecuretask/%s/%s",
            // hash, task string
            UPDATE_TASK: "jdev/sps/updatetask/%s/%s",
            // task uuid, task string
            UPDATE_SEC_TASK: "jdev/sps/updatesecuretask/%s/%s/%s",
            // task uuid, hash, task string
            DELETE_TASK: "jdev/sps/removetask/%s" // task uuid

        }
    },
    MESSAGE_CENTER: {
        GET_ENTRIES: "getEntries/2",
        // 2 is the identifier for the app. This allows the Miniserver to display specific app related content
        EXEC_ACTION: "action/%s/%i",
        // entryUuid, actionId
        DELETE_ENTRY: "deleteEntry/%s",
        // entryUuid
        READ_ENTRY: "readEntry/%s" // entryUuid

    },
    AUTO_PILOT_GENERATOR: {
        GET_RULES: "getrules/%s",
        // for type
        ACTIVATE_RULE: "setactive/%s/%s",
        // uuid of rule, 0 or 1 as active state
        DELETE_RULE: "delete/%s",
        // delete by uuid
        // ==============================
        // NOTE: The appended JSON must not exceed a maximum number of characters which is browser dependent: https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
        ADD_OR_UPDATE_RULE: "add/%s",
        // adds or update a new rule, rule as json
        // ==============================
        EXECUTE_RULE: "execute/%s" // execute a rule with the given id

    },
    AUTOMATIC_DESIGNER: {
        GET_RULES: 'getrules/%s',
        // automatic designer uuid, type 1 === new automatic designer
        ACTIVATE_RULE: "setactive/%s/%i",
        // uuid of rule, 0 or 1 as active state
        DELETE_RULE: "delete/%s",
        // uuid of rule
        EXECUTE_RULE: "execute/%s",
        // uuid of rule
        LEGACY_GET_SECTIONS_DATA: "sections/get",
        // uuid of rule, 0 or 1 as active state
        GET_SECTIONS_DATA: "sections/getraw",
        // uuid of rule, 0 or 1 as active state
        GET_SECTIONS_VERSION: "sections/version",
        // uuid of rule, 0 or 1 as active state
        LEGACY_GET_CONTROLS_DATA: "capabilities/get",
        // uuid of rule, 0 or 1 as active state
        GET_CONTROLS_DATA: "capabilities/getraw",
        // uuid of rule, 0 or 1 as active state
        GET_CONTROLS_VERSION: "capabilities/version",
        // uuid of rule, 0 or 1 as active state
        ADD_OR_EDIT_RULE: "add/",
        // uuid of rule, 0 or 1 as active state,
        CONVERT_TASK: "converttask/%s",
        // Converts a Taskrecorder Task to an AutomaticDesigner rule
        CONVERT_TASK_POST: "converttask" // Converts a Taskrecorder Task to an AutomaticDesigner rule, sends the data as HTTP Post

    },
    BATTERY_MONITOR: {
        GET_DEVICE_STATES: "getdevicestates",
        // returns JSON Array (or Object on Gateways) with states of each device
        GET_DEVICE_TYPES: "getdevicetypes" // returns JSON Array with device types (AirTypes,..)

    },
    SHARED_USER_SETTINGS: {
        SAVE: "jdev/sps/setusersettings",
        // The timestamp and the base64 encoded file to save
        FETCH: "jdev/sps/getusersettings"
    },
    PUSHBUTTON: {
        PULSE: "pulse",
        ON: "on",
        OFF: "off"
    },
    SWITCH: {
        ON: "on",
        OFF: "off"
    },
    HOURCOUNTER: {
        RESET: "reset",
        RESET_ALL: "resetall",
        ENABLE: "enable",
        DISABLE: "disable"
    },
    SAUNA: {
        ON: "on",
        OFF: "off",
        FAN_OFF: "fanoff",
        FAN_ON: "fanon",
        TEMP: "temp/%0.2f",
        HUMIDITY: "humidity/%0.2f",
        MODE: "mode/%i",
        NEXT_MODE: "pulse",
        START_TIMER: "starttimer"
    },
    SEQUENTIAL: {
        TRIGGER: 'triggerSequence/%i'
    },
    ALARM: {
        ARM: "on",
        DELAYED_ARM: "delayedon",
        DISARM: "off",
        ACKNOWLEDGE: "quit",
        DISABLE_MOVEMENT: "dismv/%i",
        // 0 for off and 1 for on
        SMOKE_ALARM: {
            MUTE: "mute",
            QUIT: "confirm",
            SERVICE_MODE: "servicemode/%i" // Seconds till stop…

        },
        AAL_SMART_ALARM: {
            QUIT: "confirm",
            DISABLE: "disable/%i" // disable for x seconds

        },
        ARM_SINGLE: "on/%i",
        // arm alarm with (1)/without (0) movement (feature);
        DELAYED_ARM_SINGLE: "delayedon/%i",
        // delayed arm with (1)/without (0) movement (feature)
        SET_ALARM_SOUND: "setWakeAlarmSound/%i",
        // the defined sound id defined in the controls details
        SET_ALARM_VOLUME: "setWakeAlarmVolume/%i",
        // The volume 0-100
        SET_ALARM_SLOPING: "setWakeAlarmSlopingOn/%i",
        // if sloping should be used
        START_DRILL: "startDrill"
    },
    INTERCOM: {
        ANSWER: "answer",
        OUTPUT_ON: "%s/on",
        OUTPUT_OFF: "%s/off",
        OUTPUT_PULSE: "%s/pulse"
    },
    INTERCOM_GEN_2: {
        ANSWER: "answer",
        PLAY_TTS: "playTts/%i",
        // plays a tts message with index %i
        MUTE: "mute/%i",
        // mutes the Qb output of the control block. 0 -> unmute, 1 -> mute at %i
        SET_ANSWERS: "setAnswers/%s" // slash separated list of answers

    },
    TIMED_SWITCH: {
        ON: "on",
        OFF: "off",
        PULSE: "pulse"
    },
    UP_DOWN_LEFT_RIGHT: {
        DIGITAL: {
            UP_ON: "UpOn",
            UP_OFF: "UpOff",
            UP_PULSE: "PulseUp",
            // support for EIB Jalousie since Config 8
            DOWN_ON: "DownOn",
            DOWN_OFF: "DownOff",
            DOWN_PULSE: "PulseDown" // support for EIB Jalousie since Config 8

        },
        ANALOG: {
            VALUE: "%f"
        }
    },
    COLOR_PICKER: {
        ON: "on",
        OFF: "off",
        SET_FAVORITE: "setfav/%i/%s",
        HSV: "hsv(%i,%i,%i)",
        LUMITECH: "lumitech(%i,%i)"
    },
    COLOR_PICKER_V2: {
        SET_FAVORITE: "setFav/%i/%s",
        DEL_FAVORITE: "setFav/%i",
        HSV: "hsv(%i,%i,%i)",
        TEMP: "temp(%i,%i)",
        SET_FAV_SEQUENCE: "setFavSequence/%i/%i/%s",
        // sequenceIdx (Of global sequence state), duration_sec, colors (Colors are separated by /, max 6 colors)
        DEL_FAV_SEQUENCE: "setFavSequence/%i",
        // sequenceIdx (Of global sequence state)
        SET_SEQUENCE: "setSequence/%i/%s",
        // interval_sec, colors (Colors are separated by /, max 6 colors)
        SET_BRIGHTNESS: "setBrightness/%i", // called when the brightness of e.g. a sequence needs to be modified.
DAYLIGHT_ON: "daylight(%s)" // brightness
    },
    LIGHTSCENE_RGB: {
        ON: "AI/on",
        OFF: "off",
        SELECT_SCENE: "%i",
        // sceneNr
        SET_COLOR: "AI/%s",
        LEARN: "%i/learn"
    },
    DIMMER: {
        ON: "on",
        OFF: "off",
        VALUE: "%f"
    },
    SLIDER: {
        VALUE: "%f"
    },
    GATE: {
        OPEN: "open",
        CLOSE: "close",
        STOP: "stop",
        PARTIAL_OPEN: "partiallyOpen" // new since 14.2
    },
    JALOUSIE: {
        UP: "up",
        UP_PULSE: "up/pulse", // available since config version 13.3.3.20
        UP_OFF: "UpOff",
        DOWN: "down",
        DOWN_PULSE: "down/pulse", // available since config version 13.3.3.20
        DOWN_OFF: "DownOff",
        FULL_UP: "FullUp",
        FULL_DOWN: "FullDown",
        SHADE: "shade",
        STOP: "stop",
        AUTO_ON: "auto",
        AUTO_OFF: "NoAuto",
        CONFIG: {
            LEARN: "setLearningMode/%s",
            // The mode(s)
            CANCEL: 0,
            UP_OR_LEFT: 14,
            DOWN_OR_RIGHT: 15,
            ACK: 18,
            // finished with that step/mode, continues configuration..
            ABORT: 24,
            // This exits the serviceMode!
            SERVICE_MODE: 25,
            // enter the serviceMode..
            RETRY_SLATS_ADJ_STEP1: 23,
            // retry the first step of slats adjustment
            RETRY_SLATS_ADJ_STEP2: "23/6" // retry the second step of slats adjustment

        },
        ENABLE_ENDPOS_ADJUSTMENT: "setAdjustingEndPos/%i",
        // rq expert-mode-permission! 0=off, 1=on
        ADJUST_ENDPOSITION: "endPosAdjustment/%i" // (Only available if enabled by prv cmd) bitmask: 0x01=down, 0x02=up

    },
    AC: {
        SET_MODE: "setMode/%i",
        SET_TARGET: "setTarget/%i",
        SET_FAN: "setFan/%i",
        SET_AIR_DIR: "setAirDir/%i",
        START_SLEEP: "startPause/%i",
        CANCEL_SLEEP: "cancelPause",
        // since V15.1
        SET_DEFAULT_TARGET: "setdefaulttargettemp/%i",
        SET_DEFAULT_FAN: "setdefaultfanspeed/%i",
        SET_DEFAULT_AIR_DIR: "setdefaultairflow/%i",
        SET_SILENT_MODE: 'setSilentMode/%i',
        DISABLE_EXTERNAL: "disableExternal/%i",
        SET_OVERRIDE: "setoverride/%i/%i/%i/%i/%i",
        UNSET_OVERRIDE: "setoverride/0",
        GET_DEFAULT_SETTINGS: "getsettings",
        SET_AUTOMODE_FANSPEED: "SetAutomaticModeFanSpeed/%i"
    },
    IRRIGATION: {
        START_FORCE: "startForce",
        STOP: "stop",
        SET_SINGLE_DURATION: "setDuration/%s",
        // will set the duration of only one zone. append set durations arg
        SET_MULTIPLE_DURATIONS: "setDurations/%s",
        // will set the durations, append setdurationsarg for each duration to change
        SET_DURATION_ARG: "%i=%i" // zone id, duration in seconds, appended to SET_DURATIONS

    },
    VENTILATION: {
        TIMER: {
            STOP: "setTimer/0",
            SET: "setTimer/%i/%i/%i/%i" // time in seconds, ventilation speed, modeId, timerProfileIdx

        },
        SET_ABSENCE: {
            MIN: "setAbsenceMin/%i",
            MAX: "setAbsenceMax/%i"
        },
        SET_PRESENCE: {
            MIN: "setPresenceMin/%i",
            MAX: "setPresenceMax/%i"
        }
    },
    LIGHTCONTROL: {
        ALL_ON: "on",
        ALL_OFF: "reset",
        SELECT_SCENE: "%i",
        // sceneNr
        ADD_AND_RENAME_SCENE: "%i/learn/%s",
        // sceneNr, sceneName,
        DELETE_SCENE: "%i/delete",
        // sceneNr,
        SAVE_SCENE: "%i/learn",
        // sceneNr
        SCENE_MINUS: "minus",
        SCENE_PLUS: "plus"
    },
    LIGHTCONTROL_V2: {
        MOOD: {
            CHANGE_TOO: "changeTo/%i",
            // moodID, The moodId the LightControl should change to
            ADD: "addMood/%i",
            // moodID, adds the provided mood id to the current mood (merge)
            REMOVE: "removeMood/%i",
            // moodID, Remove the moodID from the current mode (revert)
            LEARN: "learn/%i/%s",
            // moodID, moodName, This learns the current output values to the given mood and overrides it if the mood alredy exists
            DELETE: "delete/%i",
            // moodID, Deletes the given mood
            FAVORITE: {
                MOVE_IN: "moveFavoriteMood/%i/%i",
                // moodID, newIdx Moves a mood within the favorite mood list
                MOVE_TO: "addToFavoriteMood/%i" // moodID Moves a mood from additional mood list to the favorite mood list

            },
            ADDITIONAL: {
                MOVE_IN: "moveAdditionalMood/%i/%i",
                // moodID, newIdx Moves a mood within the additional mood list
                MOVE_TO: "removeFromFavoriteMood/%i" // moodID, Moves a mood from favorite mood list to additional mood list

            },
            MOVE: "moveMood/%i/%i" // moodID newIdx

        },
        NEXT_MOODE: "plus",
        PREVIOUS_MOOD: "minus",
        RENAME_CIRCUITS: "setcircuitnames/%s", // %s is the object with the light circle names { "AI1": "name1", "AI2": "name2", ... }
        SET_DAYLIGHT_CONFIG: 'setdaylightconfig/%s'
    },
    MEDIACLIENT: {
        ON: "on",
        OFF: "off",
        SOURCE: "source/%i",
        // sourceNr
        VOLUME: "volume/%i",
        // volumeValue
        MUTE: "mute",
        BACKWARD: "prev",
        FORWARD: "next",
        PLAY: "play",
        PAUSE: "pause",
        PROGRESS: "progress/%i",
        // time
        SHUFFLE: "shuffle/%i",
        // type
        REPEAT: "repeat/%i" // type

    },
    DAYTIMER: {
        ACTIVATE: "pulse",
        DEFAULT: "default/%f",
        // defaultValue
        START_TIMER: "startOverride/%f/%i",
        // value, time in seconds --> value as float!
        START_TIMER_ACTIVE: "startOverride/1/%i",
        // time in seconds
        START_TIMER_INACTIVE: "startOverride/0/%i",
        // time in seconds
        CANCEL_TIMER: "stopOverride",
        SET_ENTRIES: "set/%i%s",
        // numberOfEntries, entriesString -> OperatingMode/From/To/needsActivation/
        SET_ENTRIES_COOLING: "setc/%i%s",
        // numberOfEntries, entriesString
        SET_MODES_LIST: "modeslist/%s",
        // stringOfModes        // use uuidParent for IRCDaytimer
        SET_MODES_LIST_C: "modeslistc/%s" // stringOfModes      // use uuidParent for IRCDaytimer

    },
    I_ROOM_CONTROLLER: {
        MODE: "mode/%i",
        // modeNr
        SERVICE: "service/%i",
        // serviceNr
        START_TIMER: "starttimer/%i/%i",
        // tempModeNr, time in minutes
        CANCEL_TIMER: "stoptimer",
        MANUAL_TEMP: "settemp/7/%f",
        // tempValue,   7 = IRoomControlEnums.IRCTempMode.MANUAL
        SET_TEMP_MODE: "settemp/%i/%f" // tempModeNr, value

    },
    I_ROOM_V2_CONTROLLER: {
        OVERRIDE: {
            START: "override/%i",
            START_UNTIL: "override/%i/%i",
            START_MANUAL_TEMP: "override/%i/%i/%f",
            STOP: "stopOverride"
        },
        SET_TEMP: {
            COMFORT: "setComfortTemperature/%f",
            COMFORT_COOL: "setComfortTemperatureCool/%f",
            COMFORT_TOLERANCE: "setComfortTolerance/%f",
            ECO_MIN: "setAbsentMinTemperature/%f",
            ECO_MAX: "setAbsentMaxTemperature/%f",
            MANUAL: "setManualTemperature/%f",
            COMFORT_OFFSET: "setComfortModeTemp/%f",
            MANUAL_TEMP: "setManualTemperature/%f",
            // tempValue
            // available since 11.3.2.15
            HEATING_LIMIT: "setHeatingBoundary/%f",
            COOLING_LIMIT: "setCoolingBoundary/%f"
        },
        SET_OPERATING_MODE: "setOperatingMode/%i"
    },
    IRC_V2021: {
        COMFORT_COOL: "setComfortTemperatureCool/%f",
        COMFORT_HEAT: "setComfortTemperature/%f",
        SHADING_TEMP_HEAT: "setshadingtemperatureheat/%f",
        SHADING_TEMP_COOL: "setshadingtemperaturecool/%f"
    },
    REMOTE: {
        MODE: "mode",
        MODEF: "mode/%d",
        // same like mode, but a format.
        ON: "on",
        OFF: "off",
        MUTE: "mute",
        PLAY: "play",
        PAUSE: "pause",
        STOP: "stop",
        REWIND: "rewind",
        PREVIOUS: "previous",
        NEXT: "next",
        FORWARD: "forward",
        MENU: "menu",
        INFO: "info",
        EXIT: "exit",
        GUIDE: "guide",
        VOL_PLUS: "volplus",
        VOL_MINUS: "volminus",
        VOL_PLUS_OFF: "volplusoff",
        VOL_MINUS_OFF: "volminusoff",
        PRG_PLUS: "prgplus",
        PRG_MINUS: "prgminus",
        PRG_PLUS_OFF: "prgplusoff",
        PRG_MINUS_OFF: "prgminusoff",
        RETURN: "return",
        RED: "btnred",
        BLUE: "btnblue",
        YELLOW: "btnyellow",
        GREEN: "btngreen",
        OK: "dirok",
        UP: "dirup",
        UP_OFF: "dirupoff",
        DOWN: "dirdown",
        DOWN_OFF: "dirdownoff",
        LEFT: "dirleft",
        LEFT_OFF: "dirleftoff",
        RIGHT: "dirright",
        RIGHT_OFF: "dirrightoff",
        NUMBER: "num",
        NUMF: "num%d",
        // number format
        TOTAL_NUMBER: "number",
        TOTAL_NUMBERF: "number/%s",
        // format to send the number as a string (leading 0 won't be dismissed)
        RESET: "reset" // Available since 7.5.5.23!

    },
    RADIO: {
        OUTPUT: "%i",
        // outputNr
        RESET: "reset",
        NEXT: "next",
        PREV: "prev"
    },
    AudioZoneCentral: {
        VOL_UP: 'volup',
        VOL_DOWN: 'voldown'
    },
    AudioZone: {
        SERVER: {
            ON: "svpower/on",
            OFF: "svpower/off"
        },
        CLIENT: {
            // used for streaming-zones
            ON: "on",
            OFF: "off",
            PLAY: "play",
            PAUSE: "pause",
            SOURCE: "source/",
            // sourceNr
            SOURCE_F: "source/%d",
            // to use commands.format
            SOURCEID: "source" // Same as SOURCE but without the dash

        },
        TTS: {
            FORMAT: "tts/%s",
            // only text (volume missing = default volume)
            ID: "tts" // first part of TTS Command, used to identify it.

        },
        Queue: {
            NEXT: "next",
            PREV: "prev"
        },
        PROGRESS: "progress/%d",
        VOLUME_SET: "volume",
        VOLUME_SETF: "volume/%d",
        VOLUME_STEP: "volstep",
        // Available since 7.5.5.17
        VOLUME_STEPF: "volstep/%d",
        // Available since 7.5.5.17
        VOICE_RECORDER: {
            UPLOAD_FILE: "%s/audio/cfg/upload/audioupload/add/%s",
            // host | name of the audio file with suffix
            PLAY_FILE: "audio/grouped/playuploadedfile/%s/%s" // filename | zone id/ids (1, 2 ,3, ...)

        },
        SHUFFLE: "shuffle/%d",
        SET_AIRPLAY: "enableairplay/%i",
        SET_SPOTIFY_CONNECT: "enablespotifyconnect/%i",
        SET_EQ: "equalizersettings/%s", // the eq settings as a CSV
        BLUETOOTH_ON: "bluetooth/%d", // 0 or 1 - disable or enable bluetooth
        BLUETOOTH_PAIRING_RESET: "resetbluetoothpairings", // will remove all bluetooth pairings
    },
    AudioZoneV2: {
        SERVER: {
            ON: "svpower/on",
            OFF: "svpower/off"
        },
        CLIENT: {
            // used for streaming-zones
            ON: "on",
            OFF: "off",
            PLAY: "play",
            PAUSE: "pause",
            PLAY_FAV: "playZoneFav/",
            // sourceNr
            PLAY_FAV_F: "playZoneFav/%d" // to use commands.format

        },
        TTS: {
            FORMAT: "tts/%s",
            // only text (volume missing = default volume)
            ID: "tts" // first part of TTS Command, used to identify it.

        },
        Queue: {
            NEXT: "next",
            PREV: "prev"
        },
        PROGRESS: "progress/%d",
        VOLUME_SET: "volume",
        VOLUME_SETF: "volume/%d",
        VOLUME_STEP: "volstep",
        VOLUME_STEPF: "volstep/%d",
        VOLUME: {
            UP: "volUp",
            DOWN: "volDown"
        },
        FAV_NEXT: "roomfav/plus",
        PLAY: "play",
        PAUSE: "pause",
        VOICE_RECORDER: {
            UPLOAD_FILE: "%s/audio/cfg/upload/audioupload/add/%s",
            // host | name of the audio file with suffix
            PLAY_FILE: "audio/grouped/playuploadedfile/%s/%s" // filename | zone id/ids (1, 2 ,3, ...)

        },
        PLAY_SPECIFIC_FAV: "playzonefav" // param1 = id of the fav

    },
    ALARM_CLOCK: {
        SNOOZE: "snooze",
        DISMISS: "dismiss",
        SET_ACTIVE: "setActive/%i",
        // 0 deactivate, 1 activate
        ALARM_ENTRY: "entryList/put/%i/%s/%i/%i/%s",
        //ID of entry, name, alarmTime in seconds since midnight, isActive, modes (or daily [0 | 1] if it is Nightlight Air
        DELETE_ENTRY: "entryList/delete/%i",
        //ID of the entry
        SET_PREP_DURATION: "setPrepDuration/%i",
        SET_RING_DURATION: "setRingDuration/%i",
        SET_BRIGHTNESS_ACTIVE: "setBrightnessActive/%i",
        SET_BRIGHTNESS_INACTIVE: "setBrightnessInactive/%i",
        SET_SNOOZE_DURATION: "setSnoozeDuration/%i",
        SET_NEEDS_CONF: "setNeedsConf/%i",
        // Available since 9.3.1.21
        SET_BEEP_ON: "setBeepOn/%i" // 0 deactivate, 1 activate

    },
    CAR_CHARGER: {
        START_CHARGING: "charge/on",
        STOP_CHARGING: "charge/off",
        SET_LIMIT_MODE: "limitmode/%i",
        SET_MAX_LIMIT: "limit/%.2f",
        // maximal charging power (between min and max - from statusupdates)
        SET_PROFILE: "profile/%i"
    },
    WALLBOX2: {
        ENABLE: "allow/on",
        DISABLE: "allow/off",
        SET_MODE: "setmode/%i", // value = limitation mode, from 1-5
        SET_UNPLUG_MODE: "modeUnplug/%i", // value = limitation mode from 0-5, 0=keep current mode
        UPDATE_MODE: "updateMode/%s/%f/%s", // id, limitValue, name
        MANUAL_LIMIT: "manualLimit/%f", // value = charging limit in manual mode
    },
    CLIMATE_CONTROLLER: {
        RESET_MAINTENANCE: "resetMaintenance",
        SET_SERVICE_MODE: "setServiceMode/%i",
        VENTILATION: "ventilation/%i",
        AUTO_MODE: "autoMode/%i",
        SET_BOUNDARY: {
            HEATING: "setHeatingBoundary/%f",
            COOLING: "setCoolingBoundary/%f"
        }
    },
    CLIMATE_CONTROLLER_US: {
        VENTILATION: "ventilation/%i",
        // 0 = auto, 1 = pOn
        VENTILATION_TIMER: "startVentilationTimer/%i",
        // seconds since 1.1.2009
        SET_MODE: "setMode/%i",
        // 0 = off, 1 = heating/cooling, 2 = heating, 3 = cooling
        USE_EMERGENCY: "useEmergency/%i",
        // 0 = off (or automatically), 1 = using emergency heating source
        START_MODE_TIMER: "startmodetimer/%i/%i/%i",
        // mode to start, seconds since 1.1.2009 - or 0 (stop) or -1(pOn), mode after timer ends
        START_EMERGENCY_TIMER: "startEmergencyTimer/%i",
        // seconds since 1.1.2009 - or 0 (stop) or -1(pOn)
        SET_MIN_TEMP_COOLING: "setMinimumTempCooling/%f",
        SET_MAX_TEMP_HEATING: "setMaximumTempHeating/%f"
    },
    POOL: {
        SET_OPERATING_MODE: "operatingMode/%i",
        SET_TEMP_MODE: "tempMode/%i",
        SET_TARGET_TEMP: "targetTemp/%s",
        COVER_CLOSE: "coverClose",
        COVER_OPEN: "coverOpen",
        SET_SWIMMING_MACHINE: "swimmingMachine/%.2f",
        // Either digital ot analog
        SWIMMING_MACHINE_ON: "swimmingMachine/on",
        SWIMMING_MACHINE_OFF: "swimmingMachine/off",
        START_TIMER: "startCycle/%i/%s",
        // cycleId, seconds, [seconds rinse]
        START_FILTER_CYCLE: "filter",
        START_BACKWASH_CYCLE: "backwash",
        START_CIRCULATE_CYCLE: "circulate",
        START_DRAIN_CYCLE: "drain",
        SET_VALVE_POSITION: "valvePos/%i",
        PUMP: "pump/%i",
        DRAIN_VALVE: "drainValve/%i",
        SET_BACKWASH_TIME: "backwashTime/%i",
        SET_RINSE_TIME: "rinseTime/%i",
        SET_HEATING_APPROVED: "approveHeating/%i",
        // 0, 1
        SET_COOLING_APPROVED: "approveCooling/%i",
        // 0, 1
        ACK_ERROR: "ackError"
    },
    NFC_CODE_TOUCH: {
        OUTPUT: "output/%i",
        // output nr (1-6)
        CREATE_CODE: "code/create/%s/%s/%i/%i/%i",
        // name (URIComponent encoded), code, type, outputs (bitmask), standard output (nr 1-6)
        CREATE_CODE_WITH_TIME: "code/create/%s/%s/%i/%i/%i/%i/%i",
        // name (URIComponent encoded), code, type, outputs (bitmask), standard output (nr 1-6), timeFrom, timeTo
        UPDATE_CODE: "code/update/%s/%s/%s/%s/%i/%i/%i",
        // uuid, is active, name (URIComponent encoded), code, type, outputs (bitmask), standard output (nr 1-6)
        UPDATE_CODE_WITH_TIME: "code/update/%s/%s/%s/%s/%i/%i/%i/%i/%i",
        // uuid, is active, name (URIComponent encoded), code, type, outputs (bitmask), standard output (nr 1-6), timeFrom, timeTo
        ACTIVATE_CODE: "code/activate/%s",
        // uuid
        DEACTIVATE_CODE: "code/deactivate/%s",
        // uuid
        DELETE_CODE: "code/delete/%s",
        // uuid
        GET_HISTORY: "history",
        // returns history JSON array
        GET_CODES: "codes",
        // return code JSON array
        ADD_NFC_TAG_TO_USER: "jdev/sps/addusernfc/%s/%s/%s",
        // user uuid, nfc tag id, name
        REMOVE_USER_NFC: "jdev/sps/removeusernfc/%s/%s",
        // user uuid, nfc tag id
        NFC_CODE_TOUCH_START_LEARN: "nfc/startlearn",
        // starts the learning mode of the NFC Code Touch
        NFC_CODE_TOUCH_STOP_LEARN: "nfc/stoplearn" // stops the learning mode of the NFC Code Touch

    },
    WINDOW: {
        OPEN: {
            JOG_ON: "open/on",
            JOG_OFF: "open/off",
            FULL: "fullopen"
        },
        CLOSE: {
            JOG_ON: "close/on",
            JOG_OFF: "close/off",
            FULL: "fullclose"
        },
        MOVE_TO: "moveToPosition/%d" // %d is the position in %

    },
    WINDOW_CENTRAL: {
        OPEN: {
            FULL: "fullopen"
        },
        CLOSE: {
            FULL: "fullclose"
        },
        STOP: "stop"
    },
    Weather: {
        CHECK: 'jdev/sps/startweather' // will cause the miniserver to check for weather data again. usable since V8.3.1.30

    },
    Steak: {
        SURVEILLANCE: {
            START: "startSurveillance",
            STOP: "stopSurveillance"
        },
        QUIT_ALARM: "quitAlarm",
        SET_SENSOR: "setSensor/%i/%f/%s",
        // SensorIndex, TargetTemp, Sensorname
        SET_DISPLAY_ALWAYS_ON_BAT: "setDisplayAlwaysOnBat/%i",
        // State (on or off)
        SET_DISPLAY_ALWAYS_ON_DC: "setDisplayAlwaysOnDc/%i",
        // State (on or off)
        SET_ACTIVE: "setActive/%i",
        // Sets a control active %i is the index of details.availableControls
        SET_THIS_ACTIVE: "setThisActive",
        // Sets this control as the active Control
        SET_TIMER_DURATION: "setTimerDuration/%i",
        // Sets a given timer with its time as unix timestamp in seconds
        START_TIMER: "startTimer",
        // Starts a timer with the defined duration
        STOP_TIMER: "stopTimer",
        SET_TOUCH_PROTECTION: "setTouchProtection/%i",
        // Enables or disables the Touch e.g: for transportation
        SET_DISPLAY_BRIGHTNESS: "setDisplayBrightness/%f" // Sets the Display birightness

    },
    TEXT_INPUT: {
        SET_VALUE: "%s" // new value

    },
    OPMSchedule: {
        // used to get or edit the schedule for operating modes. (calendar)
        GET: "jdev/sps/calendargetentries",
        // returns a list of all entries
        NEW: "jdev/sps/calendarcreateentry/%s/%i/%i/%s",
        // name, opmode, calMode, modeSpecificArgument
        UPDATE: "jdev/sps/calendarupdateentry/%s/%s/%i/%i/%s",
        // uuid, name, opmode, calMode, modeSpecificArgument
        DELETE: "jdev/sps/calendardeleteentry/%s",
        // delete the entry
        GET_HEAT: "jdev/sps/calendargetheatperiod",
        // ISO-Date start / end, z.B. 10-15/04-15
        GET_COOL: "jdev/sps/calendargetcoolperiod" // ISO-Date start / end, z.B. 10-15/04-15

    },
    MailBox: {
        NOTIF_DIS: "disableNotifications/%d",
        // %d seconds it needs to be disabled for
        NOTIF_DIS_ID: "disableNotifications",
        // identifier for createCmdText in mailboxcontrol
        NOTIF_ENABLE: "disableNotifications/0",
        // 0 = disable timer cancelled
        CONF_PAKET: "confirmPaket",
        // paket bag was emptied
        CONF_MAIL: "confirmMail" // mails where checked

    },
    AalEmergency: {
        TRIGGER: "trigger",
        //triggers an alarm
        QUIT: "quit",
        //quits an active alarm
        DISABLE: "disable/%d",
        // %d seconds the alarm function should be disabled
        DISABLE_ID: "disable",
        // identifier for createCmdText in aalEmergency-Control
        REACTIVATE: "disable/0" //used to reenable

    },
    EnergyManager: {
        TRIGGER: "trigger/%i",
        // triggers load with id %i
        TURN_ON: "turnOn/%i",
        // turns on load with id %i
        TURN_OFF: "turnOff/%i" // turns off load with id %i

    },
    EnergyManager2: {
        LOAD_ACTIVATE: "%s/activate", // loaduuid
        LOAD_DEACTIVATE: "%s/deactivate", // loaduuid
        LOAD_AUTOMATIC: "%s/automatic", //loaduuid
        Edit: {
            LOAD: "%s/edit/load", // loaduuid --> returns an object containing the same api as the expert mode
            CANCEL: "%s/edit/cancel", // loaduuid --> cancels the edit mode
            VERIFY: "%s/edit/verify/%s/%s", // loaduuid, setting-id, value
            REFRESH: "%s/edit/refresh", // keeps the edit mode active
            SAVE: "%s/edit/save", // applies all changed settings.
        },
        SET_ORDER: "order/%s", // list of laod uuids separated by a comma ","
        SET_MIN_SOC: "setMinSoc/%i",
        SET_MAX_SPWR: "setMaxSpwr/%s",
        GET_STORAGE_TEXTS: "getStorageSettingDescriptions", // returns an json array of all the texts required for the storage settings
    },
    PulseAt: {
        SET_TIME: "setTime/%i",
        // seconds since midnight %i
        SET_ONE_TIME_PULSE: "setOneTimePulse/%i",
        // secondsSince2009 %i
        SET_TYPE: "setType/%i" // sets the current type id %i

    },
    SPOT_PRICE_OPTIMIZER: {
        CANCEL: "cancel",
        START: "start/%i/%i", // demand in hours/period in hours
        GET_FORECAST: "getForecasts"
    },

    /**
     * formats commands with C-Style format (use this method for commands instead of lxFormat  due to localization of lxFormat!
     * arguments cmd, args
     * @returns {string}
     */
    format: function formatCommand() {
        //TODO-thallth: removing the special characters messes up stuff like the loxone ID login, where you want special characters in the command.

        /*
         // check arguments if there are strings with special characters -> remove!
         for (var i = 1; i < arguments.length; i++) { // skip fist (is command format itself)
         if (typeof arguments[i] == 'string') {
         arguments[i] = Commands.checkStringForCommand(arguments[i]);
         if (arguments[i].length === 0) {
         console.error("Command not valid! Maybe used special characters??");
         return "";
         }
         }
         }
         */

        /*// new approach
         for (var i = 1; i < arguments.length; i++) { // skip fist (is command format itself)
         if (typeof arguments[i] == 'string') {
         //arguments[i] = encodeURIComponent(arguments[i]);
         //arguments[i] = arguments[i].replace("//", "I");
         if (arguments[i].length === 0) {
         console.error("Command not valid! Maybe used special characters??");
         return "";
         }
         }
         }*/
        return sprintf.apply(null, arguments);
    },

    /**
     * checks string and replaces special characters
     * @param s string to check
     * @returns {string}
     */
    checkStringForCommand: function checkStringForCommand(s) {
        // allowed: chars, digits, underscore, whitespace
        return s.replace(/[^\w\s]/gi, "").trim();
    }
};
Commands.SendState = {
    FAILED: "sending command failed",
    // command couldn't be sent due to connection problems
    QUEUED: "command in queue",
    // command is in queue and it will be sent if connection ready
    OVERRIDDEN: "overridden",
    // command has overrided previous command in the queue
    PENDING: "command sending in progress"
};
Commands.Type = {
    OVERRIDE: "override",
    QUEUE: "queue"
};
